import {render} from "lit-html";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {SlideOverView} from "../../SlideOverView";
import {TemplateUserPreferences} from "@/templates/template-items/side-view/user-preferences/TemplateUserPreferences";
import {TemplateSimplifiedUserPreferences} from "@/templates/template-items/side-view/user-preferences/TemplateSimplifiedUserPreferences";
import {TemplateChangePassword} from "@/templates/template-items/side-view/user-preferences/TemplateChangePassword";
import {TemplateChangeAddress} from "@/templates/template-items/side-view/user-preferences/TemplateChangeAddress";
import {TemplateChangePhoneNumber} from "@/templates/template-items/side-view/user-preferences/TemplateChangePhoneNumber";
import {FormHelpers} from "@/js/util/FormHelpers";
import {Popup} from "@/js/ui/Popup";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";

export class SideViewSubUserPreferences extends SideViewSubBaseClass{
    constructor(container) {
        super(container);
        this.slideoverView=null;
        this.registerInfo = {}
        this.loading = false;
        this.loadingUpdate = false;
        this.loadingPromotionSwitch = false
        this.updateData = {}
    }

    renderView(){
        this.loadingUpdate = false
        this.updateData = {}

        if(window.isSimplifiedSignUpForm){
            render(TemplateSimplifiedUserPreferences(this),this.element,{eventContext:this});
        }else{
            render(TemplateUserPreferences(this),this.element,{eventContext:this});
        }
        FormHelpers.initializeFormComponents(this.element);
        this.setupEventListener()
    }

    updateView(){
        if(window.isSimplifiedSignUpForm){
            render(TemplateSimplifiedUserPreferences(this),this.element,{eventContext:this});
        }else{
            render(TemplateUserPreferences(this),this.element,{eventContext:this});
        }
    }

    setupEventListener(){
        var controller = this
        $('.mdc-switch').each(function(index){
            var switches = $(this)[0].MDCSwitch
            if(switches.nativeControl_.id == 'post-switch'){
                switches.checked = USER.data.ReceivePromotionalByPost
            }
            if(switches.nativeControl_.id == 'email-switch'){
                switches.checked = USER.data.ReceivePromotionalByEmail
            }
            if(switches.nativeControl_.id == 'sms-switch'){
                switches.checked = USER.data.ReceivePromotionalBySMS
            }
            
            switches.listen('change', (e) => {
                var switches = e.currentTarget.MDCSwitch
                $('.mdc-switch').each(function(index){
                    var allSwitch = $(this)[0].MDCSwitch
                    allSwitch.disabled = true;
                })
                if(switches.nativeControl_.id == 'post-switch'){
                    controller.updateData.ReceivePromotionalByPost = (switches.checked) ? '1' : '0'
                    controller.updateData.ReceivePromotionalByEmail = (USER.data.ReceivePromotionalByEmail) ? '1' : '0'
                    controller.updateData.ReceivePromotionalBySMS = (USER.data.ReceivePromotionalBySMS) ? '1' : '0'
                }else if(switches.nativeControl_.id == 'email-switch'){
                    controller.updateData.ReceivePromotionalByEmail = (switches.checked) ? '1' : '0'
                    controller.updateData.ReceivePromotionalByPost = (USER.data.ReceivePromotionalByPost) ? '1' : '0'
                    controller.updateData.ReceivePromotionalBySMS = (USER.data.ReceivePromotionalBySMS) ? '1' : '0'
                }else if (switches.nativeControl_.id == 'sms-switch'){
                    controller.updateData.ReceivePromotionalBySMS = (switches.checked) ? '1' : '0'
                    controller.updateData.ReceivePromotionalByEmail = (USER.data.ReceivePromotionalByEmail) ? '1' : '0'
                    controller.updateData.ReceivePromotionalByPost = (USER.data.ReceivePromotionalByPost) ? '1' : '0'
                }
                controller.updateData.mobilePrefix = USER.data.MobilePrefix
                controller.updateData.mobilePhone = USER.data.MobilePhone
                controller.updateData.address = USER.data.Address1
                controller.updateData.city = USER.data.City
                controller.updateData.postcode = USER.data.PostCode
                controller.updateData.languageISO = USER.data.LanguageISO
                controller.updateData.oldPassword = ''
                controller.updateData.newPassword = ''
                controller.updateData.passwordRepeat = ''
                controller.updateData.sessionKey = USER.sessionKey
                
                controller.loadingPromotionSwitch = true
                controller.updateView()
                controller.updateProfileAction()
            })
        });
    }


    onSlideOverBackButtonClick(){
        console.log("CLICK HANDLER CONTEXT",this);
        if(!this.loading){
            this.slideoverView.close();
        }
    }

    getRegisterData(){
        this.loadingRegisterInfo = true;
        this.slideoverView.update()
        USER.getRegisterDataList().then(data=>{
            this.registerInfo = data;
            this.loadingRegisterInfo = false;
            this.slideoverView.update()
            FormHelpers.initializeFormComponents(this.slideoverView.element);
        });
        
    }

    refreshUserData(){
        
        USER.updateUserData().then(data => {
            this.loading = false
            this.loadingPromotionSwitch = false
            $('.mdc-switch').each(function(index){
                var allSwitch = $(this)[0].MDCSwitch
                allSwitch.disabled = false;
            })
            this.updateView()
        })
    }

    _getMDCInputFields(page){
        let mdcInput = {}
        if(page == 'address'){
            mdcInput = {
                address1: document.querySelector(".mdc-address1").MDCTextField,
                address2: document.querySelector(".mdc-address2").MDCTextField,
                city: document.querySelector(".mdc-city").MDCTextField,
                postcode: document.querySelector(".mdc-postcode").MDCTextField,
            }
        }else if (page == 'phone'){
            mdcInput = {
                mobilePrefix: document.querySelector(".mdc-mobile-prefix").MDCSelect,
                mobileNumber: document.querySelector(".mdc-mobile-number").MDCTextField,
            }
        }
        return mdcInput
    }

    onSubmitChangePassword(e){
        e.preventDefault();
        let inputData=FormHelpers.getFormDataAsObject(e.target);
        console.log(inputData)
        if(inputData.newPassword != inputData.confirmNewPassword){
            new Popup(APP_STRINGS.ERROR,APP_STRINGS.getStringById("2221005"),[APP_STRINGS.OK])
            return 
        }
        let controller = this;
        this.loading=true;
        this.slideoverView.update()
        USER.changePassword(inputData.oldPassword, inputData.newPassword)
        .then(data=>{
            controller.loading=false;
            controller.slideoverView.update()

            if(data.error){
                new Popup(APP_STRINGS.ERROR,data.error.text,[APP_STRINGS.OK])
            }else{
                new SlideOverViewStatus(controller.container,
                    SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                    APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-PasswordChangeSuccess-Message'),
                    '',
                    [APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-PasswordChangeSuccess-ButtonLabel-Continue')],
                    (btnId)=>{
                        controller.onSlideOverBackButtonClick()
                });
            }
        });
    }

    onSubmitUpdateProfile(e){
        e.preventDefault();
        let action = e.currentTarget.dataset.updateAction
        let statusString = ''
        let successButtonString = ''
        this.loadingUpdate = true;
        this.slideoverView.update()
        let inputData = {};

        switch(action){
            case 'address': 
                inputData= this._getMDCInputFields(action);
                this.updateData.address = inputData.address1.value+';'+inputData.address2.value
                this.updateData.city = inputData.city.value
                this.updateData.postcode = inputData.postcode.value
                //pre add all the detail 
                this.updateData.mobilePrefix = USER.data.MobilePrefix
                this.updateData.mobilePhone = USER.data.MobilePhone
                statusString = APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-AddressChangeSuccess-Message');
                successButtonString = APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-AddressChangeSuccess-ButtonLabel-Continue');
            break;
            case 'phone':
                inputData= this._getMDCInputFields(action);
                this.updateData.mobilePrefix = inputData.mobilePrefix.value
                this.updateData.mobilePhone = inputData.mobileNumber.value
                //pre add all the detail
                this.updateData.address = USER.data.Address1
                this.updateData.city = USER.data.City
                this.updateData.postcode = USER.data.PostCode
                statusString = APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-PhoneNumberChangeSuccess-Message');
                successButtonString = APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-PhoneNumberChangeSuccess-ButtonLabel-Continue');
            break;
            default:
                return 0;
            break;

        }
        this.updateData.NewsFeedsAllow = (USER.data.NewsFeedsAllow) ? '1' : '0'
        this.updateData.ReceivePromotionalByEmail = (USER.data.ReceivePromotionalByEmail) ? '1' : '0'
        this.updateData.ReceivePromotionalByPost = (USER.data.ReceivePromotionalByPost) ? '1' : '0'
        this.updateData.ReceivePromotionalBySMS = (USER.data.ReceivePromotionalBySMS) ? '1' : '0'
        this.updateData.languageISO = USER.data.LanguageISO
        this.updateData.oldPassword = ''
        this.updateData.newPassword = ''
        this.updateData.passwordRepeat = ''
        this.updateData.sessionKey = USER.sessionKey

        this.updateProfileAction(statusString, successButtonString)
        
    }

    updateProfileAction(statusString = null, successButtonString){
        let controller = this;
        USER.updatePlayerInfo(this.updateData).then(data=>{
            if(statusString){
                //only update by submit need to load slideover
                controller.loadingUpdate = false;
                controller.slideoverView.update()
            }
            if(data.error){
                new Popup(APP_STRINGS.ERROR,data.error.text,[APP_STRINGS.OK])
            }else{
                if(statusString){
                    new SlideOverViewStatus(controller.container, SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS, statusString, '',[successButtonString],(btnId)=>{
                        controller.onSlideOverBackButtonClick()
                        controller.loading = true
                        controller.updateView()
                        controller.refreshUserData()
                    });
                }else{
                    controller.refreshUserData()
                }
                
            }

        });
    }

    onPreferenceItemClick(e){
        console.log("!!!!clicking items",this);
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "changePassword":
                this.slideoverView=new SlideOverView(this.container,TemplateChangePassword,this);
                break;
            case "changeAddress":
                this.slideoverView= new SlideOverView(this.container, TemplateChangeAddress,this);
                break;
            case "changePhoneNumber":
                this.registerInfo = {}
                this.slideoverView= new SlideOverView(this.container, TemplateChangePhoneNumber,this);
                this.getRegisterData()
                break;
            default:
                console.log(`Action not defined: ${action}`)

        }
    }
}