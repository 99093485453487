import {MDCTextField} from "@material/textfield";
import {MDCTextFieldIcon} from "@material/textfield/icon";
import {MDCCheckbox} from "@material/checkbox";
import {MDCFormField} from "@material/form-field";
import {MDCSelect} from "@material/select";
import {MDCSwitch} from "@material/switch";
import {MDCRadio} from "@material/radio";
import {flatpickr} from 'flatpickr';
import {Helpers} from "./Helpers";

export const FormHelpers={
    initializeFormComponents:containerElement=>{
        let mdcTextfieldElements=containerElement.querySelectorAll('.mdc-text-field');
        mdcTextfieldElements.forEach(node=>{ node.MDCTextField=new MDCTextField(node); });

        let mdcTFIcons= containerElement.querySelectorAll('.mdc-text-field__icon');
        mdcTFIcons.forEach(node=>{ new MDCTextFieldIcon(node); });

        let mdcCheckboxes= containerElement.querySelectorAll('.mdc-checkbox');
        mdcCheckboxes.forEach(node=> { node.MDCCheckbox = new MDCCheckbox(node); });

        let mdcFormFields= containerElement.querySelectorAll('.mdc-form-field');
        mdcFormFields.forEach(node=> { new MDCFormField(node); });

        let mdcDropdowns= containerElement.querySelectorAll('.mdc-select');
        mdcDropdowns.forEach(node=> { initializeSelectControl(node) });

        let mdcRadio= containerElement.querySelectorAll('.mdc-radio');
        mdcRadio.forEach(node=> { node.MDCRadio = new MDCRadio(node); })

        let mdcSwitches= containerElement.querySelectorAll('.mdc-switch');
        mdcSwitches.forEach(node=> { node.MDCSwitch = new MDCSwitch(node); });




//initialize filter type minus
        var filters=containerElement.querySelectorAll(".filters-menu");
        filters.forEach(function(item){
            initFilterMenu(item);
        });

        function initFilterMenu(element){
            var input=element.querySelector(".mdc-text-field");
            let handlerFunction=function(evt) {
                var targetElement = evt.target;
                do {
                    if (targetElement === element) {return;}
                    targetElement = targetElement.parentNode;
                } while (targetElement);

                element.classList.remove("open");
                document.removeEventListener("click", handlerFunction);
            };
            input.addEventListener("click", function(e){
                e.preventDefault();
                //e.stopPropagation();
                element.classList.toggle("open");
                if(element.classList.contains("open")){
                    document.addEventListener("click", handlerFunction);
                }else{
                    document.removeEventListener("click", handlerFunction);
                }

            });
        }


        var filterButtons=containerElement.querySelectorAll(".filter-btn-mobile");
        filterButtons.forEach(function(item){

            var input=item.querySelector(".mdc-text-field");
            input.addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
            });
        });
    },

    initializeDatepickr:(node,maxDate)=>{



        let config={
            dateFormat: "d/m/Y",
            disableMobile: "true",
            onChange:function(e){
                this.input.type="text";
                this.input.parentNode.MDCTextField.foundation_.adapter_.floatLabel(true);

                console.log("On datepicker change function", e, this);
            },
        }
        if(maxDate){
            config.maxDate=maxDate;
        }
        window.flatpickr(node,config);

        console.log("Datepicker initialized here",node);
        return node._flatpickr;

    },

    getFormDataAsObject:form=>{
        let obj={};
        let d=new FormData(form);
        for(var pair of d.entries()) {
            obj[pair[0]]=pair[1];
        }
        return obj;
    }
}


function initializeSelectControl(node){
    node.MDCSelect = new MDCSelect(node);

    if(Helpers.getDeviceType()=="desktop"){
        applyKeyboardSearch(node);
    }

    if(Helpers.getDeviceType()=="mobile"){
        applyNativeSelect(node);
    }
}
function applyKeyboardSearch(node){
    //setup keyboard search on MDC select
    let items=node.querySelectorAll(".mdc-list-item");
    let labels=[];
    for(let i=0;i<items.length;i++){
        let l=items[i].innerText.replace(/(\r\n|\n|\r)/gm,"").trim();
        labels.push(l);
    }

    let searchQuery="";
    let lastKeystrokeTime=0;
    node.addEventListener('keydown', function(e) {
        if(e.key.length==1){
            if(lastKeystrokeTime+500<Date.now()){
                searchQuery="";
            }
            lastKeystrokeTime=Date.now();
            searchQuery+=e.key;
            let itemIndex=labels.findIndex(item=>item.toLowerCase().startsWith(searchQuery.toLowerCase()));

            if(itemIndex>-1){
                node.MDCSelect.value=items[itemIndex].getAttribute("data-value");
                node.MDCSelect.foundation_.adapter_.focusMenuItemAtIndex(itemIndex);
            }
        }
    });
}
function applyNativeSelect(node){
    let nativeSelect=document.createElement("select");

    nativeSelect.style.position="absolute";
    nativeSelect.style.width="100%";
    nativeSelect.style.height="100%";
    nativeSelect.style.top="0";
    nativeSelect.style.left="0";
    nativeSelect.style.opacity="0";

    createOptionItems(node.MDCSelect,nativeSelect);

    node.append(nativeSelect);

    nativeSelect.addEventListener("change",e=>{
        console.log(e.currentTarget.value);
        node.MDCSelect.value=e.currentTarget.value;
        node.MDCSelect.foundation_.adapter_.floatLabel(true);
    })

    nativeSelect.addEventListener("click",e=>{
        node.MDCSelect.value=e.currentTarget.value;
        node.MDCSelect.foundation_.adapter_.floatLabel(true);
    })

}

function createOptionItems(mdcSelect,nativeSelect){
    nativeSelect.innerHTML="";
    let selectMethods=mdcSelect.getSelectAdapterMethods_();
    let itemValues=selectMethods.getMenuItemValues();
    itemValues.forEach((item,index)=>{
        let option=document.createElement("option");
        option.value=item;
        option.innerHTML=selectMethods.getMenuItemTextAtIndex(index);
        nativeSelect.appendChild(option);
    })
}



