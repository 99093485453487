import {html} from 'lit-html';
import {USER} from "../../../js/core/User";
import {APP_STRINGS} from "../../../js/core/AppStrings";
import { Helpers } from '../../../../../capp-V3/src/js/util/Helpers';

export const TemplateMainMenuSideView=el=>html`
    <div class="view-heading layout">
        <div class="close-img-container">
            <img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/yellow-close.svg">
        </div>
        <div class="main-side-mobile-heading">
            ${USER.isLoggedIn ?  html`
                        <span @click=${el.onMenuItemClick} data-action="search" ><img @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/search-icon.svg"></span>
                        <span @click=${el.onMenuItemClick} data-action="help"><img data-action="help"  @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/contact-us-icon.svg"></span>
                        <span style="display: none;" @click=${el.onMenuItemClick} data-action="help"><img data-action="help" @click=${el._onMenuItemClick} src="${CDN_ASSETS_URL}/inbox-icon.svg"></span>
                        <span @click=${el.onMenuItemClick} data-action="user"><img src="${CDN_ASSETS_URL}/user-icon.svg"></span>` :
                    html `<span @click=${el.onMenuItemClick} class="yellow-back" data-action="login">LOGIN</span>`}
            <span><img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/yellow-close.svg"></span>
        </div>
        <span class="title">Main Menu</span>
    </div>
    <div class="scrolling-content main-menu-side-view">
        <div class="content">
            ${USER.isLoggedIn ?  html`
                        <ul>
                            ${Helpers.getDeviceType() != 'desktop' ? html`
                                ${window.menuData.map(item=> html`
                                        <a class="menu-item ${item.isActive != 'no'?'active':''}${item.classes}" href="${item.url}">
                                            <span>${item.title}</span>
                                        </a>
                                `)}
                                `:''
                            }

                            ${window.mobileMenu.map(item=> html`
                                    <a @click=${el.onMenuItemClick} data-action="${item.menu_name}">
                                        <span class="${item.classes}">${item.menu_name}</span>
                                    </a>
                            `)}
                            
                            <li @click=${el.onMenuItemClick} data-action="logout">
                                <span class="menu-separator" style="display: block;">LOG OUT</span>
                            </li>
                        </ul>`
                    : html `
                        <ul>
                            ${window.menuData.map(item=> html`
                                    <a class="menu-item ${item.isActive != 'no'?'active':''}${item.classes}" href="${item.url}">
                                        <span>${item.title}</span>
                                    </a>
                            `)}
                        </ul>
                        `}
        </div>
    </div>

`;