import {BankFormBase} from "./BankFormBase";
import {Template_IBV4_DirectBanking} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_IBV4_DirectBanking";
import {BANK} from "../../../../core/Bank";
import {Helpers} from "../../../../util/Helpers";

export class BankFormIBV4DirectBanking extends BankFormBase{
    constructor(container, country, banks, existingAccounts, callback) {
        super(container, callback);
        this.country=country;
        this.banks=banks;
        this.existingAccounts=existingAccounts;

        this._init();
    }

    _getFormTemplate() {
        return Template_IBV4_DirectBanking;
    }

    _applyExistingAccount(input){
        if(input.value=="new-account"){
            this.formInputs.forEach(input=>{
                //todo we might need to rething this for other bank forms, perhaps create reset object in bank model
                if(input.root_.dataset.field!="" && input.root_.dataset.field!="existingAccount" && input.root_.dataset.field!="bankCountry"){
                    input.value="";
                    input.foundation_.adapter_.floatLabel(false)
                }
            })
        }else{
            let account=BANK.getExistingAccountById(input.value);
            this.formInputs.forEach(input=>{
                if(account[input.root_.dataset.field]){

                    if(input.root_.dataset.field === 'bankSwiftCode' && (typeof account['bankName']) !== 'undefined'){
                        //SelectBox - bankSwiftCode value alone might not be enough to uniquely identify a bank
                        input.value=account[input.root_.dataset.field]+'|'+account['bankName'];
                    }
                    else{
                        input.value=account[input.root_.dataset.field];
                    }
                    input.foundation_.adapter_.floatLabel(true);
                }
            })
        }
    }

}