import {html} from 'lit-html';
import {Helpers} from "@/js/util/Helpers";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateHistoryContentView = (el) => html`
<div id="history">
    <div class="content">
        <div class="history-container">
            <!-- lets make this capsule-tabs as it will be defined in common elements -->
            <div class="capsule-tabs">
                <div class="capsule-tabs-inner capp-scrollbar">
                    <div class="buttons-container">
                        ${displayTransactionType(el)}
                    </div>
                </div>
            </div>
            <div class="capsule-tabs filter-date-bar">
                <div class="capsule-tabs-inner capp-scrollbar">
                    <div class="buttons-container">
                        ${displayPresetDate(el)}
                        
                    </div>
                </div>
            </div>
            ${el.isShowCustomBox ? html`
                <div class="custom-filter-bar">
                    <label class="mdc-text-field mdc-text-field--with-trailing-icon mdc-start-date">
                        <input class="mdc-text-field__input flatpickr start-date-input" type="text" aria-labelledby="my-label-id">
                        <i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing icon-down"></i>
                        <span class="mdc-floating-label" id="my-label-id">From</span>

                        <div class="mdc-line-ripple"></div>
                    </label>
                        ${(el.selectedTransaction == 'transaction' || el.selectedTransaction == 'gamePlay') ? html `
                            <label class="mdc-text-field mdc-text-field--with-trailing-icon mdc-end-date">
                                <input class="mdc-text-field__input flatpickr end-date-input" type="text" aria-labelledby="my-label-id">
                                <i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing icon-down"></i>
                                <span class="mdc-floating-label" id="my-label-id">To</span>

                                <div class="mdc-line-ripple"></div>
                            </label>
                        ` : ``}
                    <button class="primary-button ${el.loadingHistoryData ? 'loading' : ''}" @click=${el.onClickCustomFilter}>
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-History-ButtonLabel-Filter')}</span>
                        <preloader-element></preloader-element>
                    </button>

                </div>
            ` : ``}

            <div class="title-container">
                <span class="Paragraph-Normal">${el.transactionType[el.selectedTransaction].displayName} for </span><span class="date-label Heading-5">${getSelectedDateRange(el)}</span>
            </div>
            <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingHistoryData ? 'flex' : 'none'};" ></preloader-element>
            <div class="history-table-container" style="display: ${!el.loadingHistoryData ? 'block' : 'none'};">
                <div class="inner-container table-transaction ${el.selectedTransaction == 'transaction' ? 'show' : ''}">
                    <table>
                        <thead>
                        <tr>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-TransactionsTable-ColumnLabel-DateAndTime')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-TransactionsTable-ColumnLabel-Action')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-TransactionsTable-ColumnLabel-Amount')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-TransactionsTable-ColumnLabel-Status')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-TransactionsTable-ColumnLabel-PaymentMethod')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            ${displayHistoryData(el)}
                        </tbody>
                    </table>
                   
                </div>
                <div class="inner-container table-game-play ${el.selectedTransaction == 'gamePlay' ? 'show' : ''}">
                    <table>
                        <thead>
                        <tr>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-GamePlayTable-ColumnLabel-GameName')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-GamePlayTable-ColumnLabel-DateAndTime')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-GamePlayTable-ColumnLabel-Bet')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-GamePlayTable-ColumnLabel-Win')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-GamePlayTable-ColumnLabel-Balance')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            ${displayHistoryData(el)}
                        </tbody>
                    </table>
                </div>
                <div class="inner-container table-bonus ${el.selectedTransaction == 'bonus' ? 'show' : ''}">
                    <table>
                        <thead>
                        <tr>
                            <th class="bonus-name">${APP_STRINGS.getWPMLString('SideViewBank-History-BonusesTable-ColumnLabel-BonusName')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-BonusesTable-ColumnLabel-BonusType')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-BonusesTable-ColumnLabel-Amount')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-BonusesTable-ColumnLabel-FromTo')}</th>
                            <th>${APP_STRINGS.getWPMLString('SideViewBank-History-BonusesTable-ColumnLabel-Status')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            ${displayHistoryData(el)}
                        </tbody>
                    </table>
                </div>
                <div class="inner-container table-races ${el.selectedTransaction == 'races' ? 'show' : ''}">
                    <table>
                        <thead>
                        <tr>
                            <th>Race</th>
                            <th>Opted In At</th>
                            <th>Spins</th>
                            <th>Score</th>
                            <th>Rank</th>
                            <th>Win</th>
                        </tr>
                        </thead>
                        <tbody>
                            ${displayHistoryData(el)}
                        </tbody>
                    </table>
                </div>
                <div class="inner-container table-shop ${el.selectedTransaction == 'shop' ? 'show' : ''}">
                    <div class="table-menu">
                        ${displayShopSubMenu(el)}
                    </div>
                    ${displayShopSubMenuTable(el)}
                </div>
            </div>
        </div>
    </div>
</div>

`;

function displayPresetDate(el){
    const displayList = [];
    if(el.presetFilterDate){
        for (const [key, value] of Object.entries(el.presetFilterDate)) {
            if(key == 'custom'){
                continue;
                displayList.push(html`
                    <button class="tab-capsule ${(key == el.selectedFilterDateKey) ? 'active' : ''}" @click=${el.onToggleCustomBox} ?disabled=${el.loadingHistoryData}>
                        ${value.displayName} 
                        <img src="${CDN_ASSETS_URL}/${el.isShowCustomBox ? 'up' : 'down'}.svg">
                    </button>
                `)
                continue
            }
            displayList.push(html`
                <!-- <button class="tab-capsule active">${value.displayName}</button> -->
                <button class="tab-capsule ${(key == el.selectedFilterDateKey) ? 'active' : ''}" @click=${el.onClickPresetDate} data-preset-date-key="${key}" ?disabled=${el.loadingHistoryData}>
                    ${value.displayName}
                </button>
            `)
        }
        return displayList
    }
}

function displayTransactionType(el){
    const displayList = [];
    if(el.transactionType){
        for (const [key, value] of Object.entries(el.transactionType)) {
            displayList.push(html`
                <button class="tab-capsule history-type ${(key == el.selectedTransaction) ? 'active' : ''}" 
                @click=${el.onClickTransactionType} data-transaction-key="${key}" ?disabled=${el.loadingHistoryData}>
                    ${value.displayName}
                </button>
            `)
        }
        return displayList
    }
}

function getSelectedDateRange(el){
    var startDate = ''
    var endDate = ''

    startDate = Helpers.formatDate(el.presetFilterDate[el.selectedFilterDateKey].startDate)
    endDate = Helpers.formatDate(el.presetFilterDate[el.selectedFilterDateKey].endDate)

    return startDate+' - '+endDate
    // 01/01/01 - 02/02/02
}

function displayShopSubMenuTable(el){
    console.log(el.selectedShopSubMenu)
    if(el.selectedShopSubMenu === 0){
        return html`
        <table class="table-points">
            <thead>
                <tr>
                    <th>Session ID</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Points</th>
                </tr>
            </thead>
            <tbody>
            ${displayHistoryData(el)}
            </tbody>
        </table>
    `
    }else{
        return html`
        <table class="table-purchases">
            <thead>
                <tr>
                    <th>Purchase Date</th>
                    <th>Item</th>
                    <th>Points</th>
                </tr>
            </thead>
            <tbody>
                ${displayHistoryData(el)}
            </tbody>
        </table>
    `
    }
}

function displayShopSubMenu(el){
    const displayList = [];
    if(el.selectedTransaction == 'shop'){
        for (const [key, value] of Object.entries(el.transactionType[el.selectedTransaction].subMenu)) {
            displayList.push(html`
                <div class="menu ${(key == el.selectedShopSubMenu) ? 'selected' : ''}" @click=${el.onToggleShopSubMenu} data-sub-menu-key='${key}'>
                    <span>${value}</span>
                </div>
            `) 
        }
        return displayList
    }
}

function displayHistoryData(el){
    var spanQuantity = 0;
    var isEmpty = false;
    const displayList = []
    switch(el.selectedTransaction){
        case 'transaction':
            spanQuantity = 5;
            if(el.historyData.transactionData){
                if(el.historyData.transactionData.Transactions && el.historyData.transactionData.Transactions.PaymentTransaction){
                    if(el.historyData.transactionData.Transactions.PaymentTransaction.length > 0){
                        for (const [key, value] of Object.entries(el.historyData.transactionData.Transactions.PaymentTransaction)) {
                            displayList.push(html `
                                <tr>
                                    <td>${value.Timestamp}</td>
                                    <td>${value.TransactionTypeName}</td>
                                    <td>${(value.TransactionTypeName == 'Deposit') ? '+' : '-'} ${el.userData.CurrencySymbol}${Helpers.formatCurrencyCents(value.Amount,true)}</td>
                                    <td>${value.TransactionStatusName}</td>
                                    <td>${value.PaymentDisplayName}</td>
                                </tr>
                            `)
                        }
                        break;
                    }
                }
            }
            isEmpty = true;
        break;
        case 'gamePlay':
            spanQuantity = 5;
            if(el.historyData.gamePlayData){
                if(el.historyData.gamePlayData.Transactions){
                    if(el.historyData.gamePlayData.Transactions.PlayerTrail){
                        if(el.historyData.gamePlayData.Transactions.PlayerTrail){
                            if(el.historyData.gamePlayData.Transactions.PlayerTrail.length > 0){
                                for (const [key, value] of Object.entries(el.historyData.gamePlayData.Transactions.PlayerTrail)) {
                                    var winAmount = '0'
                                    if(value.TransactionType == 'Win'){
                                        if(value.winTotal){
                                            winAmount = Helpers.formatCurrencyCents(value.WinTotal)
                                        }else if(value.CreditReal){
                                            winAmount = Helpers.formatCurrencyCents(value.CreditReal)
                                        }
                                    }
                                    displayList.push(html `
                                        <tr>
                                            <td>${value.GameName}</td>
                                            <td>${value.Timestamp}</td>
                                            <td>${el.userData.CurrencySymbol}${Helpers.formatCurrencyCents(value.BetTotal)}</td>
                                            <td>${el.userData.CurrencySymbol}${winAmount}</td>
                                            <td>${el.userData.CurrencySymbol}${Helpers.formatCurrencyCents(value.BalanceReal)}</td>
                                        </tr>
                                    `)                                    
                                }
                                break;
                            }
                        }
                    }
                }
            }
            isEmpty = true
        break;
        case 'bonus': 
            spanQuantity = 5;
            if(el.historyData.bonusData){
                if(el.historyData.bonusData.BonusAwarded){
                    if(el.historyData.bonusData.BonusAwarded.length > 0){
                        for (const [key, value] of Object.entries(el.historyData.bonusData.BonusAwarded)) {
                            var completionDate = '-';
                            var status = '-'
                            if(value.SecuredDate){
                                completionDate = Helpers.formatDate(value.SecuredDate);
                            }else if(value.LostDate){
                                completionDate = Helpers.formatDate(value.LostDate);
                            }else if(value.UsedAllDate){
                                completionDate = Helpers.formatDate(value.UsedAllDate);
                            }else{
                                completionDate = "-";
                            }

                            if(value.IsLost){
                                status= APP_STRINGS.getStringById("2224082");
                            }else if(value.IsUsedAll){
                                status = APP_STRINGS.getStringById("2224083");
                            }else{
                                status ="-";
                            }
                            displayList.push(html `
                                <tr>
                                    <td>${value.bonusTitleTag}</td>
                                    <td>${value.cappBonusTypeName}</td>
                                    <td>${el.userData.CurrencySymbol}${Helpers.formatCurrencyCents(value.BonusAmountRemaining)}</td>
                                    <td>${Helpers.formatDate(value.GivenDate)} - ${completionDate}</td>
                                    <td>${status}</td>
                                </tr>
                            `)
                        }
                        break;
                    }
                }
            }
            isEmpty = true
        break;
        case 'races':
            spanQuantity = 5;
            if(el.historyData.racesData){
                if(el.historyData.racesData.TournamentPlayerStatus){
                    if(el.historyData.racesData.TournamentPlayerStatus.length > 0){
                        html `
                            <tr>
                                <td>Race Name Will Go Here</td>
                                <td>01/01/01 22:22</td>
                                <td>123</td>
                                <td>123</td>
                                <td>3rd</td>
                                <td>€50</td>
                            </tr>
                        `
                        break;
                    }
                }
            }
            isEmpty = true
        break;
        case 'shop':
            if(el.historyData.shopData){
                if(el.selectedShopSubMenu == 0){
                    spanQuantity = 4
                    if(el.historyData.shopData.points){
                        if(el.historyData.shopData.points.Sessions){
                            if(el.historyData.shopData.points.Sessions.SessionStat){
                                if(el.historyData.shopData.points.Sessions.SessionStat.length > 0){
                                    for (const [key, value] of Object.entries(el.historyData.shopData.points.Sessions.SessionStat)) {
                                        var dateOpen = new Date(value.DateOpen)
                                        var dateClose = new Date(dateOpen.setMinutes( dateOpen.getMinutes() + value.PlayTimeMinutes ));
                                        var timeClose = dateClose.toTimeString().split(' ')[0]
                                        dateClose = Helpers.formatDate(dateClose)
                                        displayList.push(html`
                                            <tr>
                                                <td>${value.SessionID}</td>
                                                <td>${Helpers.formatDate(value.DateOpen)} ${dateOpen.toTimeString().split(' ')[0]}</td>
                                                <td>${dateClose} ${timeClose}</td>
                                                <td>${parseInt(value.LoyaltyPoints).toLocaleString()}</td>
                                            </tr>
                                        `)
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }else{
                    spanQuantity = 3;
                    if(el.historyData.shopData.purchases){
                        if(el.historyData.shopData.purchases.RedemptionTransactions){
                            if(el.historyData.shopData.purchases.RedemptionTransactions.LoyaltyRedemptionTransaction){
                                if(el.historyData.shopData.purchases.RedemptionTransactions.LoyaltyRedemptionTransaction.length > 0){
                                    for (const [key, value] of Object.entries(el.historyData.shopData.purchases.RedemptionTransactions.LoyaltyRedemptionTransaction)) {
                                        var transactionDate = new Date(value.TransactionDate)
                                        displayList.push(html`
                                            <tr>
                                                <td>${Helpers.formatDate(value.TransactionDate)} ${transactionDate.toTimeString().split(' ')[0]}</td>
                                                <td>${value.LoyaltyShopTitleTag}</td>
                                                <td>${parseInt(value.LoyaltyPoints).toLocaleString()}</td>
                                            </tr>
                                        `)
                                    }
                                    break;
                                }
                            }

                        }
                    }
                }
            }
            isEmpty = true
        break;
        default: 
            console.log(el.selectedTransaction+' no this type')
        break;
    }

    if(isEmpty){
        return html`<tr><td colspan="${spanQuantity}" style="text-align:center;">${APP_STRINGS.getWPMLString('SideViewBank-History-Table-NoData-Label')}</td></tr>`
    }else{
        return displayList
    }
    
}