import {render} from "lit-html";
import {FormHelpers} from "../../../util/FormHelpers";
import {TemplateBonusesContentView} from "../../../../templates/template-items/side-view/TemplateBonusesContentView";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import { USER } from "@/js/core/User";
import {Popup} from "@/js/ui/Popup";

export class SideViewSubBonuses extends SideViewSubBaseClass{
    constructor(container) {
        super(container);
        this.loadingBonusData = false;
        this.loadingClaimBonus = false;
    }

    _init() {        
        this.expandedBonusItems = []
        this.availableBonusList = null
        this.activeBonusList = null;
        this.loadingBonusData=true;
        this.loadingClaimBonus = false;
        this.voucherCodeLoading = false;
        this.getAllBonusData();
        super._init();
    }

    renderView(){
        render(TemplateBonusesContentView(this),this.element,{eventContext:this});
        FormHelpers.initializeFormComponents(this.element);
        this._setEventListener()
    }

    updateView(){
        render(TemplateBonusesContentView(this),this.element,{eventContext:this}); 
    }

    _setEventListener(){
        let voucherCode = document.querySelector(".mdc-voucher-code").MDCTextField
        voucherCode.listen('change', (e) => {
            var voucherCode = e.currentTarget.MDCTextField
            if(voucherCode.value == ""){return;}
            this.voucherCodeLoading=true;
            this.updateView();

            voucherCode.disabled = true;
            return USER.redeemVoucherCode(voucherCode.value).then(data=>{
                voucherCode.disabled = false;
                this.voucherCodeLoading=false;
                this.updateView();
                if(data.error){

                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else{
                    this.getAllBonusData()
                    new Popup(APP_STRINGS.getTitleById('2223043'),APP_STRINGS.getStringById('2223043'),[APP_STRINGS.OK]);
                }
            })
        });
    }

    getAllBonusData(){
        return USER.getBonusAllData().then(data=>{
            this.loadingBonusData = false;
            this.availableBonusList = {}
            this.activeBonusList = {}
            this.availableBonusList.depositBonusList = data.depositBonuses
            this.availableBonusList.manualBonusList = data.manualBonuses
            this.activeBonusList.otherActiveBonusList = data.activeBonuses
            this.activeBonusList.topActiveBonus = data.topActiveBonus
            this.updateView()
        });
    }

    onToggleMoreDetail(e){
        let bonusId=parseInt(e.currentTarget.dataset.bonusId);
        let index=this.expandedBonusItems.indexOf(bonusId);
        if(index<0){
            this.expandedBonusItems.push(bonusId);
        }else{
            this.expandedBonusItems.splice(index,1);
        }
        this.updateView();
    }

    close() {
        this.removeAllListeners("OnDepositBonusActivateRequest");
        super.close();
    }

    onClickActivateBonus(e){
        var bonusID = e.currentTarget.dataset.bonusId
        this.emit("OnDepositBonusActivateRequest",{bonusID:bonusID});
    }

    onClickClaimBonus(e){
        let bonusAwardedID = e.currentTarget.dataset.bonusAwardedId;
        let bonusController = this;
        //ADD LOADING TO THIS BUTTON
        this.loadingBonusData = true;
        this.updateView()

        let selectedBonus=null;
        let goToGameUrl=null;

        for(let i=0; i<this.availableBonusList.manualBonusList.length; i++){
            if(this.availableBonusList.manualBonusList[i].BonusAwardedPreID.toString()==bonusAwardedID){
                selectedBonus=this.availableBonusList.manualBonusList[i];
                break;
            }
        }
        if(selectedBonus && selectedBonus.goToGameUrl && selectedBonus.goToGameUrl!=""){
           goToGameUrl=selectedBonus.goToGameUrl;
        }



        USER.claimBonus(bonusAwardedID).then(data=>{
            if(data.error){
                this.loadingBonusData = false;
                this.updateView()
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                bonusController.getAllBonusData()

                let btnLabel=goToGameUrl?APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-GameLink'):APP_STRINGS.OK;
                new Popup(APP_STRINGS.getTitleById("2224035"),APP_STRINGS.getStringById("2224035"),[btnLabel],(btnId)=>{
                    if(goToGameUrl){
                        window.location.href=goToGameUrl;
                    }

                });
            }
        })
    }

    onClickSetTopPriority(e){
        let bonusAwardedID = e.currentTarget.dataset.bonusAwardedId;
        let bonusController = this;
        this.loadingBonusData = true;
        this.updateView()
        USER.setTopPriorityBonus(bonusAwardedID).then(data=>{
            if(data.error){
                this.loadingBonusData = false;
                this.updateView()
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                bonusController.getAllBonusData()
            }
        })
        
    }

    onClickForfeitBonus(e){
        let bonusAwardedID = e.currentTarget.dataset.bonusAwardedId;
        let bonusController = this;
        this.loadingBonusData = true;
        this.updateView()
        USER.forfeitBonus(bonusAwardedID).then(data=>{
            if(data.error){
                this.loadingBonusData = false;
                this.updateView()
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                bonusController.getAllBonusData()
            }
        })
    }
}