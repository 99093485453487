import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";

export const TemplateBonusesContentView = (el) => html`
<div id="bonus">
    <div class="content">
        <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingBonusData ? 'flex' : 'none'};" ></preloader-element>
        <div class="bonus-container" style="display: ${el.loadingBonusData ? 'none' : 'block'  };">
            <section class="top-active-bonus ${(el.activeBonusList && el.activeBonusList.topActiveBonus) ? '' : 'empty'}">
                <h4>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-TopActiveBonusSection-Title')}</h4>
                <div class="bonus-divider">
                    <div class="bonus-box">
                        <span class="bonus-title Heading-5">${(el.activeBonusList && el.activeBonusList.topActiveBonus) ? el.activeBonusList.topActiveBonus.bonusTitleTag : ''}</span>
                        <div class="progress-bar">
                            <div class="ui-progress-bar">${displayProgressBar(el)}</div>
                            <span class="progress-description Paragraph-Small">${(el.activeBonusList && el.activeBonusList.topActiveBonus) ? el.activeBonusList.topActiveBonus.displayData.wagerRemained : ''} ${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-TopActiveBonusSection-WageringInfo-Text')}</span>
                        </div>
                        ${showTopActiveSubDesc(el)}
                    </div>
                </div>
                <div class="empty-info">
                    <img src="${CDN_ASSETS_URL}/info.svg">
                    <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-TopActiveBonusSection-NoActiveBonus-Text')}</span>
                </div>
            </section>
            <section class="other-active-bonus ${(el.activeBonusList && el.activeBonusList.otherActiveBonusList) ? '' : 'empty'}">
                <h4>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-ActiveBonusesSection-Title')}</h4>
                <div class="bonus-divider">
                    ${displayActiveBonuses(el)}
                </div>
                <div class="empty-info">
                    <img src="${CDN_ASSETS_URL}/info.svg">
                    <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-ActiveBonusesSection-NoActiveBonuses-Text')}</span>
                </div>
            </section>
            <section class="available-bonus">
                <h4>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-Title')}</h4>
                <div class="bonus-divider">
                    ${displayAvailableBonuses(el)}
                    <div class="bonus-box">
                        <span class="bonus-title Heading-5">${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-VoucherCodeItem-Title')}</span>
                        <form class="voucher-code-form" @submit=${e=>e.preventDefault()}>
                            <div class="form-element-2">
                                <label class="mdc-text-field mdc-voucher-code" >
                                    ${el.voucherCodeLoading ? html`<preloader-element class="primary"></preloader-element>` : ''}
                                    <div class="mdc-text-field__ripple"></div>
                                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" spellcheck="false">
                                    <span class="mdc-floating-label" id="my-label-id">${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-VoucherCodeItem-InputLabel-VoucherCode')}</span>
                                    <div class="mdc-line-ripple"></div>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>    
    
        
    
`;

function showTopActiveSubDesc(el){
    if(el.activeBonusList && el.activeBonusList.topActiveBonus){
        let expanded = el.expandedBonusItems.indexOf(el.activeBonusList.topActiveBonus.BonusRuleID)<0 ? false : true;
        return html`
            <div class="sub-desc ${expanded ? 'show' : ''}">
                <div class="desc-list">
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusType')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.cappBonusTypeName}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusAmount')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.bonusAmount}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-WageringRequirement')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.wageringReq}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-WageringProgress')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.wagerAchieved}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MaximumBet')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.maxBet}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-Activated')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.activationDate}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-ExpiryDate')}</label>
                        <span class="Paragraph-Small-Bold">${el.activeBonusList.topActiveBonus.displayData.expiryDate}</span>
                    </div>
                    <div class="desc">
                        <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusGames')}</label>
                        <span class="Paragraph-Small-Bold">${unsafeHTML(el.activeBonusList.topActiveBonus.cappGames)}</span>
                    </div>
                    <div class="desc">
                        <button class="secondary-button" data-bonus-awarded-id="${el.activeBonusList.topActiveBonus.BonusAwardedID}" @click=${el.onClickForfeitBonus}>
                            ${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-ForfeitBonus')}
                        </button>
                    </div>
                </div>
                <a class="Text-Link-Small see-more" @click=${el.onToggleMoreDetail} data-bonus-id="${el.activeBonusList.topActiveBonus.BonusRuleID}">
                    <span>${expanded ? APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Collapse') : APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Expand')} </span>
                    <img src="${CDN_ASSETS_URL}/${expanded ? 'up.svg' : 'down.svg'}">
                </a>
            </div>
        `
    }else{
        return ''
    }
}

function displayAvailableBonuses(el){
    const displayList = [];
    if(el.availableBonusList){
        if(el.availableBonusList.depositBonusList){
            let depositBonusList = el.availableBonusList.depositBonusList
            for (const [key, value] of Object.entries(depositBonusList)) {
                let expanded = el.expandedBonusItems.indexOf(value.BaseRule.BonusRuleID)<0 ? false : true;
                displayList.push(html`
                    <div class="bonus-box">
                        <span class="bonus-title Heading-5">${value.bonusTitleTag}</span>
                        <div class="progress-bar">
                            <span class="progress-description Paragraph-Small">${value.bonusBrief}</span>
                        </div>
                        <button class="secondary-button" @click=${el.onClickActivateBonus} data-bonus-id="${value.BaseRule.BonusRuleID}">${value.displayData.buttonName}</button>
                        <div class="sub-desc ${expanded ? 'show' : ''}">
                            <div class="desc-list">
                                <div class="desc">
                                    ${value.IsPercentage == true ?
                                        html`<label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MatchAmount')}</label> 
                                            <span class="Paragraph-Small-Bold">${parseFloat(value.Percentage*100)}%</span>`
                                        :
                                        html`<label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusAmount')}</label> 
                                            <span class="Paragraph-Small-Bold">${value.displayData.fixedAmount}</span>`
                                    }
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MaximumBonusAmount')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.matchAmount}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MinimumDeposit')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.minDeposit}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-WageringRequirement')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.wageringReq}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MaximumBet')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.maxBet}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-ExpiryDate')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.expiryDate}</span>
                                </div class="desc">
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusGames')}</label> 
                                    <span class="Paragraph-Small-Bold games-item">${unsafeHTML(value.cappGames)}</span>
                                </div>
                            </div>
                            <a class="Text-Link-Small see-more" @click=${el.onToggleMoreDetail} data-bonus-id="${value.BaseRule.BonusRuleID}">
                                <span>${expanded ? APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Collapse') : APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Expand')} </span>
                                <img src="${CDN_ASSETS_URL}/${expanded ? 'up.svg' : 'down.svg'}">
                            </a>
                        </div>
                    </div>
                `)
            }
        }
        if(el.availableBonusList.manualBonusList){
            let manualBonusList = el.availableBonusList.manualBonusList
            for (const [key, value] of Object.entries(manualBonusList)) {
                let expanded = el.expandedBonusItems.indexOf(value.BonusRuleID)<0 ? false : true;
                displayList.push(html`
                    <div class="bonus-box">
                        <span class="bonus-title Heading-5">${value.bonusTitleTag}</span>
                        <div class="progress-bar">
                            <span class="progress-description Paragraph-Small">${value.bonusBrief}</span>
                        </div>
                        <button class="secondary-button" @click=${el.onClickClaimBonus} data-bonus-awarded-id="${value.BonusAwardedPreID}">${value.displayData.buttonName}</button>
                        <div class="sub-desc ${expanded ? 'show' : ''}">
                            <div class="desc-list">
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusAmount')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.bonusAmount}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-WageringRequirement')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.wageringReq}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MaximumBet')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.maxBet}</span>
                                </div>
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-ExpiryDate')}</label> 
                                    <span class="Paragraph-Small-Bold">${value.displayData.expiryDate}</span>
                                </div class="desc">
                                <div class="desc">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusGames')}</label> 
                                    <span class="Paragraph-Small-Bold games-item">${unsafeHTML(value.cappGames)}</span>
                                </div>
                            </div>
                            <a class="Text-Link-Small see-more" @click=${el.onToggleMoreDetail} data-bonus-id="${value.BonusRuleID}">
                                <span>${expanded ? APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Collapse') : APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Expand')} </span>
                                <img src="${CDN_ASSETS_URL}/${expanded ? 'up.svg' : 'down.svg'}">
                            </a>
                        </div>
                    </div>
                `)
            }
        }
    }
    return displayList;
}

function displayActiveBonuses(el){
    const displayList = [];
    if(el.activeBonusList && el.activeBonusList.otherActiveBonusList){
        let otherActiveBonusList = el.activeBonusList.otherActiveBonusList
        for (const [key, value] of Object.entries(otherActiveBonusList)) {
            let expanded = el.expandedBonusItems.indexOf(value.BonusRuleID)<0 ? false : true;
            displayList.push(html`
                <div class="bonus-box">
                    <span class="bonus-title Heading-5">${value.bonusTitleTag}</span>
                    <div class="progress-bar">
                        <span class="progress-description Paragraph-Small">${value.bonusBrief}</span>
                    </div>
                    <button class="secondary-button" @click=${el.onClickSetTopPriority} data-bonus-awarded-id="${value.BonusAwardedID}">${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-SetAsTopActiveBonus')}</button>
                    <div class="sub-desc ${expanded ? 'show' : ''}">
                        <div class="desc-list">
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusType')}</label>
                                <span class="Paragraph-Small-Bold">${value.cappBonusTypeName}</span>
                            </div>
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusAmount')}</label>
                                <span class="Paragraph-Small-Bold">${value.displayData.bonusAmount}</span>
                            </div>
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-WageringRequirement')}</label>
                                <span class="Paragraph-Small-Bold">${value.displayData.wageringReq}</span>
                            </div>
                            <!-- <div class="desc">
                                <label>Wagered so far</label>
                                <span class="Paragraph-Small-Bold">€10</span>
                            </div> -->
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-MaximumBet')}</label>
                                <span class="Paragraph-Small-Bold">${value.displayData.maxBet}</span>
                            </div>
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-Activated')}</label>
                                <span class="Paragraph-Small-Bold">${value.displayData.activationDate}</span>
                            </div>
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-ExpiryDate')}</label>
                                <span class="Paragraph-Small-Bold">${value.displayData.expiryDate}</span>
                            </div>
                            <div class="desc">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-Field-BonusGames')}</label>
                                <span class="Paragraph-Small-Bold">${unsafeHTML(value.cappGames)}</span>
                            </div>
                            <div class="desc">
                                <button class="secondary-button" data-bonus-awarded-id="${value.BonusAwardedID}" @click=${el.onClickForfeitBonus}>FORFEIT</button>
                            </div>
                        </div>
                        <a class="Text-Link-Small see-more" @click=${el.onToggleMoreDetail} data-bonus-id="${value.BonusRuleID}">
                            <span>${expanded ? APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Collapse') : APP_STRINGS.getWPMLString('SideViewBank-Bonuses-AvailableBonusesSection-BonusItem-ButtonLabel-Expand')} </span>
                            <img src="${CDN_ASSETS_URL}/${expanded ? 'up.svg' : 'down.svg'}">
                        </a>
                    </div>
                </div>
            `)
        }
    }
    return displayList;
}

function displayProgressBar(el){
    let bonusPercentage = 0;
    if(el.activeBonusList){
        if(el.activeBonusList.topActiveBonus && el.activeBonusList.topActiveBonus.BonusWagerRequirementAchievedPercentage){
            bonusPercentage = el.activeBonusList.topActiveBonus.BonusWagerRequirementAchievedPercentage
        }
    }
    return html`<div class="progress" style="width: ${bonusPercentage}%"></div>`
}