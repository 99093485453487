import {html} from 'lit-html';

export const TemplateFTInboxRead = el => html`
    <div class="capp-ft-inbox-overlay">
        <div class="capp-ft-inbox-popup-wrapper">
            <div class="close-img-container">
                <img @click="${el.close}" class="close-img" class="ft-close-img" src="${CDN_ASSETS_URL}/close.svg">
            </div>
            <div class="inbox-read-header">
                <img src="https://cdn.urbancasino.net/images/site-assets/site-logo-ani@x1.gif" />
                <h2>${el.data.Title}</h2>
            </div>
            <div class="inbox-read-content">
                <pre>
                ${el.data.Message}
                </pre>
            </div>
            <div class="inbox-read-footer">
                NOTE: Urban Casino will never email you and ask you to disclose or verify your password, credit card, or bank account information. If you receive a suspicious email with a link to update your account information, do not click on the link. Instead, report the email to us so that we can investigate.
            </div>
        </div>
    </div>
  `;