import {render,html} from "lit-html";

export class PreloaderElement extends HTMLElement{
    constructor() {
        super();
        this.classList.add('preloader');
        console.log('created custom element');
        //this._renderUCPreloader();
    }

    connectedCallback() {
        console.log('Custom preloader element added to page.');
        this._renderUCPreloader();
        //updateStyle(this);
    }

    _renderUCPreloader(){
        render(html`
            <div class="u-preloader">
                <div class="p-wrap">
                    <div class="c c-0">
                        <div class="c-el"></div>
                    </div>
                    <div class="c c-1">
                        <div class="c-el"></div>
                    </div>
                    <div class="c c-2">
                        <div class="c-el"></div>
                    </div>
                    <div class="c c-3">
                        <div class="c-el"></div>
                    </div>
                    <div class="c c-4">
                        <div class="c-el"></div>
                    </div>
                    <div class="c c-5">
                        <div class="c-el dark"></div>
                    </div>
                    <div class="c c-6">
                        <div class="c-el dark"></div>
                    </div>
                    <div class="c c-7">
                        <div class="c-el dark"></div>
                    </div>
                </div>
            </div>
        `,this,{eventContext:this});
    }




}

customElements.define('preloader-element',PreloaderElement);

