import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateResetPasswordSideView = (el) => html`
<div class="view-heading">
    <div class="item-left">
        <span class="title">${APP_STRINGS.getWPMLString('SideViewResetPassword-Title')}</span>
    </div>
</div>
<div class="content">
    <form id="forgot-password-form" @submit="${el.onSubmitResetPassword}" class="${el.loading ? 'disabled' : ''}">
        <div class="form-element-2">
            <label class="mdc-text-field mdc-password">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="newPassword" autocapitalize="off">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewResetPassword-InputField-NewPassword')}
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>

            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" id="my-helper-id" aria-hidden="true">
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field mdc-confirm-password">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="confirmNewPassword" autocapitalize="off">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewResetPassword-InputField-PasswordRepeat')}
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>

            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" id="my-helper-id" aria-hidden="true">
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <button class="primary-button ${el.loading ? 'loading' : ''}">
                <span>${APP_STRINGS.getWPMLString('SideViewResetPassword-ButtonLabel-ResetPassword')}</span>
                <preloader-element></preloader-element>
            </button>
        </div>
    </form>
</div>
`