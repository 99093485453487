
import {SideViewSubDeposits} from "./SideViewSubDeposits";
import {BANK} from "../../../core/Bank";
import {Popup} from "../../Popup";

export class SideViewSubPIQDeposits extends SideViewSubDeposits{
    constructor(container) {
        super(container);
    }



    getDepositData(){
        console.log('get piq deposit data');
        BANK.getPIQDepositData().then(data=>{
            this.loadingDepositData=false;
            if(data.error){
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                this.depositData=data;
                this.presetValues=this._getPresetValues();
                console.log("BANK", BANK);
            }
            this.updateView();

            let inp=this.element.querySelector('#bonus-code-input-MDC');
            if(inp){
                this.bonusCodeInputMDC=inp.MDCTextField;
                this._setupBonusCodeListeners();
            }

            if(this.defaultBonus){
                this.selectBonus(this.defaultBonus);
            }
        })


    }


    onNextButtonClick(){
        if(this.depositButtonDisabled==true){return;}
        this.depositButtonDisabled=true;
        console.log('payment iq next');
        this._startStandardIframePayment(null);
        /*
        this.step2SlideOver=new SlideOverView(this.container.parentElement,TemplateDepositStepTwo,this);
        this.depositMethodAdditionalFormContainer=this.container.parentElement.querySelector("#payment-form-container");
        console.log('this.depositMethodAdditionalFormContainer',this.depositMethodAdditionalFormContainer);
        */

    }



}