import {Helpers} from "../util/Helpers";

class SideViewManager {
    constructor() {
        let _self = this;
        this._currentSideView=null;
        this._overlayElement=document.createElement("div");
        this._overlayElement.classList.add("content-overlay");
        this._overlayElement.addEventListener ("click", function() {
            console.log("output this: " + this);
            _self._currentSideView.close();
        });

    }

    openSideView(sideView){
        if(this._currentSideView){
            this._currentSideView.close();
        }
        this._currentSideView=sideView;
        document.body.appendChild(this._overlayElement);
        document.body.appendChild(this._currentSideView.element);
        this._currentSideView.renderViewContent();
        document.body.classList.add("sideview-active");
    }

    closeAnySideview(){
        if(this._currentSideView){
            this._currentSideView.close();
        }
        this.clear();
    }

    clear(){
        this._currentSideView=null;
        if($('.content-overlay').length > 0){
            document.body.removeChild(this._overlayElement);
        }
        document.body.classList.remove("sideview-active");
    }

}

export const SIDE_VIEW_MANAGER = new SideViewManager();