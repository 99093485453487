import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {USER} from "../../../../js/core/User";
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateVerificationTemp = (el) => html`
<div id="verification">
    <div class="content">
        <div class="verification-container">
            <div class="verification-info">
                <div class="info-alert">
                    <img src="${CDN_ASSETS_URL}/info.svg">
                    <span class="Paragraph-Bold">For your security we require you to provide us with some additional identification of yourself.</span>
                </div>
                <div class="info-description">
                    <p class="Paragraph-Normal">
                        To authorise withdrawing funds and to verify your casino account we need you to send us an image of your ID, proof of address and payment methods (excluding Neosurf voucher). Please send and image of your documents to <a class="Text-Link" href="mailto:docs@pokiemails.com">docs@pokiemails.com</a> and one of our team members will be get back to you within 24hours.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
`

export const TemplateVerification = el=> html`
<div id="verification">
    <div class="content">
        ${el.loadingData?html`
        <preloader-element class="primary" style="width: 100%; height: 5em;" ></preloader-element>
        `:html`
        <div class="verification-container">
            <div class="verification-items">
                <div class="v-item ${el.verificationData.basicDocs.proofOfIdentification}" @click=${el.onProofOfIdentificationClick}>
                    <div class="title">
                        <div class="status-icon">
                            ${getStatusImage(el.verificationData.basicDocs.proofOfIdentification)}
                        </div>
                        <span>${APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-ProofOfID-Title')}</span>
                    </div>
                    <div class="desc">
                        <span class="Paragraph-Normal">${getStatusText(el.verificationData.basicDocs.proofOfIdentification)}</span>
                    </div>
                </div>
                <div class="v-item ${el.verificationData.basicDocs.proofOfAddress}" @click=${el.onProofOfAddressClick}>
                    <div class="title">
                        <div class="status-icon">
                            ${getStatusImage(el.verificationData.basicDocs.proofOfAddress)}
                        </div>
                        <span>${APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-ProofOfAddress-Title')}</span>
                    </div>
                    <div class="desc">
                        <span class="Paragraph-Normal">${getStatusText(el.verificationData.basicDocs.proofOfAddress)}</span>
                    </div>
                </div>
                ${el.verificationData.accountsVerification.map((account,index)=>renderAccountItem(account,index, el))}
                
            </div>
            <div class="verification-info">
                <div class="info-alert">
                    <img src="${CDN_ASSETS_URL}/info.svg">
                    <span class="Paragraph-Bold">${APP_STRINGS.getWPMLString('SideViewUser-Verification-VerificationInfo-HeadingText')}</span>
                </div>
                <div class="info-description">
                    <p class="Paragraph-Normal">
                        ${unsafeHTML(APP_STRINGS.getWPMLString('SideViewUser-Verification-VerificationInfo-BodyText'))}
                    </p>
                </div>
            </div>
        </div>
        `}
        
    </div>
</div>
`;

function getStatusImage(status){
    switch (status) {
        case "approved":
            return html`<img class="success" src="${CDN_ASSETS_URL}/check.svg">`
            break;
        case "pending":
            return html`<img class="pending" src="${CDN_ASSETS_URL}/pending.svg">`;
            break;
        case "required":
            return html`<img class="warning" src="${CDN_ASSETS_URL}/warning.svg">`;
            break;
        default:
            return html`<img class="warning" src="${CDN_ASSETS_URL}/warning.svg">`;
    }

}
function getStatusText(status){
    switch (status) {
        case "approved":
            return APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-StateApproved-Label');
            break;
        case "pending":
            return APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-StatePending-Label');
            break;
        case "required":
            return APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-StateRequired-Label');
            break;
        default:
            return APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentsList-DocumentItem-StateRequired-Label');
    }

}

function renderAccountItem(account, index, el){
    return html`
    <div class="v-item ${account.status}" @click=${el.onPaymentMethodValidationClick} data-account-index="${index}">
        <div class="title">
            <div class="status-icon">
                ${getStatusImage(account.status)}
            </div>
            <span>${account.docTitle}</span>
        </div>
        <div class="desc">
            <span class="Paragraph-Normal">${getStatusText(account.status)}</span>
        </div>
    </div>
    `;
}

export const TemplateVerificationProofOfAddress = el => html`
<div class="view-with-heading" id="verification-id" >
    <div class="view-heading">
        <div class="item-left">
            <img @click=${el.onSlideOverBackButtonClick} class="back-img" src="${CDN_ASSETS_URL}/left.svg">
            <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfAddressSubview-Title')}</span>
        </div>
    </div>
    <div class="scrolling-content" >
        <div class="content">
            <div class="verification-id-container">
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfAddressSubview-SectionTitle')}</h4>
                ${renderDocumentContainer(el,USER.VERIFICATION_DOCUMENT_TYPE.PROOF_OF_ADDRESS)}
                <div class="info">
                    <p class="desc-1">
                        ${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfAddressSubview-ProofOfAddress-UploadInstructions-Text')}
                        
                    </p>
                    ${renderFileFormatsInstructions()}
                </div>
            </div>
        </div>
    </div>
</div>
`;

export const TemplateVerificationProofOfIdentification = el => html`
<div class="view-with-heading" id="verification-id" >
    <div class="view-heading">
        <div class="item-left">
            <img @click=${el.onSlideOverBackButtonClick} class="back-img" src="${CDN_ASSETS_URL}/left.svg">
            <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-Title')}</span>
        </div>
    </div>
    <div class="scrolling-content" >
        <div class="content">
            <div class="verification-id-container">
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-UploadInstructions-Text')}</h4>
                ${renderDocumentContainer(el,USER.VERIFICATION_DOCUMENT_TYPE.ID)}
                <div class="info">
                    <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-AcceptableDocuments-Text')}</span>
                    <ul>
                        <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-AcceptableDocumentsItem-DriversLicence')}</li>
                        <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-AcceptableDocumentsItem-ProofOfAge')}</li>
                        <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-ProofOfIdSubview-AcceptableDocumentsItem-IdOrPassport')}</li>
                    </ul>
                    ${renderFileFormatsInstructions()}
                </div>
            </div>
		</div>
    </div>
</div>
`;

export const TemplateVerificationPaymentMethod = el => html`
<div class="view-with-heading" id="verification-id" >
    <div class="view-heading">
        <div class="item-left">
            <img @click=${el.onSlideOverBackButtonClick} class="back-img" src="${CDN_ASSETS_URL}/left.svg">
            <span class="title">${el.selectedAccount.docTitle}</span>
        </div>
    </div>
    <div class="scrolling-content" >
        <div class="content">
            <div class="verification-id-container">
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-Verification-VerifyPaymentMethodSubview-Title')}</h4>
                ${renderDocumentContainer(el,USER.VERIFICATION_DOCUMENT_TYPE.ACCOUNT_DOCUMENT,el.selectedAccount.PaymentAccountID,el.selectedAccount.AccountReference)}
                <div class="info">
                    <p class="desc-1">
                        ${APP_STRINGS.getWPMLString('SideViewUser-Verification-VerifyPaymentMethodSubview-UploadInstructions-Text')}
                    </p>
                    <span class="Paragraph-Normal">
                        ${APP_STRINGS.getWPMLString('SideViewUser-Verification-VerifyPaymentMethodSubview-UploadInstructions-ExampleLabel')}
                    </span>
                    <span><img src="${CDN_ASSETS_URL}/verification-card-example.png"></span>
                    ${renderFileFormatsInstructions()}
                </div>
            </div>
		</div>
    </div>
</div>
`;

function renderDocumentContainer(el, docId, accountId, accountReference){
    return html`
    <div class="upload-container">
        <div class="inner-container" @drop=${el.onFileDropped}>
            ${el.documentUploading ? html`
                <div class="loading">
                    <preloader-element class="primary"></preloader-element>
                </div>
            ` : html`
                ${el.documentToUpload.file==null?html`
                    ${el.mobileMode ? html`
                        <div class="upload-instruction mobile-info">
                            <img class="" src="${CDN_ASSETS_URL}/upload.svg">
                            <span class="desc-1">${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-UploadDocumentMobile-Text')}</span>
                            <button class="secondary-button" @click=${el.onSelectFileClick}>
                                ${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-ButtonLabel-UseCamera')}
                            </button>
                            <span class="desc-2">${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-OrText')}</span>
                            <div class="msg" @click=${el.onSelectFileClick}>
                                ${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-ButtonLabel-SelectFilesMobile')}
                            </div>
                            <input type="file" name="document" accept="image/*,application/pdf" @change=${el.onFileSelected}>
                        </div>
                    `:html`
                        <div class="upload-instruction">
                            <img class="" src="${CDN_ASSETS_URL}/upload.svg">
                            <span class="desc-1">${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-DragAndDropText')}</span>
                            <span class="desc-2">${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-OrText')}</span>
                            <button class="secondary-button" @click=${el.onSelectFileClick}>
                                ${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-ButtonLabel-SelectFiles')}
                            </button>
                            <input type="file" name="document" accept="image/*,application/pdf" @change=${el.onFileSelected}>
                        </div>
                    `}
                
                `:html`
                <div class="preview">
                    <img src="${el.documentToUpload.imageURL}">
                    <button class="primary-button" data-document-id="${docId}" data-account-id="${accountId}" data-account-reference="${accountReference}"  @click=${el.onSubmitButtonClick}>
                        ${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadDocumentSection-ButtonLabel-UploadDocument')}
                    </button>
                    <button class="cancel-document" @click=${el.onClearSelectedFile}><img src="${CDN_ASSETS_URL}/close.svg"></button>
                </div>
                `}
            `}
        </div>
    </div>
    `;
}

function renderFileFormatsInstructions(){
    return html`
    <p class="instruction">
        ${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadInstructionsText')}
    </p>
    <div>
        <div class="info-alert">
            <img src="${CDN_ASSETS_URL}/info.svg">
            <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadFormatsInfo-HeadingText')}</span>
        </div>
        <div class="info-description">
            <ul>
                <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadFormatsInfo-InfoText-1')}</li>
                <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadFormatsInfo-InfoText-2')}</li>
                <li>${APP_STRINGS.getWPMLString('SideViewUser-Verification-UploadFormatsInfo-InfoText-3')}</li>
            </ul>
        </div>
    </div>
    `;
}
