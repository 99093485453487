import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateChangePhoneNumber = (el) => html`
    <div class="view-heading">
			<div class="item-left">
				<img @click=${el.onSlideOverBackButtonClick} class="back-img" src="${CDN_ASSETS_URL}/left.svg">
				<span class="title">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-Title')}</span>
			</div>
		</div>
		<div class="content">
		    ${el.loadingRegisterInfo ? html`
		    <preloader-element class="primary" style="width: 100%; height: 5em; display: flex;" ></preloader-element>
		    ` : html`
		    <form class="profile-form ${el.loadingUpdate ? 'disabled' : ''}" id="change-phone-number-form" autocomplete="off" 
			@submit=${el.onSubmitUpdateProfile} style="display: ${el.loadingRegisterInfo ? 'none' : 'block'  };" data-update-action="phone">
				<div class="form-element-2">
					<div class="inputs-group">
						<div class="mdc-select mdc-mobile-prefix">
							<div class="mdc-select__anchor">
								<i class="mdc-select__dropdown-icon"></i>

								<div id="demo-selected-text" class="mdc-select__selected-text" role="button"
									 aria-haspopup="listbox"
									 aria-labelledby="demo-label demo-selected-text"></div>
								<span id="demo-label"
									  class="mdc-floating-label mdc-floating-label--float-above">
									  ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-Field-NumberPrefix-Label')}
								</span>

								<div class="mdc-line-ripple"></div>
							</div>

							<div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
								<ul class="mdc-list">
									${getMobilePrefixList(el)}
								</ul>
							</div>
						</div>

						<label class="mdc-text-field mdc-mobile-number">
							<div class="mdc-text-field__ripple"></div>
							<input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
								<span class="mdc-floating-label" id="my-label-id">
								    ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-Field-PhoneNumber-Label')}
								</span>

							<div class="mdc-line-ripple"></div>
						</label>
					</div>
				</div>
				<input type="hidden" name="action" value="updatePhoneNumber">
				<div class="form-element-2">
					<button class="primary-button ${el.loadingUpdate ? 'loading' : ''}"><span>
    					${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePhoneNumberSubview-ButtonLabel-ApplyChanges')}
					</span><preloader-element></preloader-element></button>
				</div>
			</form>
		    `}
			
		</div>
`

function getMobilePrefixList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var prefixList = el.registerInfo.countryCodeInfo.DialingCodes
        const displayList = [];
        for (const [key, value] of Object.entries(prefixList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value}" role="option">
                        +${value}
                    </li>
                `
            ) 
        }
        return displayList
    }
}