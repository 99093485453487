import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateChangeAddress = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click="${el.onSlideOverBackButtonClick}">
            <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-Title')}</span>
        </div>
    </div>
    <div class="content">
        <form class="profile-form ${el.loadingUpdate ? 'disabled' : ''}" id="change-address-form" @submit=${el.onSubmitUpdateProfile} data-update-action="address">
            <div class="form-element-2">
                <label class="mdc-text-field mdc-address1">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="address1">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-Field-Address-Label')}
                        </span>

                    <div class="mdc-line-ripple"></div>
                </label>
            </div>

            <div class="form-element-2">
                <label class="mdc-text-field mdc-address2">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="address2">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-Field-Address2-Label')}
                        </span>

                    <div class="mdc-line-ripple"></div>
                </label>
            </div>

            <div class="form-element-2">
                <label class="mdc-text-field mdc-city">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="city">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-Field-City-Label')}
                        </span>

                    <div class="mdc-line-ripple"></div>
                </label>
            </div>

            <div class="form-element-2">
                <label class="mdc-text-field mdc-postcode">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="postcode">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-Field-PostCode-Label')}
                        </span>

                    <div class="mdc-line-ripple"></div>
                </label>
            </div>
            <input type="hidden" name="action" value="updateAddress">
            <div class="form-element-2">
                <button class="primary-button ${el.loadingUpdate ? 'loading' : ''}"><span>
                    ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangeAddressSubview-ButtonLabel-ApplyChanges')}
                </span><preloader-element></preloader-element></button>
            </div>
        </form>
    </div>
`