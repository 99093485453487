import {render} from "lit-html";
import {TemplateFTInboxRead} from "../../templates/template-items/TemplateFTInboxRead";

export class FTInboxRead{
    constructor(data) {
        this.container=document.getElementsByTagName("BODY")[0];
        this.overlayPopup=document.createElement("div");
        this.overlayPopup.classList.add("fasttrack-overlay-popup");
        this.container.appendChild(this.overlayPopup);
        this.data = data;
        this.template = TemplateFTInboxRead(this);

        this._init();
    }

    _init(){
        this._updateView();
        this.closeBtn = this.overlayPopup.querySelector(".close-img-container");

        var _self = this;
        this.closeBtn.addEventListener("click", function(e) {
            _self.destroy();
        }, false);

    }

    _updateView(){
        render(this.template,this.overlayPopup,{eventContext:this});
    }

    destroy(){
        this.container.removeChild(this.overlayPopup);
        delete this;
    }

}