import {render} from "lit-html";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {TemplateResetPasswordSideView} from "../../../templates/template-items/side-view/TemplateResetPasswordSideView";
import {FormHelpers} from "../../util/FormHelpers";
import {USER} from "../../core/User";
import {Popup} from "../Popup";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";


export class SideViewResetPassword extends SideViewBaseClass{
    constructor(systemResetPasswordData) {
        super("forgot-password",SideViewBaseClass.TYPE.NORMAL);
        this.systemResetPasswordData = systemResetPasswordData
        this.loading=false;
    }
    _init() {
        console.log(this.systemResetPasswordData)
        super._init();
    }

    renderViewContent() {
        console.log("rendering resetPassowrd");
        render(TemplateResetPasswordSideView(this),this.element,{eventContext:this});
        FormHelpers.initializeFormComponents(this.element);
    }

    onSubmitResetPassword(e){
        e.preventDefault();
        let inputData=FormHelpers.getFormDataAsObject(e.target);
        let controller = this
        this.loading=true;
        render(TemplateResetPasswordSideView(this),this.element,{eventContext:this});
        if(inputData.newPassword != inputData.confirmNewPassword){
            new Popup(APP_STRINGS.ERROR,APP_STRINGS.getStringById("2221005"),[APP_STRINGS.OK])
            render(TemplateResetPasswordSideView(this),this.element,{eventContext:this});
            return
        }
        USER.sessionKey = controller.systemResetPasswordData.sessionKey
        USER.changePassword(controller.systemResetPasswordData.UserPassword, inputData.newPassword)
        .then(data=>{
            if(data.error){
                controller.loading=false;
                render(TemplateResetPasswordSideView(controller),controller.element,{eventContext:controller});
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK])
            }else{
                USER.login(window.resetPasswordData.emailAddress, inputData.newPassword)
                .then(loginData=>{
                    if(loginData.error){
                        new Popup("Login error", `There was a login error, please try again. Alert code  ${loginData.error.errorID}`, ['OK'])
                    }else{
                        controller.loading=false;
                        render(TemplateResetPasswordSideView(controller),controller.element,{eventContext:controller});
                        new SlideOverViewStatus(
                            controller.element,
                            SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                            APP_STRINGS.getWPMLString('SideViewResetPassword-SuccessView-Title'),
                            APP_STRINGS.getWPMLString('SideViewResetPassword-SuccessView-Message'),
                            [APP_STRINGS.getWPMLString('SideViewResetPassword-SuccessView-ButtonLabel-Continue')],
                            (btnId)=>{
                                SIDE_VIEW_MANAGER.closeAnySideview();
                        });
                    }

                })
            }

        });
    }
}