import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
export const TemplateFTShoutOut = el => html`
    <div class="capp-ft-popup-overlay">
      <div class="shoutout-container hex-container">
        <div class="close-img-container ft-close-button">
          <img @click="${el.close}" class="close-img" class="ft-close-img" src="${CDN_ASSETS_URL}/close.svg">
        </div>
        <h2>${el.data.Title}</h2>
        <pre>
        ${unsafeHTML(el.data.Message)}
        </pre>
      </div>
    </div>
  `;