import {CAPP_API} from "./CAPPApi";
import {EventEmitter} from "events";

class AppStrings extends EventEmitter{
    constructor() {
        super();
        console.log("APP strings started");
        this._stringsMap=null;
        this.wpmlStrings=[];
    }

    _init(){
        //todo discuss updating strings procedure
        let appStringsData=window.sessionStorage.getItem('appStrings');
        if(appStringsData){
            this._createStringsMap(JSON.parse(appStringsData));
        }else{
            CAPP_API.loadApplicationStrings().then(data=>{
                let stringsData=JSON.parse(atob(data.data));
                console.log(data,stringsData);

                window.sessionStorage.setItem('appStrings', JSON.stringify(stringsData));
                this._createStringsMap(stringsData);
                console.log("APP strings",this._stringsMap);
            })
        }

        let wpmlStringsData=window.sessionStorage.getItem('wpmlStrings');
        if(wpmlStringsData){
            this.wpmlStrings=JSON.parse(wpmlStringsData);
            this.emit("WPML_Ready");
        }else{
            CAPP_API.loadWPMLStrings().then(data=>{
                let stringsData=JSON.parse(atob(data.data));
                console.log("WPML",data,stringsData);
                window.sessionStorage.setItem('wpmlStrings', JSON.stringify(stringsData));
                this.wpmlStrings=stringsData;
                this.emit("WPML_Ready");
            }).catch (e=>{
                console.log("Error loading WPML strings");
                this.emit("WPML_Ready");
            });
        }
    }

    _createStringsMap(data){
        this._stringsMap={};
        data.forEach(item=>{this._stringsMap[item.code]=item;});
    }

    getStringById(id){
        if(this._stringsMap[id]){
           return this._stringsMap[id].message;
        }else{
            return "Undefined string "+id;
        }
    }

    getTitleById(id){
        if(this._stringsMap[id]){
           return this._stringsMap[id].cappTitle;
        }else{
            return "Undefined string "+id;
        }
    }

    getWPMLString(id){
        if(this.wpmlStrings[id]){
           return this.wpmlStrings[id];
        }else{
            return `WPML-ID--${id}`;
        }
    }

    get OK(){
        return this.getStringById("2220001");
    }

    get CANCEL(){
        return this.getStringById("2220002");
    }

    get ERROR(){
        return this.getStringById("2220003");
    }

    get WARNING(){
        return this.getStringById("2220004");
    }

    get SAVE(){
        return this.getStringById("2220005");
    }

    get DISCARD(){
        return this.getStringById("2220006");
    }

    get YES(){
        return this.getStringById("2220007");
    }
    get NO(){
        return this.getStringById("2220008");
    }

}

export const APP_STRINGS=new AppStrings();