import {render} from "lit-html";
import {USER} from "../core/User";
import {Helpers} from "../util/Helpers";
import {TemplatePopup} from "../../templates/template-items/TemplatePopup";
import {TemplateVideoModal} from "../../../../capp-V3/src/templates/template-items/TemplateVideoModal";
import {Popup} from "../../../../capp-V3/src/js/ui/Popup";
import {FormHelpers} from "../util/FormHelpers";

const BASE_URL=window.location.origin;
const LANGUAGE=Helpers.getCookie('_icl_current_language');

export class VideoModal extends Popup{
    constructor(url) {
        super();
        this.success=false;
        this.error=null;
        this.url = url;
    }

    _init(popupDoneCallback) {
        super._init(popupDoneCallback);
        FormHelpers.initializeFormComponents(this.element);
        this.setupEventListeners();

    }

    setupEventListeners() {
        var video = document.getElementById('video-player');
        var me = this;

        video.onended = function(e) {
            me.onCloseButtonClick();
        };
    }

    get template(){
        return TemplateVideoModal;
    }

    _updateView(){
        render(this.template(this),this.element,{eventContext:this});
    }
}

