import {SideViewBaseClass} from "../SideViewBaseClass";
import {render} from "lit-html";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateMainMenuSideView} from "../../../templates/template-items/side-view/TemplateMainMenuSideView";
import {SideViewUser} from "./SideViewUser";
import {SideViewBank} from "./SideViewBank";
import {SideViewMenu} from "../SideViewMenu";
import {SideViewForgotPassword} from "./SideViewForgotPassword";
import {SideViewRegister} from "./SideViewRegister";
import {SideViewLogin} from "./SideViewLogin";
import {SearchView} from "../SearchView";
import {SideViewRegisterShort} from "./SideViewRegisterShort";

export class SideViewMainMenu extends SideViewBaseClass{
    constructor() {
        super("menu",SideViewBaseClass.TYPE.NORMAL,null,SideViewMainMenu.ALIGNMENT);
    }

    renderViewContent() {
        console.log("rendering login");
        render(TemplateMainMenuSideView(this),this.element,{eventContext:this});
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "deposit":
                new SideViewBank(SideViewMenu.ITEM_TYPES.DEPOSIT);
                break;
            case "withdraw":
                new SideViewBank(SideViewMenu.ITEM_TYPES.WITHDRAW);
                break;
            case "history":
                new SideViewBank(SideViewMenu.ITEM_TYPES.HISTORY);
                break;
            case "bonus":
                new SideViewBank(SideViewMenu.ITEM_TYPES.BONUSES);
                break;
            case "profile":
                new SideViewUser(SideViewMenu.ITEM_TYPES.PROFILE);
                break;
            case "preferences":
                new SideViewUser(SideViewMenu.ITEM_TYPES.PREFERENCES);
                break;
            case "verification":
                new SideViewUser(SideViewMenu.ITEM_TYPES.VERIFICATION);
                break;
            case "responsible gambling":
                new SideViewUser(SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING);
                break;
            case "logout":
                USER.logout();
                break;
            case "login":
                new SideViewLogin();
                break;
            case "signUp":
                this.launchRegistration();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "crypto-payments":
                try {
                    window.location.href=document.querySelector("input[name='crypto-payments-link']").value;
                }catch(e){
                    console.log("No Crypto Payments link found on page")
                }
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            case "all-casino-games":
                try {
                    window.location.href=document.querySelector("input[name='game-category-link_all-casino-games']").value;
                }catch(e){
                    console.log("No all casino games link found on page")
                }
                break;
            case "vip":
                try {
                    window.location.href=document.querySelector("input[name='vip-program-link']").value;
                }catch(e){
                    console.log("No vip program link found on page")
                }
                break;
            case "promotions":
                try {
                    window.location.href=document.querySelector("input[name='promotions-link']").value;
                }catch(e){
                    console.log("No promotions link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }

    onForgottenPasswordClick(e){
        e.preventDefault();
        console.log("Open forgotten password view");
        new SideViewForgotPassword();
    }

    launchRegistration(){
        new SideViewRegister();
    }
}

SideViewMainMenu.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;