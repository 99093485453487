import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
export const TemplateRestrictedCountryPopup = (el) => html`
    <div class="popup">
        <div class="header"><h4>${APP_STRINGS.getWPMLString('CountryRestrictAlert-title')}</h4>
        <div class="content">
            ${el.success ? html`
                <p style="font-size: 1em; padding-top: 1em;">${APP_STRINGS.getWPMLString('CountryRestrictAlert-SuccessMessage-text')}</p>
            `:html`
                <img style="margin-top: 1.5em;" src="${CDN_ASSETS_URL}/warning.svg">
                <p style="font-size: 1em;padding-top: 1em;padding-bottom: 2em;">${APP_STRINGS.getWPMLString('CountryRestrictAlert-message-text')}</p>
                <div class="form-element-2">
                    <label class="mdc-text-field mdc-email-address">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input email-address" type="text" aria-labelledby="my-label-id" name="emailAddress" spellcheck="false">
                        <span class="mdc-floating-label" id="my-label-id">
                        Email
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                </div>
                ${el.error?html`
                    <p style="font-size: 1em;padding-bottom: 1em;">
                        ${el.error}
                    </p>
                `:''}
                
            `}
            
        </div>
        ${el.success?'':html`
            <div class="popup-controls">
                <button data-button-id="0" @click=${el.onButtonClick} class="primary-button">${APP_STRINGS.getWPMLString('CountryRestrictAlert-SubmitButton-label')}</button>
            </div>
        `}
        

    </div>
    
`;