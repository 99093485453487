import {render} from "lit-html";

import {TemplateGameSection, TemplateDisplayFilterSection,} from "@/templates/template-items/sections/TemplateGameSection";
import {USER} from "../../core/User";
import {GameSearch} from "../../core/GameSearch";
import {Helpers} from "../../util/Helpers";
import {APP_STRINGS} from "../../core/AppStrings";
import {LazyImageLoader} from "../../core/LazyImageLoader";

export class GameFiltering {
    constructor() {
        this.selectedFilter = {
            gameTypes: [],
            winLines: [],
            numOfWays: [],
            features: [],
            gameMakers: [],
            searchString: '',
        }
        this.selectedGameCategory = $('.active.btn-game-category').children('.category-name').val()
        this.selectedSorting = {
            id : (Helpers.getDeviceType() == 'desktop') ? 'radio-f1' : 'radio-mobile-f1',
            value : 'MostPopular', //default sorting
            className: 'mdc-sorting'
        }
        this.gameLimit = '' //defualt select limit
        this.selectedPage = 0
        this.selectedGameTheme = ''
        this.selectedGameMaker = ''
        this.selectedDisplayFilter = [];
        this.gameSearch=new GameSearch();
        this.resultGameList = null;
        this.resultGameCount = 0;
        this.loading = false;
        
        this._init();

    }

    _init(){
        this.setupEventListener();

        if(Helpers.getDeviceType() == 'desktop'){
            setTimeout(()=>{
                this.collectInitialFilters();
            })
        }


    }    

    setupEventListener(){
        let gameType = null
        let winLines = null
        let numOfWays = null
        let features = null
        let sorting = null
        let gameMaker = null
        let deviceType = Helpers.getDeviceType()

        let controller = this
        $('.mdc-gameType').each(function(index){
            gameType = $(this)[0].MDCCheckbox

            gameType.listen('change', (e) => {
                var gameType = e.currentTarget.MDCCheckbox
                var description = $('label[for='+gameType.nativeControl_.id+']').text()
                if(gameType.checked){
                    controller.selectedFilter.gameTypes.push(gameType.value)

                    if(!controller.selectedDisplayFilter[gameType.nativeControl_.id]){
                        controller.selectedDisplayFilter[gameType.nativeControl_.id] = []
                    }
                    controller.selectedDisplayFilter[gameType.nativeControl_.id]['input'] = gameType
                    controller.selectedDisplayFilter[gameType.nativeControl_.id]['type'] = 'gameTypes'
                    controller.selectedDisplayFilter[gameType.nativeControl_.id]['description'] = description
                }else{
                    controller.selectedFilter.gameTypes.splice(controller.selectedFilter.gameTypes.indexOf(gameType.value), 1); 
                    delete controller.selectedDisplayFilter[gameType.nativeControl_.id]
                }
                controller.triggerFilterAction()

            })
        });

        $('body').on('change','.mdc-gameMakers', function(e){
            gameMaker = this.MDCCheckbox

            var description = $('label[for='+gameMaker.nativeControl_.id+']').text()
            if(gameMaker.checked){
                controller.selectedFilter.gameMakers.push(gameMaker.value)

                if(!controller.selectedDisplayFilter[gameMaker.nativeControl_.id]){
                    controller.selectedDisplayFilter[gameMaker.nativeControl_.id] = []
                }
                controller.selectedDisplayFilter[gameMaker.nativeControl_.id]['input'] = gameMaker
                controller.selectedDisplayFilter[gameMaker.nativeControl_.id]['type'] = 'gameMakers'
                controller.selectedDisplayFilter[gameMaker.nativeControl_.id]['className'] = 'gameMakers'
                controller.selectedDisplayFilter[gameMaker.nativeControl_.id]['description'] = description
            }else{
                controller.selectedFilter.gameMakers.splice(controller.selectedFilter.gameMakers.indexOf(gameMaker.value), 1); 
                delete controller.selectedDisplayFilter[gameMaker.nativeControl_.id]
            }

            controller.insertFilterText(this, 'gameMakers')

            if(deviceType == 'desktop'){
                controller.triggerFilterAction()
            }

        })

        $('body').on('change','.mdc-win-lines', function(e){
            winLines = this.MDCCheckbox

            var description = $('label[for='+winLines.nativeControl_.id+']').text()
            if(winLines.checked){
                controller.selectedFilter.winLines.push(winLines.value)
                if(!controller.selectedDisplayFilter[winLines.nativeControl_.id]){
                    controller.selectedDisplayFilter[winLines.nativeControl_.id] = []
                }
                controller.selectedDisplayFilter[winLines.nativeControl_.id]['input'] = winLines
                controller.selectedDisplayFilter[winLines.nativeControl_.id]['type'] = 'winLines'
                controller.selectedDisplayFilter[winLines.nativeControl_.id]['className'] = 'win-lines'
                controller.selectedDisplayFilter[winLines.nativeControl_.id]['description'] = description
            }else{
                controller.selectedFilter.winLines.splice(controller.selectedFilter.winLines.indexOf(winLines.value), 1); 
                delete controller.selectedDisplayFilter[winLines.nativeControl_.id]
            }

            controller.insertFilterText(this, 'winLines')

            if(deviceType == 'desktop'){
                controller.triggerFilterAction()
            }
        
        });

        $('body').on('change','.mdc-number-of-ways', function(e){
            numOfWays = this.MDCCheckbox

            var description = $('label[for='+numOfWays.nativeControl_.id+']').text()
            if(numOfWays.checked){
                controller.selectedFilter.numOfWays.push(numOfWays.value)
                if(!controller.selectedDisplayFilter[numOfWays.nativeControl_.id]){
                    controller.selectedDisplayFilter[numOfWays.nativeControl_.id] = []
                }
                controller.selectedDisplayFilter[numOfWays.nativeControl_.id]['input'] = numOfWays
                controller.selectedDisplayFilter[numOfWays.nativeControl_.id]['type'] = 'numOfWays'
                controller.selectedDisplayFilter[numOfWays.nativeControl_.id]['className'] = 'number-of-ways'
                controller.selectedDisplayFilter[numOfWays.nativeControl_.id]['description'] = description
            }else{
                controller.selectedFilter.numOfWays.splice(controller.selectedFilter.numOfWays.indexOf(numOfWays.value), 1); 
                delete controller.selectedDisplayFilter[numOfWays.nativeControl_.id]
            }

            controller.insertFilterText(this,'numOfWays')

            if(deviceType == 'desktop'){
                controller.triggerFilterAction()
            }
        });
        $('body').on('change','.mdc-game-features', function(e){
            features = this.MDCCheckbox
            var description = $('label[for='+features.nativeControl_.id+']').text()
            if(features.checked){
                controller.selectedFilter.features.push(features.value)
                if(!controller.selectedDisplayFilter[features.nativeControl_.id]){
                    controller.selectedDisplayFilter[features.nativeControl_.id] = []
                }
                controller.selectedDisplayFilter[features.nativeControl_.id]['input'] = features
                controller.selectedDisplayFilter[features.nativeControl_.id]['type'] = 'features'
                controller.selectedDisplayFilter[features.nativeControl_.id]['className'] = 'game-features'
                controller.selectedDisplayFilter[features.nativeControl_.id]['description'] = description
            }else{
                controller.selectedFilter.features.splice(controller.selectedFilter.features.indexOf(features.value), 1); 
                delete controller.selectedDisplayFilter[features.nativeControl_.id]
            }

            controller.insertFilterText(this,'features')

            if(deviceType == 'desktop'){
                controller.triggerFilterAction()
            }
        });

        if($('.mdc-sorting-text').length > 0){
            $('.mdc-sorting-text')[0].MDCTextField.value = 'Most Popular' // default sorting
        }

        //default showing most popular in sorting 
        $('.mdc-sorting').parents('.options-list').siblings('.mdc-text-field')[0].MDCTextField.value = 'Most Popular'
        $('body').on('change','.mdc-sorting', function(e){
            sorting = this.MDCRadio

            controller.selectedSorting['value'] = window.gamesSort = sorting.value
            controller.selectedSorting['id'] = sorting.nativeControl_.id
            controller.selectedSorting['className'] = 'mdc-sorting'

            if($(this).parents('.options-list').siblings('.mdc-text-field').length > 0){
                var displayName = ''
                if(sorting.value == 'MostPopular'){
                    displayName = APP_STRINGS.getWPMLString('GameFilters-SortControl-ItemLabel-MostPopular');
                }else if(sorting.value == 'ASC'){
                    displayName = APP_STRINGS.getWPMLString('GameFilters-SortControl-ItemLabel-AtoZ');
                }else{
                    displayName = APP_STRINGS.getWPMLString('GameFilters-SortControl-ItemLabel-ZtoA')
                }
                $(this).parents('.options-list').siblings('.mdc-text-field')[0].MDCTextField.value = displayName
            }

            if(deviceType == 'desktop'){
                controller.triggerFilterAction()
            }
        })

        $('.btn-game-category').on('click', function(e){
            // e.preventDefault()
            // controller.selectedGameCategory = $(this).children('.category-name').val()
            // $('.btn-game-category').removeClass('active')
            // $(this).addClass('active')
            //tentatively remove
            // controller.triggerFilterAction()
        })

        $('.filters-menu-search-input').on('keyup', function(e) {
            if (e.key !== 'Enter' && e.keyCode !== 13) {
                return;
            }

            // do not run the search again if the current search string is the same as the last search string
            if (window.searchString === e.currentTarget.value) {
                return;
            }

            controller.selectedFilter.searchString = window.searchString = e.currentTarget.value;
            controller.triggerFilterAction();
        });

        $('.filters-menu-search-input').on('blur', function(e) {
            // do not run the search again if the current search string is the same as the last search string
            if (window.searchString === e.currentTarget.value) {
                return;
            }

            controller.selectedFilter.searchString = window.searchString = e.currentTarget.value;
            controller.triggerFilterAction();
        });
    }

    insertFilterText(input, filterType, useRoot = false){
        let selectedInput = (useRoot) ? input.root_ : input

        if($(selectedInput).parents('.options-list').siblings('.mdc-filter-label').length > 0){
            if(this.selectedFilter[filterType].length > 0){
                $(selectedInput).parents('.options-list').siblings('.mdc-filter-label')[0].MDCTextField.value = this.selectedFilter[filterType].length+' selected'
            }else{
                $(selectedInput).parents('.options-list').siblings('.mdc-filter-label')[0].MDCTextField.value = ''
            }
        }
    }

    onClickRemoveFilter(e){
        e.preventDefault()
        var displayFilterKey = e.currentTarget.dataset.filter
        if(displayFilterKey == 'all'){
            this.selectedDisplayFilter = [];
            this.selectedFilter = {
                gameTypes: [],
                winLines: [],
                numOfWays: [],
                features: [],
                gameMakers: [],
                searchString: ''
            }
            $('.mdc-checkbox').each(function(index){
                var filterCheckBox = $(this)[0].MDCCheckbox
                filterCheckBox.checked = false;
            })
            $('.mdc-filter-label').each(function(index){
                this.MDCTextField.value = ''
            })
            $('.filters-menu-search-input').each(function() {
                this.value = '';
            });
            $('game-maker-label .mdc-floating-label--float-above').removeClass('mdc-floating-label--float-above'); // to deselect filters-menu-search-input and reset the label

            // render(TemplateGameSection(this),$('#game-filter-result')[0],{eventContext:this});
        }else{
            var type = this.selectedDisplayFilter[displayFilterKey]['type']
            var input = this.selectedDisplayFilter[displayFilterKey]['input']

            input.checked = false
            
            this.selectedFilter[type].splice(this.selectedFilter[type].indexOf(input.value), 1); 
            delete this.selectedDisplayFilter[displayFilterKey]
            this.insertFilterText(input, type, true)
        }
        this.triggerFilterAction()
    }

    triggerFilterAction(){
        console.log('selected filters',this.selectedDisplayFilter,this.selectedSorting);
        var gameItemsContainer = document.querySelector('.default-game-section .game-items-container');
        let controller = this;
        //to do convert this.selectedFilter to pokie, line, feature filter to pass in
        if(Object.keys(this.selectedDisplayFilter).length === 0 && 
                this.selectedSorting.value === 'MostPopular' &&
                this.selectedFilter.searchString === '') {
            this.resultGameList = null;
            this.resultGameCount = 0;
            $('.default-game-section').show();
            render(TemplateDisplayFilterSection(this),$('.filter-info')[0],{eventContext:this});
            render(TemplateGameSection(this),$('#game-filter-result')[0],{eventContext:this});
            controller.scrollGamesListToTop();
            return;
        }

        $('.default-game-section').hide()
        $('.filter-preloader').show();
        this.loading = true;
        render(TemplateGameSection(this),$('#game-filter-result')[0],{eventContext:this});
        $('#game-filter-result').show();
        let pokieFilters = '';
        let lineFilters = '';
        let featureFilters = '';
        let gameMakersFilter = '';
        if (Object.keys(this.selectedDisplayFilter).length > 0 || this.selectedFilter.searchString !== '') {
            render(TemplateDisplayFilterSection(this),$('.filter-info')[0],{eventContext:this});
        }

        if(this.selectedFilter.gameTypes.length > 0){
            pokieFilters = this.selectedFilter.gameTypes.join(',')
        }

        if(this.selectedFilter.numOfWays.length > 0){
            lineFilters = this.selectedFilter.numOfWays.join(',')
        }

        if(this.selectedFilter.winLines.length > 0){
            var separator = (lineFilters) ? ',' : ''
            lineFilters += separator+this.selectedFilter.winLines.join(',')
        }

        if(this.selectedFilter.features.length > 0){
            featureFilters = this.selectedFilter.features.join(',')
        }

        if(this.selectedFilter.gameMakers.length > 0){
            gameMakersFilter = this.selectedFilter.gameMakers.join(',')
        }

        if(Object.keys(this.selectedDisplayFilter).length <= 0 && gameItemsContainer && gameItemsContainer.dataset.pageSize){
            this.gameLimit = gameItemsContainer.dataset.pageSize;
            window.disableProgressiveGamesLoad = false;
            window.nonMostPopGamesCurrentPage = 0;
        }
        else{
            this.gameLimit = '';
            window.disableProgressiveGamesLoad = true;
        }

        this.gameSearch.getGameDetailLite(this.selectedFilter.searchString, this.selectedGameCategory, gameMakersFilter, this.selectedGameTheme, pokieFilters, lineFilters, featureFilters, this.selectedSorting.value, this.gameLimit, this.selectedPage).then(data=>{
            controller.loading = false;
            controller.resultGameList = data.games
            controller.resultGameCount = data.gameCount
            render(TemplateGameSection(controller),$('#game-filter-result')[0],{eventContext:controller});
            LazyImageLoader.updateLazyImagesList();
            $('.filter-preloader').hide();
            controller.scrollGamesListToTop();
        });
        //todo call getGameLite api
    }

    collectInitialFilters(){
        let gameMakerControls=document.querySelectorAll('.mdc-gameMakers');
        let winLines=document.querySelectorAll('.mdc-win-lines');
        let numOfWays=document.querySelectorAll('.mdc-number-of-ways');
        let gameListNeedsUpdate=false;


        gameMakerControls.forEach(item=>{
            let chkBox=item.MDCCheckbox;
            if(chkBox.checked){
                this.selectedFilter.gameMakers.push(chkBox.value);

                let description = $('label[for='+chkBox.nativeControl_.id+']').text()
                this.selectedDisplayFilter[chkBox.nativeControl_.id] = []
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['input'] = chkBox;
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['type'] = 'gameMakers'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['className'] = 'gameMakers'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['description'] = description

                gameListNeedsUpdate=true;
            }
        });
        let gameMakersLabel= document.querySelector('.tf5').MDCTextField;
        gameMakersLabel.value=gameMakersLabel.value;

        winLines.forEach(item=>{
            let chkBox=item.MDCCheckbox;
            if(chkBox.checked){
                this.selectedFilter.winLines.push(chkBox.value);

                let description = $('label[for='+chkBox.nativeControl_.id+']').text()
                this.selectedDisplayFilter[chkBox.nativeControl_.id] = []
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['input'] = chkBox
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['type'] = 'winLines'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['className'] = 'win-lines'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['description'] = description

                gameListNeedsUpdate=true;
            }
        })

        numOfWays.forEach(item=>{
            let chkBox=item.MDCCheckbox;
            if(chkBox.checked){
                this.selectedFilter.numOfWays.push(chkBox.value);

                let description = $('label[for='+chkBox.nativeControl_.id+']').text()
                this.selectedDisplayFilter[chkBox.nativeControl_.id] = []
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['input'] = chkBox
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['type'] = 'numOfWays'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['className'] = 'number-of-ways'
                this.selectedDisplayFilter[chkBox.nativeControl_.id]['description'] = description

                gameListNeedsUpdate=true;
            }
        })

        if(gameListNeedsUpdate){
            this.triggerFilterAction();
        }
    }

    scrollGamesListToTop(){
        let contentContainer=document.querySelector("#content-container");

        if(USER.isLoggedIn){
            var heroContainer=document.querySelector(".hero-section.logged-in");
        }
        else{
            var heroContainer=document.querySelector(".hero-section.logged-out");
        }

        if(contentContainer==null || heroContainer==null){return;}
        contentContainer.scrollTo({top: heroContainer.offsetHeight, left: 0, behavior: 'smooth'});
        console.log('scrollGamesListToTop');
        console.log(heroContainer.offsetHeight);
    }

}