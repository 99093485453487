import {html} from 'lit-html';
import {BANK} from "../../../js/core/Bank";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateWithdrawalContentView = (el) => html`
<div id="withdrawal">
    <div class="content">
    <preloader-element class="primary" style="width: 100%; height: 5em; display: ${isDisplayPreloader(el) ? 'flex' : 'none'};" ></preloader-element>
        <form class="withdrawal-container" @submit=${el.onFormSubmit} style="display: ${isDisplayPreloader(el) ? 'none' : 'block'  };">
            <div class="title-container">
                <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AmountSection-Title')}</span>
                <div class="sub-title"><span class="sub-title-label Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AmountSection-WithdrawableAmount-Label')}</span><span
                        class="Paragraph-Bold">${el.balance}</span></div>
            </div>
            <section class="amount-container">
                <div class="form-element-2">
                    <label class="mdc-text-field mdc-text-field--with-leading-icon" id="withdrawalAmountMDC">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id">
                        <i class="material-icons mdc-text-field__icon mdc-text-field__icon--leading icon-${el.currencyISO}"></i>
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AmountSection-AmountInput-Label')}
                        </span>
                        <div class="mdc-line-ripple"></div>
                    </label>
                </div>
                <div class="favourite-amount">
                    <ul>
                        ${el.presetValues.map((presetValue,index)=>
                            html`<li @click=${el.onPresetClick} data-preset-id="${index}" class="${el.selectedPreset==index ? `selected` : '' }"><span>${el.currencySymbol}${presetValue}</span></li>`
                        )}
                    </ul>
                </div>
            </section>
            ${el.PIQWithdrawals==true?'':html`
            <div id="dynamicWithdrawalLimitText" class="withdrawal-limit-container"></div>

                <div class="title-container">
                    <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-Title')}</span>
                </div>
                <section class="radio-items-section deposit-methods-section">
                    ${el.withdrawalData ? getWithdrawalMethodsItems(el) : ''}
                </section>
            `}
            <!--
            <div class="title-container">
                <span class="title Heading-4">Withdrawal</span>
            </div>
            -->
            <section id="payment-form-container" class="payment-section payment-method-form-holder"></section>
            ${el.withdrawalForm ? html`` : html`
                <section class="payment-section">
                    <div class="payment-field ">
                        <div class="form-element-2">
                            <button type="button" class="primary-button ${el.processingWithdrawal ? 'loading' : ''}" @click=${el.onWithdrawButtonClick} ?disabled=${el.BANK.withdrawalSettings.withdrawalAmount==0 || el.BANK.withdrawalSettings.selectedMethod==null}>
                                <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-ButtonLabel-Withdraw')}</span>
                                <preloader-element></preloader-element>
                            </button>
                        </div>
                    </div>
                </section>
            `}

            
            ${el.loadingPendingWithdrawalsData || el.pendingWithdrawals.length>0 ? html`
                <div class="title-container pw-title">
                    <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-Title')}</span>
                </div>
            ` : ''}
            <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingPendingWithdrawalsData ? 'flex' : 'none'};" ></preloader-element>
            ${el.pendingWithdrawals && el.pendingWithdrawals.length>0 ? html`
                <section class="pending-withdrawal-container">
                <table>
                    <thead>
                    <tr>
                        <th>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-TableColumnLabel-DateAndTime')}</th>
                        <th>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-TableColumnLabel-Amount')}</th>
                        <th>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-TableColumnLabel-PaymentMethod')}</th>
                        <th>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-TableColumnLabel-Action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    ${el.pendingWithdrawals.map(item=>html`
                        <tr>
                        <td>${item.dateDisplay}</td>
                        <td>${item.AmountDisplay}</td>
                        <td>${item.AccountReference}</td>
                        <td>
                            ${item.cancelingWithdrawal==true ? html`
                                <preloader-element class=" primary"></preloader-element>
                            `:html`
                                <a class="Text-Link" href="#" data-method="${item.PaymentMethodName}" data-transaction-key="${item.UniqueTransactionID}" @click=${el.onCancelPendingWithdrawalClick}>
                                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-ButtonLabel-CancelPendingWithdrawal')}
                                </a>
                            `}
                            
                        </td>
                    </tr>
                    `)}
                    </tbody>
                </table>
            </section>
            ` : ''}
           
        </form>
    </div>
</div>        
`;

export const TemplateWithdrawalVerificationView = (el) => html`
<div id="withdrawal-verification">
    <div class="content">
        <div class="verification-container">
            <ol class="status-message-container">
                <li>
                    <span class="Heading-3">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotVerifiedSection-Title')}</span>
                </li>
                <li class="status-desc">
                    <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotVerifiedSection-InfoText-Part1')}</span>
                </li>
                <li class="status-desc">
                    <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotVerifiedSection-InfoText-Part2')}</span>
                </li>
                <li>
                    <button class="primary-button continue" @click=${el.onVerificationButtonClick}>
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotVerifiedSection-ButtonLabel-VerifyAccount')}
                    </button>
                </li>
            </ol>
        </div>
    </div>
</div>
`

export const TemplateWithdrawalAccountActivationView = (el) => html`
<div id="withdrawal-verification">
        <div class="content">
            <div class="verification-container">
                <ol class="status-message-container">
                    <li>
                        <span class="Heading-3">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotActivatedSection-Title')}</span>
                    </li>
                    <li class="status-desc">
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotActivatedSection-InfoText-Part1')}</span>
                    </li>
                    <li class="status-desc">
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotActivatedSection-InfoText-Part2')}</span>
                    </li>
                    <li>
                        <button class="primary-button ${el.verificationEmailLoading ? 'loading' : ''}" @click=${el.onAccountActivationEmailButtonClick}>
                            <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-AccountNotActivatedSection-ButtonLabel-ResendEmail')}</span>
                            <preloader-element></preloader-element>
                        </button>
                    </li>
                </ol>
            </div>
        </div>
    </div>
`

function isDisplayPreloader(el){
    let display = false;

    if(el.loadingWithdrawalData || el.loadingUserData){
        display = true;
    }
    return display;
}

function getWithdrawalMethodsItems(el){
    let withdrawalMethodTemplates=[];

    el.withdrawalData.withdrawalMethods.forEach(methodData=>{
        let selected=false;
        let isDisabled=false;
        if(el.BANK.withdrawalSettings.selectedMethod && methodData.PaymentMethodName==el.BANK.withdrawalSettings.selectedMethod.PaymentMethodName){
            selected=true;
        }
        if(methodData.limitStatus!=BANK.DEPOSIT_METHOD_LIMIT_STATUS.IN_RANGE){
            selected=false;
            isDisabled=true;
        }
        let pm=html`
        <div class="radio-box-2 deposit-method-item ${selected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}"  data-payment-method="${methodData.PaymentMethodName}" data-payment-account-id="${methodData.PaymentAccountID}" data-withdrawal-limit-text="${methodData.PlayerWithdrawalLimitText}" @click=${el.onWithdrawalMethodSelect}>
        <div class="inner">
            <label class="form-element">
                <input type="radio" name="payment-method" value="${methodData.PaymentMethodName}" ?checked=${selected} >
                <span class="Heading-5">${methodData.displayName}</span>
            </label>
            <div class="rb-content">
                <div class="method-info">
                    <div class="pm-img">
                        <img src="${methodData.image}">
                    </div>
                    <div class="limits-display">
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-PaymentMethodItem-FieldLabel-MinimumWithdrawal')}</span>
                        <span class="limit-value">${methodData.PlayerLowerLimitDisplay}</span>
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-PaymentMethodItem-FieldLabel-MaximumWithdrawal')}</span>
                        <span class="limit-value">${methodData.PlayerUpperLimitDisplay}</span>
                    </div>
                </div>
            </div>
        </div>    
            ${getLimitControl(el,methodData)}
        </div>
    `;
        withdrawalMethodTemplates.push(pm);
    })
    return withdrawalMethodTemplates;
}

function getLimitControl(el,methodData){
    switch (methodData.limitStatus) {
        case BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW:
            return html`<div class="limit-control"><span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-PaymentMethodItem-LimitsMessage-AmountTooLow')}</span>.
                    <a data-payment-method="${methodData.PaymentMethodName}" @click=${el.onLimitControlClick}>
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-PaymentMethodItem-ButtonLabel-SetMinimumAmount')}
                    </a>
                </div>`;
            break;
        case BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH:
            return html`<div class="limit-control"><span>Amount high for this method.</span>
                    <a data-payment-method="${methodData.PaymentMethodName}" @click=${el.onLimitControlClick}>
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-PaymentMethodItem-ButtonLabel-SetMaximumAmount')}
                    </a>
                 </div>`;
            break;
        default:
            return;
    }
}

export const TemplateWithdrawalIframePaymentSlideOver = (el) => html`
<div id="iframe-deposit-slide-over">
    <div class="view-heading">
                <div class="item-left">
                    <img @click=${el.onSlideOverPaymentIframeBackButtonClick}  class="back-img" src="${CDN_ASSETS_URL}/left.svg">
                        <span class="title">${el.paymentSlideoverTitle}</span>
                </div>
    </div>
    <div class="content">
        ${el.loadingIframeURL ?
            html`
            <preloader-element class="primary" style="width: 100%; height: 5em;"  ></preloader-element>
            `
            : html``
        }
        <iframe style="display: ${el.loadingIframeURL ? 'none':'block'}" @load=${el._onPaymentIframeLoaded} class="payment-iframe ${el.BANK.withdrawalSettings.selectedMethod==null?'':el.BANK.withdrawalSettings.selectedMethod.PaymentMethod}" frameBorder="0" src="${el.paymentIframeURL==null?'':el.paymentIframeURL}">
    </div>
</div>
        
`;


