import {APP_STRINGS} from '@/js/core/AppStrings'

export const Validations={
    /** @function validatePassword
     */
    validatePassword : function(value){
        var reg = /^([\s\S]{6,30})$/;
        var result={};
        if (reg.test(value)) {
            result.valid = true;
        }else{
            result.valid = false;
            result.message = APP_STRINGS.getStringById("465");
        }

        var requiredCharacters=["1","2","3","4","5","6","7","8","9","0","!","#","$","%","&"];
        var found=false;

        for(var i=0; i<requiredCharacters.length; i++){
            if(value.indexOf(requiredCharacters[i])>=0){
                found=true;
            }
        }

        if(found==false){
            result.valid = false;
            result.message = APP_STRINGS.getStringById("465");
        }


        return result;
    },
}