import {BankFormBase} from "./BankFormBase";
import {Template_Inpay_Withdrawal} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_Inpay_Withdrawal";
import {BANK} from "../../../../core/Bank";
import {Helpers} from "../../../../util/Helpers";
import {USER} from "../../../../core/User";

export class BankFormInpayWithdrawal extends BankFormBase{
    constructor(container, selectedMethod, existingAccounts,country, callback) {
        super(container, callback);
        this.country=country;
        this.existingAccounts=[];
        this.isBankClearingIdRequired = false;
        this.isSwiftCodeRequired = false;
        this.isBankNameRequired = false;
        this.banksList = [];
        this.postcode = USER.data.PostCode;
        this.address = USER.data.Address1;
        this.city = USER.data.City;

        if(selectedMethod.TransactionWithdrawOption){
            let accounts =[];
            selectedMethod.TransactionWithdrawOption.forEach(function (method) {

                if (selectedMethod.PaymentMethod === method.CappPaymentName) {
                    if (method.PaymentAttributes) {
                        let accountData = {
                            accountDisplay: method.AccountReference,
                            accountId: method.PaymentAccountID,
                            accountHolderName: ""
                        };

                        method.PaymentAttributes.PaymentGatewayMethodAttribute.forEach(function(attr) {
                            if (attr.AttrName === "accountName") {
                                accountData.accountHolderName = attr.AttrDefaultValue;
                            }

                            if (attr.AttrName === "accountNumber") {
                                accountData.accountNumber = attr.AttrDefaultValue;
                            }

                            if (attr.AttrName === "bankCode") {
                                accountData.bankCode = attr.AttrDefaultValue;
                            }

                            if (attr.AttrName === "bankClearingID") {
                                accountData.bankClearingID = attr.AttrDefaultValue;
                            }

                            if (attr.AttrName === "bankSwiftCode") {
                                accountData.bankSwiftCode = attr.AttrDefaultValue;
                            }
                        });

                        accounts.push(accountData);
                    }
                }

            });
            this.existingAccounts = accounts;
        }

        if(selectedMethod.AvailableCurrencyBankList && Object.keys(selectedMethod.AvailableCurrencyBankList).length > 0){
            let banks = [];
            Object.keys(selectedMethod.AvailableCurrencyBankList).forEach(function(val, index) {
                banks.push({bankCode: val, bankName: selectedMethod.AvailableCurrencyBankList[val]});
            });
            this.banksList = banks;
        }

        switch(USER.data.CurrencyISO) {
            case 'AUD':
                this.isBankClearingIdRequired = true;
                break;
            case 'CAD':
            case 'NZD':
                this.isBankClearingIdRequired = true;
                this.isSwiftCodeRequired = true;
                this.isBankNameRequired = true;
                break;
            case 'EUR':
                break;
            default:
                break;
        }

        console.log(this);
        this._init();
    }

    _getFormTemplate() {
        return Template_Inpay_Withdrawal;
    }


    _validateInput(input){
        if(input.root_.dataset.field=="accountNumber"){
            return this._validateCustomField();
        }
        else if(input.root_.dataset.field=="bankClearingID"){
            return this._validateCustomField();
        }
        else if(input.root_.dataset.field=="bankSwiftCode"){
            return this._validateCustomField();
        }
        else{
            return super._validateInput(input);
        }
    }

    _validateCustomField(){
        let valid=true;

        this.formInputs.forEach(input=>{
            if(input.root_.dataset.field=="accountNumber"){
                valid=false;

                switch(USER.data.CurrencyISO) {
                    case 'CAD':
                        if (input.value.length >= 7 && input.value.length <= 12) {
                            valid = true;
                        }
                        break;
                    case 'NZD':
                        if (input.value.length >= 9 && input.value.length <= 10) {
                            valid = true;
                        }
                        break;
                    case 'AUD':
                        if (input.value.length >= 5 && input.value.length <= 9) {
                            valid = true;
                        }
                        break;
                    case 'EUR':
                        if (input.value.match(/[A-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}/)) {
                            valid = true;
                        }
                        break;
                }
                this._setInputState(input,valid);
            }
            else if(input.root_.dataset.field=="bankSwiftCode"){
                valid=false;

                switch(USER.data.CurrencyISO) {
                    case 'CAD':
                    case 'NZD':
                        if (input.value.length === 8 || input.value.length === 11) {
                            valid = true;
                        }
                        break;
                }
                this._setInputState(input,valid);
            }
            else if(input.root_.dataset.field=="bankClearingID"){
                valid=false;
                switch(USER.data.CurrencyISO) {
                    case 'CAD':
                        if (input.value.match(/^\d{9,9}$/)) {
                            valid = true;
                        }
                        break;
                    case 'NZD':
                        if (input.value.match(/^\d{6,6}$/)) {
                            valid = true;
                        }
                        break;
                    case 'AUD':
                        if (input.value.match(/^\d{6}$/)) {
                            valid = true;
                        }
                        break;
                    case 'EUR':
                        valid = true;
                        break;

                }
                this._setInputState(input,valid);
            }
        })

        return valid;
    }

    _applyExistingAccount(input){
        if(input.value=="new-account"){
            this.formInputs.forEach(input=>{
                //todo we might need to rething this for other bank forms, perhaps create reset object in bank model
                if(input.root_.dataset.field!="" && input.root_.dataset.field!="existingAccount" && input.root_.dataset.field!="bankCountry"){
                    input.value="";
                    input.foundation_.adapter_.floatLabel(false)
                }

            })
        }else{
            let account=Helpers.findItemInArray(this.existingAccounts,"accountId", input.value);
            this.formInputs.forEach(input=>{
                console.log('autocomplete', input.root_.dataset.field, account);
                if(account[input.root_.dataset.field]){
                    input.value=account[input.root_.dataset.field];
                    input.foundation_.adapter_.floatLabel(true)
                }
            })
        }
    }


}