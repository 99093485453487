import {Helpers} from "../util/Helpers";
import {USER} from "./User";

export const InitLivechatController=(hideByDefault)=>{
    if((typeof window.lcLicenseKey) !== 'undefined' && window.lcLicenseKey > 0){
        initChat(hideByDefault);
        USER.on("onLoginStateChange",()=>{
            console.log(USER);
            initChat(hideByDefault);
        })
    }
}

export const SetLivechatDepositErrorCustomVariable=paymentType=>{
    if(LiveChatWidget){
        let vars = getSessionVaribles();
        vars.DepositFailed = paymentType;
        LiveChatWidget.call("set_session_variables", vars);  
    }
}

function initChat(hideByDefault){
    console.log('lcLicenseKey', window.lcLicenseKey);

    //Asynchronous Initialization
    if((typeof LiveChatWidget) === 'object'){
        LiveChatWidget.call("destroy");
        console.log('LiveChatWidget destroyed');
    }
    console.log(window.hideChatWindowOnLogout);
    console.log(window.lcLicenseKey);
    window.__lc = window.__lc || {};
    window.__lc.license = window.lcLicenseKey;
    window.__lc.asyncInit = true;

    if(USER.isLoggedIn){
        window.__lc.group = window.lcGroupIds.loggedin;
    }else{
        window.__lc.group = window.lcGroupIds.loggedout;
    }
    window.__lc.chat_between_groups = false;
    console.log(window.__lc.group);
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice)) 

    setConf(hideByDefault);
    LiveChatWidget.init();
}

function setConf(hideByDefault){
    console.log('setConf', hideByDefault);

    if(USER.isLoggedIn){
        setLoggedInMode();
        if(hideByDefault){
            LiveChatWidget.call("hide");
        }else{
            LiveChatWidget.call("minimize");
        }
    }else{
        setLoggedOutMode();
    }
}

function setLoggedOutMode(){
    if(LiveChatWidget){
        if(window.hideChatWindowOnLogout){
            LiveChatWidget.call("hide");
        }
        console.log('set_session_variables', {});
        LiveChatWidget.call("set_session_variables",{});
    }
}

function setLoggedInMode(){
    if(LiveChatWidget){
        console.log('set_session_variables', getSessionVaribles());
        LiveChatWidget.call("set_session_variables", getSessionVaribles());
        __lc.visitor={
            name: `${USER.data.FirstName} ${USER.data.LastName}`,
            mail: USER.data.EmailAddress
        }   
    }
}

function getSessionVaribles() {
    return {
        "First Name": USER.data.FirstName,
        "Email": USER.data.EmailAddress,
        //"Platform": window.clientInformation.platform,
        "Current Real Balance": USER.data.CurrencySymbol+Helpers.formatCurrencyCents(parseFloat(USER.data.CurrentRealBalance)),
        "CurrentBonusBalance": USER.data.CurrencySymbol+Helpers.formatCurrencyCents(parseFloat(USER.data.CurrentBonusBalance)),
        "LoyaltyLevelName": USER.data.VIPLevelName,
        "AccountActivated": USER.data.AccountActivated,
        "KycChecked": USER.data.IsKYCChecked
        //"PlayerProfileUrl", '',
    };
}

//window.__lc.params.unshift();