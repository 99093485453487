import {render} from "lit-html";
import validator from "validator/es";
import {SlideOverView} from "../../SlideOverView";
import {TemplateResponsibleGambling} from "@/templates/template-items/side-view/responsible-gambling/TemplateResponsibleGambling";
import {TemplateRGGeneralLimits} from "@/templates/template-items/side-view/responsible-gambling/TemplateRGGeneralLimits";
import {TemplateRGSelfExclude} from "@/templates/template-items/side-view/responsible-gambling/TemplateRGSelfExclude";
import {TemplateRGSessionLimits} from "@/templates/template-items/side-view/responsible-gambling/TemplateRGSessionLimits";
import {FormHelpers} from "../../../util/FormHelpers";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import { USER } from "@/js/core/User";
import {Popup} from "@/js/ui/Popup";
import {Helpers} from "@/js/util/Helpers";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";
import {BANK} from "../../../core/Bank";

export class SideViewSubResponsibleGambling extends SideViewSubBaseClass{
    constructor(container) {
        super(container);
        this.rgLimitData=null;
        this.depositLimitList = {};
        this.gameLimitList = {};
        this.intervalType = [
            {
                id : 0,
                intervalPropName: 'Transaction',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Transaction')
            },
            {
                id : 1,
                intervalPropName: 'Day',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Day')
            },
            {
                id : 2,
                intervalPropName: 'Week',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Week')
            },
            {
                id : 3,
                intervalPropName: 'Month',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Month')
            },
            {
                id : 4,
                intervalPropName: 'Year',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Year')
            },
            {
                id : 5,
                intervalPropName: 'Session',
                intervalDisplayName: APP_STRINGS.getWPMLString('SideViewUser-Limits-IntervalTypeLabes-Session')
            },
        ];
        this.limitTypeList = [
            'Deposit',
            'Loss',
            'Wager',
            'Session',
            'Self Exclude'
        ];

        this.limitTypeDisplayNamesList = [
            APP_STRINGS.getWPMLString('SideViewUser-Limits-LimitTypeLabes-Deposit'),
            APP_STRINGS.getWPMLString('SideViewUser-Limits-LimitTypeLabes-Loss'),
            APP_STRINGS.getWPMLString('SideViewUser-Limits-LimitTypeLabes-Wager'),
            APP_STRINGS.getWPMLString('SideViewUser-Limits-LimitTypeLabes-Session'),
            APP_STRINGS.getWPMLString('SideViewUser-Limits-LimitTypeLabes-Self Exclude')
        ];




        this.selectedIntervalTypeID = null
        this.selectedLimitTypeID = null
        this.selectedIntervalAmount = null;
        this.presetAmount = [
            '10',
            '25',
            '50',
            '70',
            '100'
        ];
        this.selectedPresetAmountID = null;
        this.presetSessionTime = [
            '10',
            '25',
            '30',
            '60',
            '120',
        ];
        this.selectedPresetSessionTime = null;
        this.selectedPresetSessionValue = null;
        this.updateLimitLoading = false;
    }

    _init() {        
        this.loadingRgLimitData=true;
        this.getAllRgLimitData();
        super._init();
    }

    renderView(){
        this.updateView();
        FormHelpers.initializeFormComponents(this.element);
        this._setupEventListeners();
    }

    updateView(){
        render(TemplateResponsibleGambling(this),this.element,{eventContext:this});
    }

    _setupEventListeners(){

    }

    _getMDCInputFields(limitType = null){
        let mdcInput = {}
        if(limitType == 'Self Exclude'){
            mdcInput = {
                excludeDay: document.querySelector(".mdc-exclusion-day").MDCSelect,
                lockCheck: document.querySelector(".mdc-lock-checkbox").MDCCheckbox,
            }
        }else{
            mdcInput = {
               limitAmount: document.querySelector(".mdc-limit-amount").MDCTextField,
           }
        }
        return mdcInput
    }

    onSlideOverBackButtonClick(){
        console.log("CLICK HANDLER CONTEXT",this);
        if(!this.updateLimitLoading){
            this.loadingRgLimitData=true;
            this.updateView();
            this.getAllRgLimitData();
            this.slideoverView.close();
        }
    }

    onRGItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "deposit-limit":
                this.selectedLimitTypeID = 0;
                this.selectedIntervalTypeID = 0;
                this.selectedIntervalAmount = this.getIntervalDepositLimitAmount(this.depositLimitList, this.selectedIntervalTypeID)
                this.slideoverView = new SlideOverView(this.container,TemplateRGGeneralLimits,this);
                break;
            case "loss-limit":
                this.selectedLimitTypeID = 1;
                this.selectedIntervalTypeID = 1;
                this.selectedIntervalAmount = this.getIntervalGameLimitAmount(this.gameLimitList, this.selectedLimitTypeID, this.selectedIntervalTypeID)
                this.slideoverView = new SlideOverView(this.container,TemplateRGGeneralLimits,this);
                break;
            case "wager-limit":
                this.selectedLimitTypeID = 2;
                this.selectedIntervalTypeID = 0;
                this.selectedIntervalAmount = this.getIntervalGameLimitAmount(this.gameLimitList, this.selectedLimitTypeID, this.selectedIntervalTypeID)
                this.slideoverView = new SlideOverView(this.container,TemplateRGGeneralLimits,this);
                break;
            case "session-limit":
                this.selectedLimitTypeID = 3;
                var time = this.getIntervalGameLimitAmount(this.gameLimitList, this.selectedLimitTypeID, null)
                if(time != null){
                    for (const [index, value] of Object.entries(this.presetSessionTime)) {
                        if(value == time){
                            this.selectedPresetSessionTime = index;
                        }
                    }
                }
                this.slideoverView = new SlideOverView(this.container, TemplateRGSessionLimits,this);
                break;
            case "self-exclude":
                this.selectedLimitTypeID = 4;
                this.slideoverView = new SlideOverView(this.container, TemplateRGSelfExclude,this);
                break;
            default:
                console.log(`Action not defined: ${action}`)
        }
        $('.slide-over').attr('id', 'responsible-gambling');
    }

    getAllRgLimitData(){
        return USER.getRGLimitData().then(data=>{
            if(data.depositLimitList.error || data.gameLimitList.error){
                this.loadingRgLimitData = false;
                this.updateView()
                new Popup(data.depositLimitList.error.errorTitle, data.gameLimitList.error.errorMessage, ['OK'])
                return
            }
            this.RgLimitData = data;
            this.loadingRgLimitData = false;
            if(Object.keys(this.RgLimitData.depositLimitList.TransactionLimits).length > 0){
                this.depositLimitList = this.reformatDepositLimitData(this.RgLimitData.depositLimitList)
            }
            if(Object.keys(this.RgLimitData.gameLimitList.GameLimits).length > 0){
                this.gameLimitList = this.reformatGameLimitData(this.RgLimitData.gameLimitList)
            }
            this.updateView()
        });
    }

    reformatDepositLimitData(depositLimitList){
        var depositLimit = depositLimitList.TransactionLimits.TransactionLimitTimeLine
        if(Object.keys(depositLimit).length > 0){
            const displayDepositLimitData = {};
            for (const [key, value] of Object.entries(depositLimit)) {
                displayDepositLimitData[key] = {
                    currencySymbol : this.userData.CurrencySymbol,
                    intervalType : value.IntervalType,
                    amount : Helpers.formatCurrencyCents(value.Current.Amount,true)
                }
            }
            return displayDepositLimitData
        }
    }

    reformatGameLimitData(gameLimitList){
        var gameLimit = gameLimitList.GameLimits.GameLimitTimeLine
        if(Object.keys(gameLimit).length > 0){
            const displayGameLimitData = {};
            var betLimitKey = 0;
            var lossLimitKey = 0;
            var timeLimitKey = 0;
            var betTypeKey = 0;
            for (const [key, value] of Object.entries(gameLimit)) {
                if(typeof displayGameLimitData[value.LimitType] == 'undefined'){
                    displayGameLimitData[value.LimitType] = {}
                }
                if(typeof displayGameLimitData[value.LimitType][key] == 'undefined'){
                    displayGameLimitData[value.LimitType][key] = {}
                }

                if(value.LimitType == 'BET_AMOUNT_LIMIT'){
                    betTypeKey = betLimitKey
                }else if(value.LimitType == 'LOSS_AMOUNT_LIMIT'){
                    betTypeKey = lossLimitKey
                }else if(value.LimitType == 'TIME_LIMIT'){
                    betTypeKey = timeLimitKey
                }

                var limitValue = (value.LimitType == 'TIME_LIMIT') ? parseInt(value.Current.LimitValue) : Helpers.formatCurrencyCents(value.Current.LimitValue,true)

                displayGameLimitData[value.LimitType][betTypeKey] = {
                    currencySymbol : this.userData.CurrencySymbol,
                    intervalType : value.IntervalType,
                    amount : limitValue
                }
                
                if(value.LimitType == 'BET_AMOUNT_LIMIT'){
                    betLimitKey += 1
                }else if(value.LimitType == 'LOSS_AMOUNT_LIMIT'){
                    lossLimitKey += 1
                }else if(value.LimitType == 'TIME_LIMIT'){
                    timeLimitKey += 1
                }
            

            }
            return displayGameLimitData
        }
    }

    onClickIntervalType(e){
        this.selectedIntervalTypeID = e.currentTarget.dataset.intervalId;
        if(this.selectedLimitTypeID == 0){
            this.selectedIntervalAmount = this.getIntervalDepositLimitAmount(this.depositLimitList, this.selectedIntervalTypeID)
        }else{
            this.selectedIntervalAmount = this.getIntervalGameLimitAmount(this.gameLimitList, this.selectedLimitTypeID, this.selectedIntervalTypeID)
        }
        this.slideoverView.update();
    }

    onClickPresetAmount(e){
        var type = e.currentTarget.dataset.type;
        if(type == 'general'){
            this.selectedPresetAmountID = e.currentTarget.dataset.presetId;
            var inputFields = this._getMDCInputFields()
            inputFields.limitAmount.value = this.presetAmount[this.selectedPresetAmountID]
        }else if(type == 'session'){
            this.selectedPresetSessionTime = e.currentTarget.dataset.presetId;
            this.selectedPresetSessionValue = e.currentTarget.dataset.value;
        }
        this.slideoverView.update();
    }

    getIntervalDepositLimitAmount(depositLimitData, intervalTypeID){
        var amount = null;
        if(Object.keys(depositLimitData).length > 0){
            for(const [index, data] of Object.entries(depositLimitData)){
                if(this.intervalType[intervalTypeID]['intervalPropName'] == data.intervalType){
                    amount = data.currencySymbol+''+data.amount
                    continue;
                }
            }
            
        }
        return amount
    }

    getIntervalGameLimitAmount(gameLimitData, gameType, intervalTypeID){
        var amount = null;
        var key = '';
        switch(gameType){
            case 1:
            case 2:
                key = (gameType == '1') ? 'LOSS_AMOUNT_LIMIT' : 'BET_AMOUNT_LIMIT'
                if(Object.keys(gameLimitData).length > 0){
                    if(Object.keys(gameLimitData[key]).length > 0){
                        for (const [index, value] of Object.entries(gameLimitData[key])) {
                            if(this.intervalType[intervalTypeID]['intervalPropName'] == value.intervalType){
                                amount = value.currencySymbol+''+value.amount
                                continue;
                            }
                        }
                    }
                }
            break;
            case 3:
                key = 'TIME_LIMIT'
                if(Object.keys(gameLimitData).length > 0){
                    if(Object.keys(gameLimitData[key]).length > 0){
                        if(typeof gameLimitData[key][0] != 'undefined'){
                            amount = gameLimitData[key][0]['amount'];
                        }
                    }
                }
            break;
        }
        return amount;
    }

    onSubmitLimitForm(e){
        e.preventDefault()
        var limitFormID = parseInt(e.currentTarget.dataset.limitType)
        let RGSideController = this;
        if($.inArray(limitFormID, [0,1,2]) !== -1){
            //deposit / loss / bet
            var intervalTypePropName = this.intervalType[this.selectedIntervalTypeID]['intervalPropName']
            var inputFields = this._getMDCInputFields()
            if(!validator.isNumeric(inputFields.limitAmount.value)){
                inputFields.limitAmount.useNativeValidation = false;
                inputFields.limitAmount.valid = false;
                inputFields.limitAmount.helperTextContent = APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-InputValidationError-AcceptOnlyNumbers');
                return;
            }else{
                inputFields.limitAmount.useNativeValidation = true;
                inputFields.limitAmount.valid = true;
                inputFields.limitAmount.helperTextContent = '';
            }
            var limitAmount = inputFields.limitAmount.value * 100;
        }else{
            if(limitFormID == 3){
                //session
                if(this.selectedPresetSessionValue == null || this.selectedPresetSessionValue < 10 || this.selectedPresetSessionValue > 2880){
                    new Popup(APP_STRINGS.getTitleById("2224071"),APP_STRINGS.getStringById("2224071"),[APP_STRINGS.OK])
                    return;
                }
            }else if (limitFormID == 4){
                var inputFields = this._getMDCInputFields(this.limitTypeList[limitFormID])
                if(!inputFields.lockCheck.checked){
                    new Popup(APP_STRINGS.getTitleById("2224017"),APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Form-WarningMessage-Text'),[APP_STRINGS.OK])
                    return
                }
            }
        }
        for (const [index, value] of Object.entries(this.limitTypeList)) {
            if(limitFormID == index){
                switch(value){
                    case 'Deposit':
                        new Popup(APP_STRINGS.getTitleById("2224063"),APP_STRINGS.getStringById("2224063"),[APP_STRINGS.YES, APP_STRINGS.NO], function(response) {
                            if(response == 0){
                                RGSideController.updateLimitLoading = true;
                                RGSideController.slideoverView.update();
                                USER.setDepositLimit(intervalTypePropName, limitAmount)
                                .then(data=>{
                                    if(data.error){
                                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                    }else{
                                        //update deposit limit list amount
                                        RGSideController.selectedIntervalAmount = RGSideController.userData.CurrencySymbol+''+Helpers.formatCurrencyCents(data.Current.Amount);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                        BANK.invalidateExistingDepositData();
                                        //redirect to successful page
                                        let msg=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-DepositLimitsSuccess-Text');
                                        let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-SuccessButtonLabel-Continue');
                                        new SlideOverViewStatus(RGSideController.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,msg, '',[btnLabel],(btnId)=>{
                                            console.log("btn id",btnId);
                                            // SIDE_VIEW_MANAGER.closeAnySideview();
                                            RGSideController.onSlideOverBackButtonClick()
                                        });
                                    }
                                });
                            }
                        })
                    break;
                    case 'Loss':
                        new Popup(APP_STRINGS.getTitleById("2224065"),APP_STRINGS.getStringById("2224065"),[APP_STRINGS.YES, APP_STRINGS.NO], function(response) {
                            if(response == 0){
                                RGSideController.updateLimitLoading = true;
                                RGSideController.slideoverView.update();
                                USER.setLossLimit(intervalTypePropName, limitAmount)
                                .then(data=>{
                                    if(data.error){
                                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                    }else{
                                        //update LOSS limit list amount
                                        RGSideController.selectedIntervalAmount = RGSideController.userData.CurrencySymbol+''+Helpers.formatCurrencyCents(data.Current.LimitValue);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                        //redirect to successful page
                                        let msg=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-LossLimitsSuccess-Text');
                                        let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-SuccessButtonLabel-Continue');

                                        new SlideOverViewStatus(RGSideController.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,msg, '',[btnLabel],(btnId)=>{
                                            console.log("btn id",btnId);
                                            // SIDE_VIEW_MANAGER.closeAnySideview();
                                            RGSideController.onSlideOverBackButtonClick()
                                        });
                                    }
                                });
                            }
                        })
                    break;
                    case 'Wager':
                        new Popup(APP_STRINGS.getTitleById("2224064"),APP_STRINGS.getStringById("2224064"),[APP_STRINGS.YES, APP_STRINGS.NO], function(response) {
                            if(response == 0){
                                RGSideController.updateLimitLoading = true;
                                RGSideController.slideoverView.update();
                                USER.setBetLimit(intervalTypePropName, limitAmount)
                                .then(data=>{
                                    if(data.error){
                                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                    }else{
                                        //update WAGER limit list amount
                                        RGSideController.selectedIntervalAmount = RGSideController.userData.CurrencySymbol+''+Helpers.formatCurrencyCents(data.Current.LimitValue);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                        //redirect to successful page
                                        let msg=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-WagerLimitsSuccess-Text');
                                        let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-SuccessButtonLabel-Continue');
                                        new SlideOverViewStatus(RGSideController.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,msg, '',[btnLabel],(btnId)=>{
                                            console.log("btn id",btnId);
                                            RGSideController.onSlideOverBackButtonClick()
                                        });
                                    }
                                });
                            }
                        })
                    break;
                    case 'Session':
                        new Popup(APP_STRINGS.getTitleById("2224066"),APP_STRINGS.getStringById("2224066"),[APP_STRINGS.YES, APP_STRINGS.NO], function(response) {
                            if(response == 0){
                                RGSideController.updateLimitLoading = true;
                                RGSideController.slideoverView.update();
                                USER.setTimeLimit(RGSideController.selectedPresetSessionValue)
                                .then(data=>{
                                    if(data.error){
                                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                    }else{
                                        //update SESSION limit list amount
                                        if(typeof RGSideController.gameLimitList[data.LimitType] == 'undefined'){
                                            RGSideController.gameLimitList[data.LimitType] = []
                                            if(typeof RGSideController.gameLimitList[data.LimitType][0] == 'undefined'){
                                                RGSideController.gameLimitList[data.LimitType][0] = []
                                                if(typeof RGSideController.gameLimitList[data.LimitType][0]['amount'] == 'undefined'){
                                                    RGSideController.gameLimitList[data.LimitType][0]['amount'] = parseInt(data.Current.LimitValue)
                                                }
                                            }
                                        }else{
                                            if(typeof RGSideController.gameLimitList[data.LimitType][0] == 'undefined'){
                                                RGSideController.gameLimitList[data.LimitType][0] = []
                                                if(typeof RGSideController.gameLimitList[data.LimitType][0]['amount'] == 'undefined'){
                                                    RGSideController.gameLimitList[data.LimitType][0]['amount'] = parseInt(data.Current.LimitValue)
                                                }
                                            }else{
                                                RGSideController.gameLimitList[data.LimitType][0]['amount'] = parseInt(data.Current.LimitValue)
                                            }
                                        }
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                        //redirect to successful page
                                        let msg=APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Success-Text');
                                        let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-SuccessButtonLabel-Continue');

                                        new SlideOverViewStatus(RGSideController.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,msg, '',[btnLabel],(btnId)=>{
                                            console.log("btn id",btnId);
                                            RGSideController.onSlideOverBackButtonClick()
                                        });
                                    }
                                });
                            }
                        })
                    break;
                    case 'Self Exclude':
                        var string = APP_STRINGS.getStringById("2224017")
                        var minutes = inputFields.excludeDay.value * 1440;
                        string = string.replace('#', inputFields.excludeDay.value)
                        var isIndefinite = false;
                        var reason = 'SelfExclusionFromSite';
                        new Popup(APP_STRINGS.getTitleById("2224017"), string,[APP_STRINGS.YES, APP_STRINGS.NO], function(response) {
                            if(response == 0){
                                RGSideController.updateLimitLoading = true;
                                RGSideController.slideoverView.update();
                                USER.setSelfExclude(minutes, isIndefinite, reason)
                                .then(data=>{
                                    if(data.error){
                                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                                        RGSideController.updateLimitLoading = false;
                                        RGSideController.slideoverView.update();
                                    }else{
                                        //logout user
                                        let title=APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Success-Title');
                                        let message = `${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Success-Message-Part1')} ${inputFields.excludeDay.value} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Success-Message-Part2')}`;
                                        let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-SuccessButtonLabel-Continue');
                                        new SlideOverViewStatus(RGSideController.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,title, message,[btnLabel],(btnId)=>{
                                            console.log("btn id",btnId);
                                            USER.logout(false)
                                            SIDE_VIEW_MANAGER.closeAnySideview();
                                        });
                                    }
                                });
                            }
                        })
                    break;
                }
            }
        }
        
    }

}