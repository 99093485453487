import {render} from "lit-html";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {SideViewMenu} from "../SideViewMenu";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateMobileFilterMenuView, TemplateMobileSortingView} from "@/templates/template-items/side-view/TemplateMobileFilterMenuView"
import {TemplateMobileFilterListView} from "@/templates/template-items/side-view/TemplateMobileFilterListView"
import {SlideOverView} from "@/js/ui/SlideOverView";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";
import {APP_STRINGS} from "../../core/AppStrings";
import {SideViewUser} from "./SideViewUser";
import {USER} from "../../core/User";
import {SearchView} from "../SearchView";


export class SideViewMobileFilterMenu extends SideViewBaseClass{
    constructor(type) {
        super("mobile-filter-view",SideViewBaseClass.TYPE.WIDE, type);
        this.menu;
    }

    renderViewContent() {
        console.log("rendering filter menu");
        this.filterType = [
            {
                name : 'game-makers',
                displayName : APP_STRINGS.getWPMLString('GameFilters-FilterTypeLabels-GameMakers'),
                filterKey : 'gameMakers',
            },
            // {
            //     name : 'win-lines',
            //     displayName : APP_STRINGS.getWPMLString('GameFilters-FilterTypeLabels-WinLines'),
            //     filterKey : 'winLines',
            // },
            // {
            //     name : 'number-of-ways',
            //     displayName : APP_STRINGS.getWPMLString('GameFilters-FilterTypeLabels-NumberOfWays'),
            //     filterKey : 'numOfWays',
            // },
            // { hide game feature
            //     name : 'game-features',
            //     displayName : 'Game Features',
            //     filterKey : 'features',
            // }
        ]
        this.selectedFilter = window.gameFiltering.selectedFilter
        this.selectedDisplayFilter = window.gameFiltering.selectedDisplayFilter
        this.selectedSorting = window.gameFiltering.selectedSorting;

        if(this.firstView == 'filter'){
            render(TemplateMobileFilterMenuView(this),this.element,{eventContext:this});
            FormHelpers.initializeFormComponents(this.element);
        }else if(this.firstView == 'sorting'){
            render(TemplateMobileSortingView(this),this.element,{eventContext:this});
            FormHelpers.initializeFormComponents(this.element);
            if($('#'+this.selectedSorting.id).length > 0){
                $('#'+this.selectedSorting.id).parents('.'+this.selectedSorting.className)[0].MDCRadio.checked = true
            }
        }
    }

    setupEventListener(){
        FormHelpers.initializeFormComponents(this.element);

        if(Object.keys(this.selectedDisplayFilter).length > 0){
            for (const [key, value] of Object.entries(this.selectedDisplayFilter)) {
                if($('#'+key).length > 0){
                    $('#'+key).parents('.mdc-'+value.className)[0].MDCCheckbox.checked = true
                }
            }
        }
    }

    onSlideOverBackButtonClick(e){
        this.slideoverView.close();
        render(TemplateMobileFilterMenuView(this),this.element,{eventContext:this});
    }

    onClickFilterType(e){
        let filterTypeID =e.currentTarget.dataset.filterTypeId;

        this.selectedFilterID = filterTypeID

        this.slideoverView = new SlideOverView(this.element,TemplateMobileFilterListView,this);
        $('.slide-over').attr('id', 'mobile-filter-view');
        this.setupEventListener();
    }

    onClickApplyFilter(e){
        var filterType = e.currentTarget.dataset.filter
        window.gameFiltering.triggerFilterAction()
        SIDE_VIEW_MANAGER.closeAnySideview();
        if(filterType == 'filter'){
            // if($('.mobile-filter').length > 0){
            //     $('.mobile-fitler')[0].MDCTextField.value = 
            // }
        }else if(filterType == 'sorting'){
            // if($('.mobile-sorting').length > 0){
            //     $('.mobile-sorting')[0].MDCTextField.value = 
            // }
        }
    }

    onClickClearAllFilter(e){
        window.gameFiltering.onClickRemoveFilter(e);
        this.selectedFilter = window.gameFiltering.selectedFilter
        this.selectedDisplayFilter = window.gameFiltering.selectedDisplayFilter
        render(TemplateMobileFilterMenuView(this),this.element,{eventContext:this});
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "user":
                new SideViewUser();
                break;
            case "logout":
                USER.logout();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }
}