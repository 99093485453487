import {render} from "lit-html";
import {TemplateUserProfile} from "../../../../templates/template-items/side-view/TemplateUserProfile";
import {TemplateSimplifiedUserProfile} from "../../../../templates/template-items/side-view/TemplateSimplifiedUserProfile";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {SideViewBank} from "../SideViewBank";

export class SideViewSubUserProfile extends SideViewSubBaseClass{
    constructor(container) {
        super(container);
        console.log("User profile sub");
    }

    renderView(){
        if(window.isSimplifiedSignUpForm){
            render(TemplateSimplifiedUserProfile(this),this.element,{eventContext:this});
        }else{
            render(TemplateUserProfile(this),this.element,{eventContext:this});
        }
    }

    onDepositButtonClick(){
        new SideViewBank();
    }


}