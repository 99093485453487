import {render} from "lit-html";
import {TemplateSearchComponent} from "../../templates/template-items/SearchTemplate";
import {GameSearch} from "../core/GameSearch";

export class SearchView {
    constructor(closeCallback) {
        this.element=null;
        this.mainContainer=null;
        this.searchInput=null;
        this.currentSearchValue="";
        this.showTemplates=true;
        this.loading=false;
        this.searchControl=new GameSearch();
        this.searchResult=null;
        this.closeCallback=closeCallback;
        this._init();
    }

    _init(){
        this.recentSearches=this.getRecentSearches();
        this.createSearchView();

    }

    createSearchView(){
        let domain = window.location.host.split('.')
        this.element=document.createElement("div");
        this.element.id="search-view";
        this.mainContainer=document.getElementById("main-container");;
        this.mainContainer.appendChild(this.element);
        this._updateView();
        this.searchInput=this.element.querySelector("#search-input-"+domain[1]);
        this.searchInput.focus();

    }

    _updateView(){
        render(TemplateSearchComponent(this),this.element,{eventContext:this});
    }

    _onCloseButtonClick(){
        this.close();
    }

    _onInputKeyUp(e){

        if(this.showTemplates==true && e.currentTarget.value.length>0){
            this.showTemplates=false;
            this._updateView();
        }else if(this.showTemplates==false && e.currentTarget.value.length==0){
            this.showTemplates=true;
            this.searchResult=null;
            this._updateView();
        }
    }

    _onInputChange(){
        if(this.searchInput.value.length>0){
            this.searchForGames(this.searchInput.value);
        }

    }

    _onResetButtonClick(){
        this.searchInput.value="";
        this.showTemplates=true;
        this.searchResult=null;
        this._updateView();
    }

    _onSearchPresetClick(e){
        this.searchInput.value=e.currentTarget.innerText;
        this.showTemplates=false;
        this.searchForGames(this.searchInput.value);
    }

    _onGameLinkClick(){
        if(this.searchInput.value.length>0){
            this.addToRecentSearches(this.searchInput.value);
        }
    }

    searchForGames(searchString){
        this.loading=true;
        this.searchResult=null;
        this._updateView();
        this.searchControl.searchForGamesByName(searchString)
            .then(data=>{
                this.loading=false;
                this.searchInput.focus();
                if(data.error){
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK])

                }else{
                    this.searchResult=data.games;
                    this._updateView();
                }
            });
    }

    close(){
        this.mainContainer.removeChild(this.element);
        this.mainContainer=null;
        if(this.closeCallback)this.closeCallback();

    }

    getRecentSearches(){
        let value=window.localStorage.getItem("recentsearch");
        if(value){
            let searches=JSON.parse(value);
            if(searches.length>5){
                searches.splice(0,searches.length-5);
            }

            return searches;
        }else{
            return [];
        }
    }

    addToRecentSearches(searchTerm){
        if(this.recentSearches.indexOf(searchTerm)<0){
            this.recentSearches.push(searchTerm);
            window.localStorage.setItem("recentsearch",JSON.stringify(this.recentSearches))
        }
    }

}
