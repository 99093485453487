import {BankFormBase} from "./BankFormBase";
import {Template_PayID_Withdrawal} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_PayID_Withdrawal";

export class PayIDAdditionalForm extends BankFormBase{
    constructor(container, accountReference, callback) {
        super(container,callback);
        this.accountReference = accountReference;
        this._init();
    }

    _getFormTemplate(){
        return Template_PayID_Withdrawal;
    }
    _validateInput(input){
     
        return true;
    }
}