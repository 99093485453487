import {render} from "lit-html";
import {FormHelpers} from "../../../../util/FormHelpers";
import {BANK} from "../../../../core/Bank";


export class BankFormBase {
    constructor(container, callback) {
        this.container=container;
        this.callback=callback;
        this.form=null;
        this.formInputs=null;
        this._loading=false;
        this._disabled=false;
    }

    set loading(value){
        this._loading=value;
        this._render();
    }
    set disabled(value){
        this._disabled=value;
        this._render();
    }

    _init(){
        this.renderTarget=document.createElement('div');
        this.container.append(this.renderTarget);
        this._render();
        this._initializeFormElements();
    }

    _render(){
        let template=this._getFormTemplate();
        console.log('****from render');
        console.log('this.container',this.container);
        console.log('template',template);

        render(template(this),this.renderTarget,{eventContext:this});
    }

    update(){
        this._render();
    }

    _onWithdrawButtonClick(e){
        let valid=this._validateForm();

        if(valid){
            if(this.callback){
                let formData={};
                this.formInputs.forEach(input=>{
                    formData[input.root_.dataset.field]=input.value;
                })
                console.log("formData",formData);
                this.callback(formData);
                this.loading=true;
            }
        }
    }

    _validateForm(){
        let valid=true;
        this.formInputs.forEach(input=>{
            valid= this._validateInput(input) && valid;
        })
        return valid;
    }

    _getFormTemplate(){
        throw new Error("Override this method in a subclass")
    }

    _initializeFormElements(){
        this.form=this.container.querySelector(".payment-method-form");
        FormHelpers.initializeFormComponents(this.form);
        let elements=this.form.querySelectorAll(".mdc-text-field,.mdc-select");
        this.formInputs=[];
        elements.forEach(element=>{
            let input;
            if(element.classList.contains("mdc-text-field")){
                input=element.MDCTextField;
                input.listen("change",e=>{this._validateInput(input)});
            }else if(element.classList.contains("mdc-select")){
                input=element.MDCSelect;
                if(input.root_.dataset.field=="existingAccount"){
                    input.foundation_.handleChange=()=>{this._applyExistingAccount(input)}
                }else{
                    input.foundation_.handleChange=()=>{this._validateInput(input)}
                }

                input.foundation_.handleMenuOpened=function(){input.menu_.menuSurface_.foundation_.adapter_.setMaxHeight("180px");}
            }
            input.useNativeValidation=false;

            this.formInputs.push(input);
        })
    }

    _validateInput(input){
        let method=input.root_.dataset.validationMethod;
        if(method===undefined){
            return true;
        }

        let valid=false;
        switch (method) {
            case "required":
                if(input.value.length>0){valid=true;}
                break;
            case "bsd":
                if(input.value.length>=6){valid=true;}
                break;
            default:
                valid=false;
        }

        this._setInputState(input,valid);
        return valid;
    }



    _setInputState(input, valid){
        if(valid){
            input.valid=true;
            if(input.helperText_){
                input.helperText_.foundation_.setPersistent(false);
            }
        }else{
            input.valid=false;
            if(input.helperText_){
                input.helperText_.foundation_.setPersistent(true);
            }
        }
    }

    _applyExistingAccount(input){
        if(input.value=="new-account"){
            this.formInputs.forEach(input=>{
                //todo we might need to rething this for other bank forms, perhaps create reset object in bank model
                if(input.root_.dataset.field!="" && input.root_.dataset.field!="existingAccount" && input.root_.dataset.field!="bankCountry"){
                    input.value="";
                    input.foundation_.adapter_.floatLabel(false)
                }

            })
        }else{
            let account=BANK.getExistingAccountById(input.value);
            this.formInputs.forEach(input=>{
                if(account[input.root_.dataset.field]){
                    input.value=account[input.root_.dataset.field];
                    input.foundation_.adapter_.floatLabel(true);
                }
            })
        }
    }

    destroy() {
        this.container.removeChild(this.renderTarget);
        delete this;
    }
}