export const Helpers={
    getCookie:function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";path=/";// + expires;
    },
    deleteCookie:function(cname){
        document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    formatNumber:function (num, currencyISO) {
        var decimal = 2;

        if (this.isNonStableCryptoCurrency(currencyISO)) {
            decimal = 8;
        }

        return this.formatCurrencyComma(num.toFixed(decimal));
    },
    formatDate:function(dateSource, system) {
        var result = [];
        var year = null;
        var month = null;
        var day = null;

        if (typeof dateSource === 'string') {
            var date = dateSource.split(' ')[0].split('-');
            if(date.length <= 2){
                date = dateSource.split(' ')[0].split('/');
                day = date[0].toString();
                month = date[1].toString();
                year = date[2].toString();
            }else{
                year = date[0].toString();
                month = date[1].toString();
                day = date[2].toString();
            }
        } else {
            dateSource = new Date(dateSource);

            year = dateSource.getFullYear().toString();
            month = (dateSource.getMonth() + 1).toString();
            day = dateSource.getDate().toString();
        }

        if (year.length < 2) {
            year = '0' + year;
        }

        if (month.length < 2) {
            month = '0' + month;
        }

        if (day.length < 2) {
            day = '0' + day;
        }


        if (system) {
            result.push(year, month, day);
            result = result.join('-');
        } else {
            result.push(day, month, year);
            result = result.join('/');
        }

        return result;
    },

    formatTime:function(timeSource){
        return timeSource.split(' ')[1];
    },

    formatCurrencyDecimal:function(amount, currencyISO) {
        var decimal = 2;

        if (typeof(amount) === 'undefined') {
            amount = 0;
        }
        if (typeof(amount) === 'string') {
            amount = parseFloat(amount);
        }

        if (this.isNonStableCryptoCurrency(currencyISO)) {
            decimal = 8;
        }

        let amountString=amount.toFixed(decimal);
        return amountString;
    },

    formatCurrency:function(amount, addDecimal) {
        if (typeof(amount) === 'undefined') {
            amount = 0;
        }
        if (addDecimal || this.isNonStableCryptoCurrency()) {
            amount = this.formatCurrencyDecimal(amount);
        }

        return this.formatCurrencyComma(amount);
    },

    formatCurrencyCents:function(amount, addDecimal) {
        if (typeof(amount) === 'undefined') {
            amount = 0;
        }

        return this.formatCurrency(parseFloat(amount) / 100, addDecimal);
    },

    findItemInArray(array,field,value){
        for(let i=0; i<array.length; i++){
            if(array[i][field]==value){
                return array[i];
            }
        }
        return null;
    },

    removeItemFromArray(array,item){
        for(let i=0; i<array.length; i++){
            if(array[i]==item){
                array.splice(i,1);
                return;
            }
        }
    },

    removeUrlParam(key, url = window.location.href) {
        var rtn = url.split("?")[0],
            param,
            params_arr = [],
            queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn;
    },

    getUrlParameter(sParam, url = window.location.href) {
        var sPageURL = decodeURIComponent(url.substring(url.indexOf("?") + 1)),
            sURLVariables = sPageURL.split('&');

        for(var i = 0; i < sURLVariables.length; i++) {
            var key = sURLVariables[i].substring(0, sURLVariables[i].indexOf('='));

            if(key === sParam) {
                //if equals means there is no value entered
                if(sURLVariables[i].indexOf('=') + 1 == sURLVariables[i].length) {
                    return '';
                }
                else {
                    return sURLVariables[i].substring(sURLVariables[i].indexOf('=') + 1);
                }
            }
        }

        return false;//param not found
    },


    getDeviceType(){
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    },

    isLastDay(dt) {
        return new Date(dt.getTime() + 86400000).getDate() === 1;
    },

    formatCurrencyComma(amount) {
        var split_str = amount.toString().split('.');
        var str = split_str[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

        if((typeof split_str[1]) !== 'undefined'){
            return str + '.' + split_str[1];
        }
        else{
            return str;
        }
    },

    isNonStableCryptoCurrency(currencyISO) {
        var CRPYTO_ISO = ['BTC', 'ETH', 'LTC', 'BCH'];

        if (!currencyISO) {

            if(typeof(window.isNonStableCrypto) !== 'undefined'){
                return window.isNonStableCrypto;
            }
            else{
                currencyISO = window.currencyISO;
            }
        }
        console.log(currencyISO);
        if (CRPYTO_ISO.indexOf(currencyISO) > -1) {
            return true;
        }
        else {
            return false;
        }
    },

    // To get currency ISO from the paymentMethod, e.g. return BTC from CryptoPay_BTC
    getCurrencyIsoByPaymentMethod(paymentMethod, currencyISO, isLowerCase) {
        currencyISO = window.isCrypto ? currencyISO : "BTC";

        if(paymentMethod != null){
            var paymentMethodChunks = paymentMethod.split('_');

            if(paymentMethodChunks.length > 1){
                currencyISO = paymentMethodChunks[paymentMethodChunks.length - 1];
            }
        }

        if(isLowerCase === true){
            currencyISO = currencyISO.toLowerCase();
        }
        return currencyISO;
    },

    formatLoyaltyPoints(amount) {
        if (typeof(amount) === 'undefined') {
            amount = 0;
        }
        amount=parseInt(amount);
        return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
}


