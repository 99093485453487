import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "./CAPPApi";
import {BANK} from "./Bank";

class User extends EventEmitter{
    constructor() {
        super();
        this.isLoggedIn=false;
        this.data=null;
        this.sessionKey=null;
        this.depositBonuses = null;
        this.manualBonuses = null
        this.activeBonuses = null;
        this.topActiveBonus = null;
        this.registerData=null;
        this._init();
    }

    _init(){

        if(Helpers.getCookie('is_logged_in')==1 ){
            console.log("***Cookie is logged in exist")
            this._processLoggedInUserData();

        }else{
            console.log("Cookie is logged in is missing");

        }
    }

    _processLoggedInUserData(){
        let storageData = localStorage.getItem('userData');
        if(storageData){
            console.log("***LOCal storageitem  exists")
            this.isLoggedIn=true;
        }else{
            console.log("***LOCal storage item is missing")
            this.isLoggedIn=false;
            Helpers.deleteCookie("is_logged_in");
            Helpers.deleteCookie("sessionKey");
            return;
        }


        this.sessionKey=Helpers.getCookie('sessionKey');

        //todo include error checking and load user data if for some reason session storage data doesnt exist
        this.data=JSON.parse(storageData);
        this._parseUserData();
        this.updatePlayerBalance();
    }

    login(username, password, loginData){
        BANK.invalidateExistingDepositData();

        if((typeof loginData) === 'object' && loginData != null && loginData.SessionKey){
            this.sessionKey=loginData.SessionKey;
            this.data=loginData.playerDetails;
            window.localStorage.setItem('userData', JSON.stringify(this.data));
            this._parseUserData();
            this.isLoggedIn=true;
            console.log(`User has logged in V2`,this.data);
            this.emit("onLoginStateChange",{loggedIn:true});
            return Promise.resolve(loginData);
        } else {
            return CAPP_API.login(username,password)
                .then(data=>{
                    console.log("Login response",data);
                    if(data.error){
                        //do something with error here?
                    }else{
                        this.sessionKey=data.SessionKey;
                        this.data=data.playerDetails;
                        window.localStorage.setItem('userData', JSON.stringify(this.data));
                        this._parseUserData();
                        this.isLoggedIn=true;
                        console.log(`User has logged in V2`,this.data);
                        this.emit("onLoginStateChange",{loggedIn:true});
                    }
                    return data;
                })
                /*todo handle unknown errors
                .catch(reason => {
                    return reason;
                })*/
        }
    }

    loginBySessionKey(sessionKey){
        BANK.invalidateExistingDepositData();

        return CAPP_API.loginBySessionKey(sessionKey)
            .then(data=>{
                console.log("Login response",data);
                if(data && !data.error){
                    this.sessionKey=data.SessionKey;
                    this.data=data.playerDetails;
                    window.localStorage.setItem('userData', JSON.stringify(this.data));
                    this._parseUserData();
                    this.isLoggedIn=true;
                    console.log(`User has logged in V2 by sessionKey`,this.data);
                    this.emit("onLoginStateChange",{loggedIn:true});
                }
                return data;
            })
            /*todo handle unknown errors
            .catch(reason => {
                return reason;
            })*/
    }

    loginAsPlayer(username, password, verificationKey, redirectUrl){
        BANK.invalidateExistingDepositData();

        return CAPP_API.loginAsPlayer(username, password, verificationKey)
            .then(data=>{
                console.log("Login As Player response",data);
                if(data.error){
                    //do something with error here?
                    //this.emit("onLoginError",{error:data.error.text});
                }else{
                    this.sessionKey=data.SessionKey;
                    this.data=data.playerDetails;
                    window.localStorage.setItem('userData', JSON.stringify(this.data));
                    this._parseUserData();
                    this.isLoggedIn=true;
                    console.log(`User has logged in V2`,this.data);
                    //this.emit("onLoginStateChange",{loggedIn:true});
                    //todo maybe better to handle in event listener rather than here?
                    if(redirectUrl){
                        window.location.replace(redirectUrl);
                    }else{
                        window.location.reload();
                    }

                }
                return data;
            })
            /*todo handle unknown errors
            .catch(reason => {
                return reason;
            })*/
    }

    _parseUserData(){
        window.isCrypto=this.data.isCryptoWallet;
        window.isNonStableCrypto=this.data.isNonStableCryptoWallet;
        window.currencyISO = this.data.CurrencyISO;
        this.data.CurrentBalance=parseFloat(this.data.CurrentBalance);
        this.data.CurrentBalanceDisaply=this.data.CurrencySymbol + Helpers.formatNumber((this.data.CurrentBalance/100));
    }

    logout(skipApi){
        console.log("something");

        if(!skipApi){
            CAPP_API.logout(this.sessionKey).then(data=>{
                console.log("Logout response",data);
            })
        }
        //we are not waiting for response from this api call, there are also still errors on session expired and so on


        //lets clean this up ourselves since backend sometimes leaves the mess when session expires
        Helpers.deleteCookie("is_logged_in");
        Helpers.deleteCookie("sessionKey");
        Helpers.deleteCookie("PHPSESSID");
        this.isLoggedIn=false;
        this.sessionKey=null;
        window.localStorage.removeItem('userData');
        this.data=null;
        BANK.invalidateExistingDepositData();
        this.emit("onLoginStateChange",{loggedIn:false});
    }

    resetPassword(emailAddress){
        return CAPP_API.resetPassword(emailAddress)
            .then(data=>{
                console.log("ForgotPassword response",data);
                return data
        })
    }

    getVerificationData(){

        return new Promise((resolve,reject)=> {
            let requests = [
                CAPP_API.getAccessStatus(this.sessionKey),
                CAPP_API.getPaymentAccounts(USER.sessionKey, USER.data.LangaugeISO)
            ];

            Promise.all(requests)
                .then(results=>{
                    //todo parse and prepare all the data
                    console.log("results",results);
                    if(results[0].error){
                        reject(results[0].error);
                    }else if (results[1].error){
                        reject(results[1].error);
                    }

                    let d={
                        basicDocs:this._processBasicDocsData(results[0]),
                        accountsVerification:this._processAccountsVerifiactionData(results[1])
                    }

                    resolve(d);
                })
                .catch(e=>{
                    console.log(e);
                });

        });
    }

    _processBasicDocsData(data){
        let accessData={}
        if(data.KycChecked){
            accessData.proofOfIdentification="approved";
            accessData.proofOfAddress="approved";
        }else{
            if(this.checkForDocumentPendingStatus("ID")){
                accessData.proofOfIdentification="pending";
            }else{
                accessData.proofOfIdentification="required";
            }
            if(this.checkForDocumentPendingStatus("POA")){
                accessData.proofOfAddress="pending";
            }else{
                accessData.proofOfAddress="required";
            }
        }

        return accessData;
    }
    _processAccountsVerifiactionData(data){
        let accountsData=[];
        if(data.PaymentAccounts && data.PaymentAccounts.TransactionPaymentAccount){
            let accounts=data.PaymentAccounts.TransactionPaymentAccount;
            accounts.forEach(account=>{
                if (account.CappPaymentVerification == 1) {
                    return;
                }
                if(account.KYCChecked){
                    account.status="approved";
                }else{
                    let pending=this.checkForDocumentPendingStatus(account.PaymentAccountID);
                    account.status=pending?"pending":"required";
                }
                if(account.CappPaymentCategorySlug=="credit-debit-cards"){
                    if(isNaN(parseInt(account.AccountReference[0]))){
                        account.docTitle = APP_STRINGS.getStringById("2223031")+": "+ account.AccountReference;
                    }else{
                        account.docTitle = account.AccountReference.replace(",", "## #### ");
                        account.docTitle = account.docTitle.substr(0, 4) + " " + account.docTitle.substr(4);
                        account.docTitle = APP_STRINGS.getStringById("2223031")+": "+ account.docTitle;
                    }

                }else{
                    account.docTitle = account.CappPaymentDisplayName+": "+account.AccountReference;
                }

                accountsData.push(account);
            })
        }
        return accountsData;
    }

    checkForDocumentPendingStatus(docId){
        var isPending=false;
        if (this.data && this.data.Attributes && this.data.Attributes.PlayerAttribute) {
            this.data.Attributes.PlayerAttribute.forEach(function (attr) {
                if ((attr.AttrName.indexOf("KYC_" + docId)>=0) && attr.AttrValue == "Pending") {
                    isPending=true;
                }
            });
        }

        return isPending;
    };

    updateUserData(){
        return CAPP_API.loadUser().then(data=>{
            if(data.error){
                //todo handle user data error
            }else{
                this.data=Object.assign(this.data,data);
                localStorage.setItem('userData',JSON.stringify(this.data));
                this.emit("onUserDataUpdate");
            }
        });
    }

    updatePlayerBalance(){
        if((!this.isLoggedIn) || (typeof CAPP_API) === 'undefined'){return Promise.resolve()}
        return CAPP_API.getPlayerBalance(this.sessionKey,this.data.LanguageISO)
            .then(data=>{
                console.log("balance data",data);
                if(data.error){

                }else{
                    this.data.CurrentBalance=parseFloat(data.CurrentTotalBalance);
                    this.data.CurrentRealBalance = parseFloat(data.CurrentRealBalance);
                    this.data.CurrentBonusBalance = parseFloat(data.CurrentBonusBalance);
                    this.data.CurrentLoyaltyPoints = parseFloat(data.CurrentLoyaltyPoints);
                    this._parseUserData();
                    localStorage.setItem('userData',JSON.stringify(this.data));
                    this.emit("onUserDataUpdate");
                    this.emit("onUserBalanceUpdate");
                }
                return data;
            });
    }

    changePassword(oldPassword, newPassword){
        return CAPP_API.changePassword(oldPassword, newPassword, this.sessionKey)
        .then(data=>{
            console.log("changePassword response",data);
            if(data.error){
                return data;
            }else{
                //success
                return CAPP_API.loadUser();
            }
        })
        .then(data=>{
            if(data.error){
                //todo handle user data loading error
            }else{
            }
            return data;
        })
    }

    resendActivationEmail(){
        return CAPP_API.resendActivationCode(this.sessionKey)
            .then(data=>{
                //dont think we need to do anything here
                return data;
            })
    }

    updatePlayerInfo(data){
        return CAPP_API.updatePlayerInfo(data)
            .then(data=>{
                console.log("updatePlayerInfo response",data);
                return data
        })
    }

    register(data){
        return CAPP_API.register(data)
        .then(data=>{
            console.log("register response",data);
            return data
    })
    }

    registerConfirmLocation(clientId, signature, answer){
        return CAPP_API.registerConfirmLocation(clientId,signature,answer)
            .then(data=>{
                console.log("register response",data);
                return data
            });
    }

    checkEmailExist(emailAddress){
        return CAPP_API.checkEmailExist(emailAddress)
        .then(data=>{
            console.log("checkEmailExist response",data);
            return data
        })
    }

    checkSignUpCode(SignupCode){
        return CAPP_API.checkSignUpCode(SignupCode)
        .then(data=>{
            console.log("checkSignUpCode response",data);
            return data
        })
    }

    getRegisterDataList(){
        let requests=[
            CAPP_API.getCountryCodeInfo(),
            CAPP_API.getCitizenshipList(),
            CAPP_API.getSecretQuestionList()
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                countryCodeInfo     : data[0],
                citizenshipList      : data[1],
                secretQuestionList  : data[2],
            }
            // this.registerData = formattedData;
            return formattedData;
        })
    }

    getRGLimitData(){
        let requests=[
            CAPP_API.transactionGetAllDepositLimit(this.sessionKey),
            CAPP_API.gameLimitGetAll(this.sessionKey),
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                depositLimitList   : data[0],
                gameLimitList      : data[1],
            }
            return formattedData;
        })
    }

    setDepositLimit(intervalType, amount){
        return CAPP_API.transactionSetDepositLimit(intervalType, amount, this.sessionKey)
        .then(data=>{
            console.log("setDepositLimit response",data);
            return data
        })
    }

    setBetLimit(intervalType, amount){
        return CAPP_API.setBetLimit(intervalType, amount, this.sessionKey)
        .then(data=>{
            console.log("setBetLimit response",data);
            return data
        })
    }

    setLossLimit(intervalType, amount){
        return CAPP_API.setLossLimit(intervalType, amount, this.sessionKey)
        .then(data=>{
            console.log("setLossLimit response",data);
            return data
        })
    }

    setTimeLimit(timeDuration){
        return CAPP_API.setTimeLimit(timeDuration, this.sessionKey)
        .then(data=>{
            console.log("setTimeLimit response",data);
            return data
        })
    }

    setSelfExclude(minutes, isIndefinite, reason){
        return CAPP_API.setSelfExclude(minutes, isIndefinite, reason, this.sessionKey)
        .then(data=>{
            console.log("setSelfExclude response",data);
            return data
        })
    }
    
    getBonusAllData(){
        let requests=[
            CAPP_API.getAvailableBonuses(this.sessionKey),
            CAPP_API.getActiveBonuses(this.sessionKey),
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                availableBonusList   : data[0],
                activeBonusList      : data[1],
            }
            this._processDepositBonus(formattedData)
            this._processManualBonus(formattedData)
            this._processActiveBonus(formattedData)

            let bonusData = {
                depositBonuses: this.depositBonuses,
                manualBonuses: this.manualBonuses,
                activeBonuses: this.activeBonuses,
                topActiveBonus: this.topActiveBonus
            };

            return bonusData;
        })
    }

    uploadBasicDocument(docId,file){
        let data = new FormData();
        data.append('username', this.data.Username);
        data.append('ImageFile', file);
        data.append('paymentMethod', 0);
        data.append('AccountID', this.data.ClientID);
        data.append('idDocument', docId);
        data.append('accountReference', "");
        return this._submitVerificationDocument(data)
            .then(data=>{
                console.log("UPLOAD RESPONSE",data);
                return data;
            })
    }

    uploadAccountDocument(accountId,accountReference,file){
        let data = new FormData();
        data.append('username', this.data.Username);
        data.append('ImageFile', file);
        data.append('paymentMethod', accountId);
        data.append('AccountID', this.data.ClientID);
        data.append('idDocument', accountReference);
        data.append('accountReference', accountReference);
        return this._submitVerificationDocument(data)
            .then(data=>{
                console.log("UPLOAD RESPONSE",data);
                return data;
            })
    }

    _submitVerificationDocument(formData){
        return CAPP_API.uploadDocument(formData);
    }

    _processDepositBonus(bonusList){
        var currencySymbol = USER.data.CurrencySymbol;
        var depositBonusList = [];
        if(bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit){
            if(bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit.length > 0){
                depositBonusList = bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit;
                for (const [key, bonusInfo] of Object.entries(depositBonusList)) {
                    depositBonusList[key]['displayData'] = []
                    depositBonusList[key]['displayData']['minDeposit'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusMinDepositAmount, true)
                    depositBonusList[key]['displayData']['matchAmount'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusPercentageMaxAmount, true)
                    depositBonusList[key]['displayData']['fixedAmount'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusFixedAmount, true)
                    if (bonusInfo.BaseRule && bonusInfo.BaseRule.WagerRequirementMultiplier){
                        depositBonusList[key]['displayData']['wageringReq'] = parseInt(bonusInfo.BaseRule.WagerRequirementMultiplier)+'x';
                    }

                    try{
                        depositBonusList[key]['displayData']['expiryDate'] = Helpers.formatDate(bonusInfo.BaseRule.ExpiryDate);
                    }catch(e){
                        depositBonusList[key]['displayData']['expiryDate']="";
                    }

                    depositBonusList[key]['displayData']['maxBet'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.cappMaxBet, true)
                    depositBonusList[key]['displayData']['buttonName'] = 'ACTIVATE'
                }
                this.depositBonuses = depositBonusList;
            }else{
                this.depositBonuses = null;
            }
        }
    }

    _processManualBonus(bonusList){
        var currencySymbol = USER.data.CurrencySymbol;
        var manualBonusList = [];
        if(bonusList.availableBonusList.PlayerGetPreAuthBonusesResult && bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth){
            if(bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth.length > 0){
                manualBonusList = bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth;
                for (const [key, bonusInfo] of Object.entries(manualBonusList)) {
                    manualBonusList[key]['displayData'] = []
                    manualBonusList[key]['displayData']['bonusAmount'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusAmount, true)
                    if (bonusInfo.WagerRequirementMultiplier){
                        manualBonusList[key]['displayData']['wageringReq'] = parseInt(bonusInfo.WagerRequirementMultiplier)+'x';
                    }
                    
                    var expiryDate = ''
                    if(bonusInfo.ExpiryDateFixed){
                        expiryDate = bonusInfo.ExpiryDateFixed;
                    }else{
                        if(bonusInfo.ExpiryDate){
                            expiryDate = bonusInfo.ExpiryDate
                        }else{
                            expiryDate = ExpiryDaysFromAwarding
                        }
                    }

                    try{
                        manualBonusList[key]['displayData']['expiryDate'] = Helpers.formatDate(expiryDate);
                    }catch(e){
                        manualBonusList[key]['displayData']['expiryDate']="";
                    }

                    manualBonusList[key]['displayData']['maxBet'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.cappMaxBet, true)
                    manualBonusList[key]['displayData']['buttonName'] = 'CLAIM'
                }
                this.manualBonuses = manualBonusList;
            }else{
                this.manualBonuses = null;
            }
        }else{
            this.manualBonuses = null;
        }
    }

    _processActiveBonus(bonusList){
        var currencySymbol = USER.data.CurrencySymbol;
        var activeBonusList = [];
        let topPriority = 0;
        //console.log(bonusList.activeBonusList);
        if(bonusList.activeBonusList.Bonuses.BonusAwarded){
            if(bonusList.activeBonusList.Bonuses.BonusAwarded.length > 0){
                activeBonusList = bonusList.activeBonusList.Bonuses.BonusAwarded;
                let priority = [];
                for (const [key, bonusInfo] of Object.entries(activeBonusList)) {
                    activeBonusList[key]['displayData'] = []
                    activeBonusList[key]['displayData']['bonusAmount'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusAmountGiven, true)
                    activeBonusList[key]['displayData']['maxBet'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.cappMaxBet, true)
                    if(bonusInfo.BonusWagerRequirementAchieved){
                        activeBonusList[key]['displayData']['wagerAchieved'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusWagerRequirementAchieved, true)
                        activeBonusList[key]['displayData']['wagerRemained'] = currencySymbol+''+Helpers.formatCurrencyCents(bonusInfo.BonusWagerRequirementRemain, true)
                        activeBonusList[key]['displayData']['progressPercentage'] = (parseFloat(bonusInfo.BonusWagerRequirementAchieved)/parseFloat(bonusInfo.BonusWagerRequirement)) * 100
                    }
                    if (bonusInfo.wagerRequirementMultiplier){
                        activeBonusList[key]['displayData']['wageringReq'] = parseInt(bonusInfo.wagerRequirementMultiplier)+'x';
                    }
                    //console.log(activeBonusList);
                    var expiryDate = ''
                    if(bonusInfo.ExpiryDateFixed){
                        expiryDate = bonusInfo.ExpiryDateFixed;
                    }else{
                        if(bonusInfo.ExpiryDate){
                            expiryDate = bonusInfo.ExpiryDate
                        }else{
                            expiryDate = ExpiryDaysFromAwarding
                        }
                    }

                    try{
                        activeBonusList[key]['displayData']['expiryDate'] = Helpers.formatDate(expiryDate);
                    }catch(e){
                        activeBonusList[key]['displayData']['expiryDate']="";
                    }

                    try{
                        activeBonusList[key]['displayData']['activationDate'] = Helpers.formatDate(bonusInfo.activationStartDate);
                    }catch(e){
                        activeBonusList[key]['displayData']['activationDate']="";
                    }

                    if(activeBonusList.length == 1){
                        //if only have 1 active bonus make it as top active bonus remain active bonus list to null
                        this.topActiveBonus = activeBonusList[key];
                        activeBonusList = null;
                    }else{
                        this.topActiveBonus = null;
                        priority[key] = bonusInfo.Priority;
                    }
                }
                if(activeBonusList){
                    if(activeBonusList.length > 1){
                        //set top priority
                        [topPriority] = Object.entries(priority).sort(([ ,v1], [ ,v2]) => v1 - v2);
                        var topPriorityKey = topPriority[0]
                        this.topActiveBonus = activeBonusList[topPriorityKey];
                        delete activeBonusList[topPriorityKey];
                    }else{
                        this.topActiveBonus = null;
                    }
                }
                this.activeBonuses = activeBonusList;
            }else{
                this.topActiveBonus = null;
            }
        }
    }
    
    claimBonus(bonusAwardedPreID){
        return CAPP_API.claimBonus(bonusAwardedPreID, this.sessionKey)
        .then(data=>{
            console.log("claimBonus response",data);
            return data
        })
    }

    setTopPriorityBonus(bonusAwardedPreID){
        return CAPP_API.setTopPriorityBonus(bonusAwardedPreID, this.sessionKey)
        .then(data=>{
            console.log("setTopPriorityBonus response",data);
            return data
        })
    }

    redeemVoucherCode(voucherCode){
        return CAPP_API.redeemVoucherCode(voucherCode, this.sessionKey, this.data.LanguageISO)
        .then(data=>{
            console.log("redeemVoucherCode response",data);
            return data
        })
    }

    forfeitBonus(bonusAwardedID){
        return CAPP_API.forfeitBonus(bonusAwardedID, this.sessionKey)
        .then(data=>{
            console.log("forfeitBonus response",data);
            return data
        })
        
    }

    getAccountTransactions(startDate, endDate){
        return CAPP_API.getAccountTransactions(startDate, endDate, this.sessionKey)
        .then(data=>{
            console.log("getAccountTransactions response",data);
            return data
        })
    }

    getPlayerGamePlayHistory(startDate, endDate, limit, page){
        return CAPP_API.getPlayerGamePlayHistory(startDate, endDate, limit, page, 1, this.sessionKey)
        .then(data=>{
            console.log("getPlayerGamePlayHistory response",data);
            return data
        })
    }

    getPlayerBonusHistory(dateFrom, removeActives){
        return CAPP_API.getPlayerBonusHistory(dateFrom, removeActives, this.sessionKey)
        .then(data=>{
            console.log("getPlayerBonusHistory response",data);
            return data
        })
    }

    getPlayerRacesHistory(dateFrom, removeActives){
        return CAPP_API.getPlayerRacesHistory(dateFrom, removeActives, this.sessionKey)
        .then(data=>{
            console.log("getPlayerRacesHistory response",data);
            return data
        })
    }

    getPlayerShopHistory(dateFrom){
        let requests=[
            CAPP_API.getPlayerPointsHistory(dateFrom, this.sessionKey),
            CAPP_API.getPlayerShopPurchasesHistory(dateFrom, this.sessionKey),
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                pointsHistoryList         : data[0],
                purchasesHistoryList      : data[1],
            }

            return formattedData;
        })
    }
    
    activateAccount(activateCode, ea){
        return CAPP_API.activateAccount(activateCode, ea)
        .then(data=>{
            console.log("activateAccount response",data);
            if(this.isLoggedIn ==true){
                this.updateUserData();
            }
            return data
        })
    }

    playerResetPasswordWithResetCode(emailAddress, passwordResetCode){
        this.logout(true)
        return CAPP_API.playerResetPasswordWithResetCode(emailAddress, passwordResetCode)
            .then(data=>{
                console.log("playerResetPasswordWithResetCode response",data);
                if(data.error){
                    //do something with error here?
                }else{
                }
                return data;
            })
    }

    sendCountryRestrictionEmail(email,csrfToken){
        return CAPP_API.submitCountryRestrictionEmail(email,csrfToken);
    }
}



// expose USER as singleton
export const USER = new User();

USER.VERIFICATION_DOCUMENT_TYPE={
    ID:0,
    PROOF_OF_ADDRESS:1,
    ACCOUNT_DOCUMENT:-1
};