export const InitializeViewportHeightController=()=>{
    setDocHeight();

    window.addEventListener('resize', function () {
        setDocHeight();
    });
    window.addEventListener('orientationchange', function () {
        setDocHeight();
    });
};


function setDocHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);

};