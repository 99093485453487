import {render} from "lit-html";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {TemplateForgotPasswordSideView} from "../../../templates/template-items/side-view/TemplateForgotPasswordSideView";
import {FormHelpers} from "../../util/FormHelpers";
import {USER} from "../../core/User";
import {Popup} from "../Popup";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";
import {SideViewUser} from "./SideViewUser";
import {SearchView} from "../SearchView";


export class SideViewForgotPassword extends SideViewBaseClass{
    constructor() {
        super("forgot-password",SideViewBaseClass.TYPE.NORMAL, null, SideViewForgotPassword.ALIGNMENT);
        this.loading=false;
    }

    renderViewContent() {
        console.log("rendering forgotPassword");
        render(TemplateForgotPasswordSideView(this),this.element,{eventContext:this});
        FormHelpers.initializeFormComponents(this.element);
    }

    onSubmitForgotPassword(e){
        e.preventDefault();
        let inputData=FormHelpers.getFormDataAsObject(e.target);
        let controller = this
        console.log(inputData)
        this.loading=true;
        render(TemplateForgotPasswordSideView(this),this.element,{eventContext:this});
        USER.resetPassword(inputData.emailAddress)
        .then(data=>{
            if(data.error){
                this.loading=false;
                render(TemplateForgotPasswordSideView(this),this.element,{eventContext:this});
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK])
            }else{
                this.loading=false;
                render(TemplateForgotPasswordSideView(this),this.element,{eventContext:this});
                new SlideOverViewStatus(controller.element,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                    APP_STRINGS.getWPMLString('SideViewForgotPassword-SuccessView-Title'),
                    APP_STRINGS.getWPMLString('SideViewForgotPassword-SuccessView-Message'),
                    [APP_STRINGS.getWPMLString('SideViewForgotPassword-SuccessView-ButtonLabel-Continue')],
                    (btnId)=>{
                        SIDE_VIEW_MANAGER.closeAnySideview();
                });
            }

        });
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "user":
                new SideViewUser();
                break;
            case "logout":
                USER.logout();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }
}

SideViewForgotPassword.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;