export const InitExternalBannerController=()=>{
    $(function() {
        if(document.querySelector('.hero-section.ext-slider-holder.logged-out.home')){
            setBannerHeight(document.querySelector('.hero-section.ext-slider-holder.logged-out.home'));
        }

        if(document.querySelector('.hero-section.ext-slider-holder.logged-in.home')){
            setBannerHeight(document.querySelector('.hero-section.ext-slider-holder.logged-in.home'));
        }

        if(document.querySelector('.hero-section.ext-slider-holder.logged-out.games-list')){
            setBannerHeight(document.querySelector('.hero-section.ext-slider-holder.logged-out.games-list'));
        }

        if(document.querySelector('.hero-section.ext-slider-holder.logged-in.games-list')){
            setBannerHeight(document.querySelector('.hero-section.ext-slider-holder.logged-in.games-list'));
        }
    });

    //Tentative solution to control the external banner container height.
	//Should be removed once MKS is able to provide a fully responsive external banner.
    function setBannerHeight(element){
        if((typeof ResizeObserver) === 'function'){
            //console.log(element);
            var ro = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const cr = entry.contentRect;
                    //console.log('Element:', entry.target);
                    //console.log(element.classList.contains('dynamic'));
                    //console.log(element.dataset.aspectRatio);
                    if(element.classList.contains('dynamic') && element.dataset.aspectRatio > 0){
                        element.style.height = (cr.width / parseFloat(element.dataset.aspectRatio)) + 'px';
                        console.log('Banner Dynamic Height: ' + (cr.width / parseFloat(element.dataset.aspectRatio)) + 'px');
                        //console.log(parseFloat(element.dataset.aspectRatio));
                    }
                    else if(element.classList.contains('common-banner')){
                        if(cr.width > 1280){
                            element.style.height = '374px';
                        }
                        else if(cr.width > 1024 && cr.width <= 1280){
                            element.style.height = '350px';
                        }
                        else if(cr.width > 440 && cr.width <= 1024){
                            element.style.height = '190px';
                        }
                        else{
                            element.style.height = '168px';
                        }
                    }
                    else if(element.classList.contains('games-list')){
                        if(cr.width > 1024){
                            element.style.height = '300px';
                        }
                        else if(cr.width > 440 && cr.width <= 1024){
                            element.style.height = '190px';
                        }
                        else{
                            element.style.height = '168px';
                        }
                    }
                    else{
                        if(cr.width > 1280){
                            element.style.height = '374px';
                        }
                        else if(cr.width > 1024 && cr.width <= 1280){
                            element.style.height = '350px';
                        }
                        else if(cr.width > 440 && cr.width <= 1024){
                            element.style.height = '190px';
                        }
                        else{
                            element.style.height = '168px';
                        }
                    }
                    console.log(`Banner size: ${cr.width}px x ${cr.height}px`);
                }
            });
            //Observe one or multiple elements
            ro.observe(element);
        }
    }
}