import {html, nothing} from 'lit-html';
import {FAST_TRACK} from "../../js/core/FastTrack";

export const TemplateFTInbox = el => html`
  ${listInboxMessages(el)}
`;

function listInboxMessages(el) {
  let items=[];
  console.log(el);
  el.data.forEach(function(item, index) {
    items.push(html`<div class="capp-ft-message-wrapper" data-id="${item.MessageId}" @click=${FAST_TRACK._onInboxMessageClick}>
      <div class="ft-inbox-preview-container ${item.IsRead == false ? 'unread' : ''}">
        <div>
          <span>
            <img class="mail-status-icon" src="${CDN_ASSETS_URL}/mobile-${item.IsRead == false ? 'unread' : 'read'}-mail-icon.svg">
          </span>
          <span>${getDate(item.Date)}</span></div>
        <div>${item.Title}</div>
        <div>${item.PreviewText}</div>
      </div>
    </div>`);
  });

  function getDate(ftDate) {
    var date = new Date(),
        day = String(date.getDate()).padStart(2, '0'),
        month = String(date.getMonth() + 1).padStart(2, '0'),
        year = date.getFullYear(),
        date_str = '',
        today = year + '-' + month +'-' + day,
        time = '';

    ftDate = ftDate.split(' ');
    time = ftDate[1].split(':');
    time.pop();
    if (ftDate[0] === today) {
      date_str = 'Today  ' + time.join(':');
    }
    else {
      date_str = month + '/' + day + '  ' +  time.join(':');
    }

    return date_str;

  }

  return items;
}