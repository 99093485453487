import {SIDE_VIEW_MANAGER} from "./SideViewManager";

export class SideViewBaseClass{
    constructor(id,type,firstView, alignment) {
        console.log("ALIGNMENT",alignment);
        if(SIDE_VIEW_MANAGER._currentSideView && SIDE_VIEW_MANAGER._currentSideView.id==id){return;}
        this.id=id;
        this.type=type;
        this.element=null;
        this.alignment= alignment ? alignment : SideViewBaseClass.ALIGNMENT.LEFT;
        this.firstView=firstView;
        this._init();
    }

    _init(){
        this.element=document.createElement("div");
        this.element.id=this.id;
        this.element.classList.add("side-view");

        console.log("ALIGNMENT2",this.alignment);

        if(this.alignment==SideViewBaseClass.ALIGNMENT.RIGHT){
            this.element.classList.add("side-view-right-align");
        }else{
            this.element.classList.add("side-view-left-align");
        }

        if(this.type.length>0){
            this.element.classList.add(this.type);
        }
        SIDE_VIEW_MANAGER.openSideView(this);

    }


    close(){
        //document.ontouchmove = function(e){ return true; }
        SIDE_VIEW_MANAGER.clear();
        console.log("removing sub view new");
        this.element.classList.add("close");
        setTimeout(()=>{
            try {
                document.body.removeChild(this.element);
            }catch (e){}

        },200);

        delete this;
    }

    onSlideOverBackButtonClick(){
        console.log("CLICKING");
    }

    renderViewContent(){
       throw new Error("ERROR! Override this method in a subclass")
    }


}

SideViewBaseClass.TYPE={
    NORMAL:"",
    WIDE:"big"
};

SideViewBaseClass.ALIGNMENT={
    LEFT:"left",
    RIGHT:"right"
}