import {html} from 'lit-html';
import {VideoModal} from "../../js/ui/VideoModal";

export const TemplateVideoModal = el => html`
    <div class="video-modal-container">
        <div class="item-right" @click="${el.onCloseButtonClick}">
            <img @click="${el.onCloseButtonClick}" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
        </div>
        <video id="video-player" width="100%" height="auto" autoplay muted playsinline controls>
            <source src="${el.url}" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
`;
