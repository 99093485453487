import {EventEmitter} from 'events';
import {USER} from '@/js/core/User.js';

export class SideViewSubBaseClass extends EventEmitter{
    constructor(container){
        super();
        this.container=container;
        this.userData = USER.data;
        this._init();
    }

    _init(){
        this.element=document.createElement("div");
        this.element.classList.add("side-view-sub");
        this.container.appendChild(this.element);

        this.renderView();
    }

    renderView(){
        throw new Error("ERROR! Override this method in a subclass")
    }

    close(){
        console.log("removing sub view");
        try{
            this.container.removeChild(this.element);
        }catch (e) {
            console.log(e);
        }

        delete this;
    }
}