import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateRGSessionLimits = (el) => html`
<div class="view-with-heading">
<div class="view-heading">
        <div class="item-left">
        <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click=${el.onSlideOverBackButtonClick}>
        <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Title')}</span>
        </div>
    </div>
    <div class="scrolling-content">
        <div class="content">
        <form class="limits-form ${el.updateLimitLoading ? 'disabled' : ''}" @submit=${el.onSubmitLimitForm} data-limit-type="${el.selectedLimitTypeID}">
            <p class="Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-DescriptionText')}</p>
                <div class="play-limit-info" style="display:${(!getCurrentSessionAmount(el)) ? 'none' : 'block' }">
                    <p>${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-CurrentValueDisplay-Label')}</p>
                    <h2>${getCurrentSessionAmount(el)} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Form-ValueSuffix-Minutes')}</h2>
                </div>
            <h4>${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Form-Title')}</h4>
            <div class="form-element-2 favourite-amount time-presets">
                <ul>
                    ${el.presetSessionTime.map((value,index)=>
    html`<li class="${el.selectedPresetSessionTime==index ? `selected` : '' }" @click=${el.onClickPresetAmount} data-preset-id="${index}" data-value="${value}" data-type="session"><span>${value} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Form-ValueSuffix-Minutes')}</span></li>`
)}
                </ul>
            </div>
            <div class="form-element-2">
                <button class="primary-button ${el.updateLimitLoading ? 'loading' : ''}">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-SessionLimits-Form-ButtonLabel-Submit')}</span><preloader-element></preloader-element>
                </button>
            </div>
        </form>
        </div>
    </div>
    
</div>
    
`

function getCurrentSessionAmount(el){
    if(typeof el.gameLimitList['TIME_LIMIT'] != 'undefined'){
        if(typeof el.gameLimitList['TIME_LIMIT'][0] != 'undefined'){
            return el.gameLimitList['TIME_LIMIT'][0]['amount']
        }
    }
    return false;
}