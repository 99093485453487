import {EventEmitter} from 'events';
import {render} from "lit-html";
import {TemplateSideViewMenu} from "../../templates/template-items/side-view/TemplateSideViewMenu";

export class SideViewMenu extends EventEmitter{
    constructor(menuItems,container, contentToBlur) {
        super();

        this.menuItems=menuItems;
        this.selectedItem=menuItems[0];
        this.container=container;
        this.contentToBlur=contentToBlur;
        this.mobileOpened=false;
        this._init();
    }

    _init(){
        this.element=document.createElement("div");
        this.element.classList.add("side-view-menu");
        this.container.appendChild(this.element);
        this.updateMenu();
    }



    setSelectedItem(item){
        this.contentToBlur.classList.remove("blur-4");
        this.selectedItem=item;
        this.updateMenu();
        this.emit("change",{selectedId:this.selectedItem,index:this.menuItems.indexOf(this.selectedItem)});
    }

    onMenuItemClick(e){
        this.contentToBlur.classList.remove("blur-4");
        this.selectedItem=e.currentTarget.dataset.itemId;
        this.updateMenu();
        this.emit("change",{selectedId:this.selectedItem,index:this.menuItems.indexOf(this.selectedItem)});
    }

    onMobileItemClick(e){
        e.stopPropagation();
        this.mobileOpened=!this.mobileOpened;
        this.updateMenu();
        let _self=this;
        let clickListener=function(){
            _self.contentToBlur.classList.remove("blur-4");
            _self.mobileOpened=false;
            _self.updateMenu();
            window.removeEventListener("click", clickListener);
        }
        if(this.mobileOpened){
            this.contentToBlur.classList.add("blur-4");
            window.addEventListener("click",clickListener);
        }else{
            this.contentToBlur.classList.remove("blur-4");
        }
    }

    onCloseButtonClick(){
        this.emit("close");
    }

    updateMenu(){
        render(TemplateSideViewMenu(this),this.element,{eventContext:this});
    }
}

SideViewMenu.ITEM_TYPES={
    PROFILE:'profile',
    PREFERENCES:'preferences',
    INBOX:'inbox',
    VERIFICATION:'verification',
    RESPONSIBLE_GAMBLING:'responsibleGambling',
    DEPOSIT:'deposit',
    WITHDRAW:'withdraw',
    HISTORY:'history',
    BONUSES:'bonuses',
    LOYALTY_SHOP:'loyaltyShop',
}