import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";
import {USER} from "../../../js/core/User";

export const TemplateMobileFilterListView = (el) => html`
<div class="view-with-heading">
    <div class="view-heading layout">
        <div class="close-img-container">
			<img @click="${el.close}" class="close-img" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
		</div>
        <div class="main-side-mobile-heading">
            <span @click=${el.onMenuItemClick} data-action="search" ><img @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/search-icon.svg"></span>
            <span @click=${el.onMenuItemClick} data-action="help"><img data-action="help"  @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/contact-us-icon.svg"></span>
            ${USER.isLoggedIn ?  html`<span @click=${el.onMenuItemClick} data-action="logout" class="yellow-back">LOG OUT</span>` :
    html `<span @click=${el.onForgottenPasswordClick} class="yellow-back">RESET PASSWORD</span>`}
            <span><img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/close.svg"></span>
        </div>
        <span class="title">${el.filterType[el.selectedFilterID]['displayName']}</span>
    </div>
    <div class="scrolling-content">
        <div class="content">
            ${displayFilterList(el)}
            <button class="primary-button" style="width:100%;" @click=${el.onSlideOverBackButtonClick}><span>${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-ButtonLabel-ApplyFilters')}</span></button>
        </div>
    </div>
    
</div>
`
function displayFilterList(el){
    switch(el.selectedFilterID){
        case '0':
            return gameMakerList();
            break;
        case '1':
            return winLinesList();
            break;
        case '2':
            return numberOfWaysList();
            break;
        case '3':
            return gameFeatureList();
            break;
    }
}

function gameMakerList(){
    const displayList = []
    if(window.gameMakerFilterList){
        for (const [key, value] of Object.entries(window.gameMakerFilterList)) {
            displayList.push( html`
                <div class="mdc-form-field full-width">
                    <label for="checkbox-mobile-f1-${key}">${value.title}
                        <div class="mdc-checkbox mdc-gameType-videoSlot mdc-gameMakers">
                            <input type="checkbox"
                                    class="mdc-checkbox__native-control"
                                    id="checkbox-mobile-f1-${key}" name="option5" value="${value.slug}"/>
                            <div class="mdc-checkbox__background">
                                <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                                <div class="mdc-checkbox__mixedmark"></div>
                            </div>
                            <div class="mdc-checkbox__ripple"></div>
                        </div>
                    </label>
                </div>
            `)
        }
        return displayList;
    }
}

function winLinesList(){
    return html`
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f2-1">${APP_STRINGS.getWPMLString('GameFilters-WinLinesFilter-ItemLabel-1to8Lines')}</label>
            <div class="mdc-checkbox mdc-win-lines">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f2-1" name="option8" value="1-8lines"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f2-2">${APP_STRINGS.getWPMLString('GameFilters-WinLinesFilter-ItemLabel-9to25Lines')}</label>
            <div class="mdc-checkbox mdc-win-lines">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f2-2" name="option9" value="9-25lines"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f2-3">${APP_STRINGS.getWPMLString('GameFilters-WinLinesFilter-ItemLabel-31PlusLines')}</label>
            <div class="mdc-checkbox mdc-win-lines">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f2-3" name="option10" value="31-242lines"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
    `
}

function numberOfWaysList(){
    return html`
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f3-1">${APP_STRINGS.getWPMLString('GameFilters-NumberOfWaysFilter-ItemLabel-243Ways')}</label>
            <div class="mdc-checkbox mdc-number-of-ways">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f3-1" value="243-1023ways"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f3-2">${APP_STRINGS.getWPMLString('GameFilters-NumberOfWaysFilter-ItemLabel-1024Ways')}</label>
            <div class="mdc-checkbox mdc-number-of-ways">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f3-2" value="1024-10000ways"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
    `
}

function gameFeatureList(){
    return html`
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f4-1">Branded Games</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-1" value="branded"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f4-2">Gamble Your Winnings</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-2" value="gamble_your_winnings"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f4-3">Bonus Levels</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-3" value="bonus_levels"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f4-4">Free Spins</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-4" value="free_spins"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-f4-5">Scatter Reels</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-5" value="scatter_reels"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
        <div class="mdc-form-field full-width">
            <label for="checkbox-mobile-f4-6">Wild Reels</label>
            <div class="mdc-checkbox mdc-game-features">
                <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-mobile-f4-6" value="wild_reels"/>
                <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-white.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
                <div class="mdc-checkbox__ripple"></div>
            </div>
        </div>
    `
}
