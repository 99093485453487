import {DepositAdditionalFormBase} from "./DepositAdditionalFormBase";
import {Template_CryptoPay_Deposit_AdditionlForm} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_CryptoPay_Deposit";
import WAValidator from 'multicoin-address-validator';

export class CryptoPayAdditionalForm extends DepositAdditionalFormBase{
    constructor(container, currencyISO, isBtcAddressExist, networks, callback) {
        super(container,callback);
        this.currencyISO=currencyISO;
        this.isBtcAddressExist=isBtcAddressExist;
        this.networks=networks;
        this._init();
    }

    _getFormTemplate(){
        return Template_CryptoPay_Deposit_AdditionlForm;
    }

    _triggerValidateAddressNetwork(){
        let valid = true;

        this.formInputs.forEach(input=>{
            let method=input.root_.dataset.validationMethod;
            if(method==='network'){
                valid = this._validateInput(input);
            }
        });
        return valid;
    }

    _validateAddressNetwork(network, bitcoinWithdrawalAddress){
        let valid = true;

        if(bitcoinWithdrawalAddress != ''){
            switch(network){
                case 'bitcoin':
                    valid=WAValidator.validate(bitcoinWithdrawalAddress, 'btc');
                break;

                case 'ethereum':
                case 'bnb_smart_chain':
                    valid=WAValidator.validate(bitcoinWithdrawalAddress, 'eth'); //Both ERC20 and BEP20 addresses follow the Ethereum address format(ETH), which consists of a 40-character hexadecimal string. The address usually starts with "0x" followed by a combination of numbers (0-9) and letters (A-F).
                break;

                case 'litecoin':
                    valid=WAValidator.validate(bitcoinWithdrawalAddress, 'ltc');
                break;

                case 'bitcoin_cash':
                    valid=WAValidator.validate(bitcoinWithdrawalAddress, 'bch');
                break;

                case 'tron':
                    valid=WAValidator.validate(bitcoinWithdrawalAddress, 'trx');
                break;
            }
        }
        return valid;
    }

    _validateInput(input){
        let method=input.root_.dataset.validationMethod;
        if(method===undefined){
            return true;
        }

        let valid=false;
        switch (method) {
            case "btc-address":
                /*
                    P2PKH which begin with the number 1, eg: 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2.
                    P2SH type starting with the number 3, eg: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy.
                    Bech32 type starting with bc1, eg: bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq
                    WAValidator Source: https://www.npmjs.com/package/multicoin-address-validator
                */
                if(
                    WAValidator.validate(input.value, 'btc') ||
                    WAValidator.validate(input.value, 'eth') //For validating the BNP Smart Chain addresses(BEP20). Both ERC20 and BEP20 addresses follow the Ethereum address format(ETH)
                ){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "eth-address":
                /*
                    A regular expression for matching Ethereum addresses must check for a leading 0x followed by a random string of 40 hexadecimal characters (lowercase a-f, uppercase A-F, and numbers 0-9).
                    These expressions are not case sensitive, although a capitalized checksum version exists that refers to the same account but provides an added layer of security.
                */
                if(WAValidator.validate(input.value, 'eth')){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "ltc-address":
                if(
                    WAValidator.validate(input.value, 'ltc') ||
                    WAValidator.validate(input.value, 'eth') //For validating the BNP Smart Chain addresses(BEP20). Both ERC20 and BEP20 addresses follow the Ethereum address format(ETH)
                ){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "bch-address":
                if(
                    WAValidator.validate(input.value, 'bch') ||
                    WAValidator.validate(input.value, 'eth') //For validating the BNP Smart Chain addresses(BEP20). Both ERC20 and BEP20 addresses follow the Ethereum address format(ETH)
                ){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "usdt-address":
                // USDT can be validated either as a BTC or ETH address, depending on the network type.
                // Basically it goes like that: If cryptocurrency is USDT and chain type is ERC20, validate the address against ETH address format.
                // If cryptocurrency is USDT and wallet type is OMNI, validate the address against BTC address format
                if(
                    WAValidator.validate(input.value, 'usdt') == true ||
                    WAValidator.validate(input.value, 'trx') == true //For validating the USDT:TRC20 addresses(Tron/trx)
                ){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "usdc-address":
                if(
                    WAValidator.validate(input.value, 'usdc') == true ||
                    WAValidator.validate(input.value, 'trx') == true //For validating the USDC:TRC20 addresses(Tron/trx)
                ){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "dai-address":
                if(WAValidator.validate(input.value, 'dai')){
                    valid=true;
                    this._triggerValidateAddressNetwork();
                }
                break;

            case "network":
                valid = input.value != null && input.value != '';

                if(valid === true && this.isBtcAddressExist === false){
                    let bitcoinWithdrawalAddress = document.querySelector("input[name=bitcoinWithdrawalAddress]").value;
                    console.log(input.value);
                    console.log(bitcoinWithdrawalAddress);
                    valid = this._validateAddressNetwork(input.value, bitcoinWithdrawalAddress);
                }
                break;

            default:
                valid=false;
        }

        this._setInputState(input,valid);
        return valid;
    }
}