class LazyImageLoaderClass{
    constructor() {
        this.images=[];
        this.imageObserver=null;
        this._initObserver();
    }

    updateLazyImagesList(){
        if(this.images.length>0 && this.imageObserver){
            this.images.forEach(image=>{
                this.imageObserver.unobserve(image);
            })
        }

        this.images= document.querySelectorAll(".lazy");

        if(this.imageObserver){
            this.images.forEach(image=>{
                this.imageObserver.observe(image);
            })
        }else{
            this.images.forEach(image=>{
                image.src = image.dataset.src;
                // image.classList.remove("lazy");
            })
        }
    }

    _initObserver(){
        if("IntersectionObserver" in window){
            let imageObserver=new IntersectionObserver((entries, observer)=> {
                entries.forEach(entry=> {
                        if (entry.isIntersecting) {
                            this._loadObservedImage(entry.target);
                        }
                    }
                );
            },{
                rootMargin: "0px 200px 200px 0px",
                threshold: 0.1
            });
            this.imageObserver=imageObserver;
        }

    }


    _loadObservedImage(image){
        image.src = image.dataset.src;
        // image.classList.remove("lazy");
        this.imageObserver.unobserve(image);
    }

}



export const LazyImageLoader = new LazyImageLoaderClass();