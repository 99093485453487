import {SideViewBaseClass} from "../SideViewBaseClass";
import {Sidebar} from "../Sidebar";

export class SideviewSidebarMenu extends SideViewBaseClass{
    constructor() {
        super("side-view-sidebar",SideViewBaseClass.TYPE.NORMAL);

    }

    renderViewContent() {
        this.contentElement=document.createElement('div');
        this.contentElement.id="sidebar";
        this.contentElement.classList.add("open");
        this.element.appendChild(this.contentElement);
        let sidebar=new Sidebar();
        sidebar.collapseOverrideFunction=()=>{this.close()};
    }
}