import {render} from "lit-html";
import {FormHelpers} from "../../../util/FormHelpers";
import {
    TemplateWithdrawalAccountActivationView,
    TemplateWithdrawalContentView,
    TemplateWithdrawalVerificationView,
    TemplateWithdrawalIframePaymentSlideOver
} from "../../../../templates/template-items/side-view/TemplateWithdrawalContentView";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {BANK} from "../../../core/Bank";
import {Popup} from "../../Popup";
import {Helpers} from "../../../util/Helpers";
import {Template_IBV4_DirectBanking} from "../../../../templates/template-items/side-view/bank-paymetns-forms/Template_IBV4_DirectBanking";
import {MDCTextField} from "@material/textfield/component";
import {MDCSelect} from "@material/select/component";
import {SlideOverViewStatus} from "../../SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "../../SideViewManager";
import {SideViewUser} from "../SideViewUser";
import {SideViewMenu} from "../../SideViewMenu";
import {USER} from "../../../core/User";
import {Template_BankTransfer_Withdrawal} from "../../../../templates/template-items/side-view/bank-paymetns-forms/Template_BankTransfer_Withdrawal";
import {BankFormDirectBankTransfer} from "./bankForms/BankFormDirectBankTransfer";
import {BankFormIBV4DirectBanking} from "./bankForms/BankFormIBV4DirectBanking";
import {PayIDAdditionalForm} from "./bankForms/PayIDAdditionalForm";
import {BankFormInpayWithdrawal} from "./bankForms/BankFormInpayWithdrawal";
import {SlideOverView} from "../../SlideOverView";
import {CAPP_API} from "../../../core/CAPPApi";

export class SideViewSubWithdrawal extends SideViewSubBaseClass{
    constructor(container) {
        super(container);

    }

    _init() {
        this.userData=USER.data
        this.BANK=BANK;
        this.country=USER.data.CountryName;
        this.currencyISO=USER.data.CurrencyISO;
        this.balance=`${USER.data.CurrencySymbol}${Helpers.formatCurrencyCents(USER.data.CurrentRealBalance,true)}`;
        this.withdrawalData=null;
        this.pendingWithdrawals=null;
        this.loadingWithdrawalData=true;
        this.loadingPendingWithdrawalsData=true;
        this.loadingUserData = false;
        this.verificationEmailLoading=false;
        console.log("BANK",BANK);

        this.paymentFormTemplate=null;

        this.presetValues=[];
        this.selectedPreset=null;

        this.withdrawalForm=null;

        this.getWithdrawalData();
        this.loadPendingWithdrawals();
        super._init();
    }

    getPresetValues(){
        let presetValues;

        if(BANK.withdrawalSettings.presetAmounts && BANK.withdrawalSettings.presetAmounts.length > 0){
            presetValues = BANK.withdrawalSettings.presetAmounts;
        }
        else{
            switch (this.currencyISO){
                case 'INR':
                    presetValues=[500, 1000, 2000, 3000, 5000];
                    break;
                default:
                    let clientSegmentID = USER.data.ClientSegment ? USER.data.ClientSegment.ClientSegmentID : 0;

                    switch (clientSegmentID) {
                        case 1:
                        case 2:
                        case 4:
                            presetValues=[100,250,500,1000,2500];
                            break;
                        case 3:
                        case 32:
                            presetValues=[500,1000,2000,4000,8000];
                            break;
                        default:
                            presetValues=[100,250,500,1000,2500];
                    }
            }
        }

        return presetValues;
    }

    renderView(){
        this.loadingUserData = true;
        USER.updateUserData()
        .then(() => {
            console.log(USER.data, 'USER.data after updateUserData');
            this.loadingUserData = false;
            //this.updateView(true);
            if(this.PIQWithdrawals){
                console.log(USER.data, 'USER.data before updateUserData');
              
                this.updateView();
              
            }
            else{
                this.updateView(true);
            }
        });
    }

    _setupEventListeners(){
        this.withdrawalAmountInputMDC.foundation_.adapter_.registerInputInteractionHandler("keyup", (e)=>{
            this.selectedPreset=null;
            let val=parseFloat(this.withdrawalAmountInputMDC.value);
            BANK.setWithdrawalAmount(isNaN(val) ? 0 : val);
            this.updateView();

        })

        this.withdrawalAmountInputMDC.foundation_.adapter_.registerInputInteractionHandler("change", (e)=>{
            let val=parseFloat(this.withdrawalAmountInputMDC.value);
            BANK.setWithdrawalAmount(isNaN(val) ? 0 : val);

            this.updateView();
        })



    }

    onFormSubmit(e){
        //prevent form submit
        e.preventDefault();
    }

    updateView(initializeForm){
        //todo probably move this to bank class and dont load withdrawal data at all
        if(USER.data.AccountActivated != true && this.loadingUserData === false){
            render(TemplateWithdrawalAccountActivationView(this),this.element,{eventContext:this});
        }else if(USER.data.WithdrawalRequireVerification && this.loadingUserData === false){
            render(TemplateWithdrawalVerificationView(this),this.element,{eventContext:this});
        }else{
            render(TemplateWithdrawalContentView(this),this.element,{eventContext:this});
        }

        if(this.withdrawalForm){
            this.withdrawalForm.disabled=BANK.withdrawalSettings.selectedMethod.limitStatus!=0;
        }

        if(initializeForm){
            FormHelpers.initializeFormComponents(this.element);
            let inp=this.element.querySelector('#withdrawalAmountMDC');
            let dynamicWithdrawalLimitText=this.element.querySelector('#dynamicWithdrawalLimitText');

            if(inp){
                this.withdrawalAmountInputMDC=inp.MDCTextField;
                this._setupEventListeners();
            }

            if(dynamicWithdrawalLimitText){
                this.dynamicWithdrawalLimitText=dynamicWithdrawalLimitText;
            }
        }
    }

    getWithdrawalData(){
        BANK.getWithdrawalData()
            .then(data=>{
                this.loadingWithdrawalData=false;
                if(data.error){
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else{
                    this.withdrawalData=data;
                    this.presetValues=this.getPresetValues();
                }

                console.log("this.withdrawalData",this.withdrawalData);

                this.updateView();
            })
    }

    loadPendingWithdrawals(){
        BANK.getPendingWithdrawals()
            .then(data=>{
                this.loadingPendingWithdrawalsData=false;
                if(data.error){
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else{
                    this.pendingWithdrawals=data;
                }

                this.updateView();

            })
    }

    onVerificationButtonClick(){
        new SideViewUser(SideViewMenu.ITEM_TYPES.VERIFICATION);
    }

    onAccountActivationEmailButtonClick(){
        console.log("Call api to resend activation email");
        this.verificationEmailLoading=true;
        this.updateView();
        USER.resendActivationEmail()
            .then(data=>{
                this.verificationEmailLoading=false;
                this.updateView();
                if(data.error){
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else{
                    new Popup(
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-ResendActivationEmailAlert-Success-Title'),
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PendingWithdrawalsSection-ResendActivationEmailAlert-Success-Message'),
                        [APP_STRINGS.OK]);
                }
            })
    }

    onPresetClick(e){
        this.selectedPreset=parseInt(e.currentTarget.dataset.presetId);
        BANK.setWithdrawalAmount(parseFloat(this.presetValues[this.selectedPreset]));
        this.withdrawalAmountInputMDC.value=this.presetValues[this.selectedPreset];
        this.updateView();

    }

    onWithdrawalMethodSelect(e){
        BANK.setWithdrawalMethod(e.currentTarget.dataset.paymentMethod);
        this.dynamicWithdrawalLimitText.innerHTML=e.currentTarget.dataset.withdrawalLimitText;
        this._updateOnWithdrawalMethodChange();
    }

    onLimitControlClick(e){
        e.preventDefault();
        BANK.setWithdrawalAmountToRange(e.currentTarget.dataset.paymentMethod);
        this.selectedPreset=null;
        this.withdrawalAmountInputMDC.value=BANK.withdrawalSettings.withdrawalAmount;
        this._updateOnWithdrawalMethodChange();
    }

    onCancelPendingWithdrawalClick(e){
        let transactionKey=e.currentTarget.dataset.transactionKey;
        let method=e.currentTarget.dataset.method;
        new Popup(APP_STRINGS.getTitleById("2223022"),APP_STRINGS.getStringById("2223022"),[APP_STRINGS.YES,APP_STRINGS.NO],btnId=>{
            if(btnId==1){return;}
            BANK.cancelPendngWithdrawal(transactionKey, method)
                .then(data=>{
                    if(data.error){
                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                    }
                    this.updateView();
                })
            this.updateView();
        });


    }

    _updateOnWithdrawalMethodChange(){
      
        if( this.withdrawalForm){
            this.withdrawalForm.destroy();
            this.withdrawalForm=null;
        }
      /*   if(BANK.withdrawalSettings.selectedMethod==null && this.withdrawalForm){
            this.withdrawalForm.destroy();
            this.withdrawalForm=null;
            return;
        }else if(BANK.withdrawalSettings.selectedMethod!=null && this.withdrawalForm) {
            this.withdrawalForm.destroy();
            this.withdrawalForm=null;
        }
 */
        let formContainer=this.element.querySelector("#payment-form-container");
        let existingAccounts;
        if(BANK.existingBankAccounts && BANK.existingBankAccounts[BANK.withdrawalSettings.selectedMethod.PaymentMethodName]){
            existingAccounts=BANK.existingBankAccounts[BANK.withdrawalSettings.selectedMethod.PaymentMethodName];
        }else{
            existingAccounts=[];
        }

      
        switch (BANK.withdrawalSettings.selectedMethod.PaymentMethodName) {
            case "IBV4_DirectBanking":
                this.withdrawalForm = new BankFormIBV4DirectBanking(formContainer,this.country, this.withdrawalData.banks, existingAccounts,(formData)=>{
                    console.log("IBV4_DirectBanking");
                    BANK.withdrawalSettings.formData=formData;
                    this._startWithdrawal();
                })
                break;
            case "BankTransfer":
                this.withdrawalForm = new BankFormDirectBankTransfer(formContainer,this.country, existingAccounts,(formData)=>{
                    console.log("Direct bank trensfer callback");
                    BANK.withdrawalSettings.formData=formData;
                    this._startWithdrawal();
                })
                break;
            case "CryptoPay":
            case 'CryptoPay_BTC':
            case 'CryptoPay_ETH':
            case 'CryptoPay_LTC':
            case 'CryptoPay_BCH':
            case 'CryptoPay_USDT':
            case 'CryptoPay_USDC':
            case 'CryptoPay_DAI':
            case "Interac_Withdrawal":
                if(this.withdrawalForm){
                    this.withdrawalForm.destroy();
                    this.withdrawalForm=null;
                }
                console.log(BANK.withdrawalSettings.selectedMethod.PaymentMethodName);
                console.log(BANK.withdrawalSettings.selectedMethod.PaymentAccountID);
                BANK.withdrawalSettings.formData={};
                break;
            case "PSE_PID_PAY_ID_WITHDRAW":
              
                if(BANK.withdrawalSettings.selectedMethod.PaymentAccounts.TransactionWithdrawOption){
                 
                    this.accountReference = BANK.withdrawalSettings.selectedMethod.PaymentAccounts.TransactionWithdrawOption[0].AccountReference;
                   // console.log('BANK',BANK.withdrawalSettings.selectedMethod.PaymentAccounts.TransactionWithdrawOption[0]);
                  
                }
              
                this.withdrawalForm = new PayIDAdditionalForm(formContainer, this.accountReference,(formData)=>{
                    console.log("Direct callback");
                    BANK.withdrawalSettings.formData = formData;
                    this._startWithdrawal();
                })
                break;
            case "PSE_INP_BANK_TRANSFER_WITHDRAW":
                this.withdrawalForm = new BankFormInpayWithdrawal(formContainer,BANK.withdrawalSettings.selectedMethod, existingAccounts,this.country, (formData)=>{
                    this._startINPAYBankTransferWithdrawal(formData);
                })
                break;
            default:
                this.withdrawalForm=null;
                BANK.withdrawalSettings.formData={};
        }

        this.updateView();

    }


    onWithdrawButtonClick(e){
        console.log(BANK.withdrawalSettings.selectedMethod.isMaxPendingWithdrawals, 'isMaxPendingWithdrawals');

        if(BANK.withdrawalSettings.selectedMethod.isMaxPendingWithdrawals === true){
            new Popup(APP_STRINGS.getTitleById("2223095"),APP_STRINGS.getStringById("2223095"),[APP_STRINGS.OK]);
        }
        else{
         
            this._startWithdrawal();
            
        }
    }

    _startWithdrawal(){
        if(BANK.withdrawalSettings.selectedMethod.cashier =='PIQ'){
            this._startPIQWithdrawal();
        }else{
            this._startStandardWithdrawal();
        }
    }

    _startINPAYBankTransferWithdrawal(formData) {
        var playerUpdateFields = ['postcode', 'address', 'city'];
        var playerUpdateObj = {};

        this.processingWithdrawal=true;
        BANK.withdrawalSettings.formData=formData;

        // populate player update fields and value
        playerUpdateFields.forEach(function(key) {
            if (formData[key]) {
                playerUpdateObj[key] = formData[key];
            }
        });

        console.log('inpay start', formData, playerUpdateObj);

        //if there is player fields need to be updated, else we direct to withdrawal
        if (Object.keys(playerUpdateObj).length > 0) {
            playerUpdateObj.sessionKey = USER.sessionKey;

            CAPP_API.updatePlayerInfo(playerUpdateObj)
                .then(data=>{
                    console.log("inpay update player data",data);
                    if (data) {
                        USER.updateUserData();
                        this._startWithdrawal();
                    }
                })
        }
        else {
            this._startWithdrawal();
        }
    }


    _startStandardWithdrawal(){
        this.processingWithdrawal=true;
        this.updateView();
        BANK.withdraw()
            .then(data=>{
                this.processingWithdrawal=false;
                this.updateView();

                if(data.error){
                    if(this.withdrawalForm){
                        this.withdrawalForm.loading=false;
                    }
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else if(data.success){
                    new SlideOverViewStatus(this.container,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalSuccessView-Title'),
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalSuccessView-Text'),
                        [APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalSuccessView-ButtonLabel-Continue')],
                        (btnId)=>{
                            SIDE_VIEW_MANAGER.closeAnySideview();
                    });
                }else{
                    new SlideOverViewStatus(this.container,SlideOverViewStatus.STATUS_VIEW_TYPE.FAILED,
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalFailedView-Title'),
                        APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalFailedView-Text'),
                        [APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-WithdrawalFailedView-ButtonLabel-Continue')],
                        (btnId)=>{
                            SIDE_VIEW_MANAGER.closeAnySideview();
                    });
                }


            })
    }


    _startPIQWithdrawal() {
        this.loadingIframeURL=true;
        this.paymentIframeURL=null;
        this.processingWithdrawal = true;
        this.paymentSlideoverTitle=BANK.withdrawalSettings.selectedMethod.displayName;

        this.updateView();

        BANK.withdrawPIQ().then(data=>{
            console.log('PIQ withdrawal data',data);
            if(data.error){

                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);

            }else{
                this.loadingIframeURL=false;
                this.paymentIframeURL=data;
                this.iframePaymentSlideOver=new SlideOverView(this.container.parentElement,TemplateWithdrawalIframePaymentSlideOver,this);

                //this.iframePaymentSlideOver.update();
            }
            this.processingWithdrawal = false;
            this.updateView();

        })

    }


    onSlideOverPaymentIframeBackButtonClick(){
        this.iframePaymentSlideOver.close();
        this.iframePaymentSlideOver=null;
        USER.updatePlayerBalance();
    }


}