import {html} from 'lit-html';
import {USER} from "../../../js/core/User";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateForgotPasswordSideView = (el) => html`
<div class="view-heading layout">
    <div class="close-img-container">
			<img @click="${el.close}" class="close-img" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
		</div>
    <div class="main-side-mobile-heading">
            <span @click=${el.onMenuItemClick} data-action="search" ><img @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/search-icon.svg"></span>
            <span @click=${el.onMenuItemClick} data-action="help"><img data-action="help"  @click=${el.onMenuItemClick} src="${CDN_ASSETS_URL}/contact-us-icon.svg"></span>
            ${USER.isLoggedIn ?  html`<span @click=${el.onMenuItemClick} data-action="logout" class="yellow-back">LOG OUT</span>` :
    html `<span @click=${el.onForgottenPasswordClick} class="yellow-back">RESET PASSWORD</span>`}
            <span><img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/close.svg"></span>
        </div>
    <span class="title">${APP_STRINGS.getWPMLString('SideViewForgotPassword-Title')}</span>
</div>
<div class="content">
    <form id="forgot-password-form" @submit="${el.onSubmitForgotPassword}" class="${el.loading ? 'disabled' : ''}">
        <div class="form-element-2">
            <p class="Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewForgotPassword-Instructions-Text')}</p>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="emailAddress">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewForgotPassword-InputField-Email')}
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>

            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" id="my-helper-id" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewForgotPassword-InputField-Validation-Email')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <button class="primary-button ${el.loading ? 'loading' : ''}">
                <span>${APP_STRINGS.getWPMLString('SideViewForgotPassword-ButtonLabel-ResetPassword')}</span>
                <preloader-element></preloader-element>
            </button>
        </div>
    </form>
</div>
`