import {render} from "lit-html";
import {TemplatePreloaderOverlayPopup} from "../../templates/template-items/TemplatePreloaderOverlayPopup";

export class PreloaderOverlayPopup{
    constructor() {
        this._init();
    }

    _init(){
       
    }

    createPreloaderPopup(){
        
        this.container=document.getElementsByTagName("BODY")[0];
        this.overlayPopup=document.createElement("div");
        this.overlayPopup.classList.add("preloader-overlay-popup");
        this.container.appendChild(this.overlayPopup);
        this.template = TemplatePreloaderOverlayPopup(this);
        this.update();
        
    }

    update(){
        render(this.template,this.overlayPopup,{eventContext:this});
    }

    removePreloaderPopup(){
   
        this.overlayPopup.remove();
    }


}