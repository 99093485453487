import {render} from "lit-html";
import {TemplateFTInbox} from "../../templates/template-items/TemplateFTInbox";
import {FAST_TRACK} from "../core/FastTrack";

export class FTInbox{
    constructor(data) {
        this.container=document.getElementsByClassName("inbox-content");

        if (this.container.length > 0) {
            this.container=this.container[0];
            this.search_container=document.getElementsByClassName("inbox-search-container")[0];
            this.no_content_container=document.getElementsByClassName("no-content")[0];
            this.overlayPopup=document.createElement("div");
            // this.overlayPopup.classList.add("fasttrack-overlay-popup");
            // this.container.appendChild(this.overlayPopup);
            this.data = data;
            this.template = TemplateFTInbox(this);

            this._init();
        }
    }

    _init(){
        this._updateView();
        this.no_content_container.style.display = 'none';
        this.searchInput = this.search_container.querySelector(".ft-search-input");
        this.searchButtonDesktop = this.search_container.querySelector(".ft-search-button");
        this.searchButtonMobile = this.search_container.querySelector(".ft-search-button-mobile");


        var _self = this;
        this.searchInput.addEventListener("keyup", function(e) {
            if(e.keyCode == 13) {
                FAST_TRACK._searchText(e.target.value);
            }
        }, false);

        this.searchButtonDesktop.addEventListener("click", function(e) {
            FAST_TRACK._searchText(_self.searchInput.value);
        }, false);

        this.searchButtonMobile.addEventListener("click", function(e) {
            FAST_TRACK._searchText(_self.searchInput.value);
        }, false);

    }

    _updateView(){
        render(this.template,this.container,{eventContext:this});
    }

}