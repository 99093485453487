import {html} from 'lit-html';

export const Template_Inpay_Withdrawal= el =>html`
    <form class="payment-method-form ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        ${el.existingAccounts ? html`
             <div class="form-element-2" id="existing accounts">
             
                <div class="mdc-select" data-field="existingAccount">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>
        
                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-ExistingAccounts')}
                        </span>
        
                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            <li class="mdc-list-item" data-value="new-account" role="option">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-ExistingAccounts-ItemLabel-NewAccount')}
                            </li>
                            ${el.existingAccounts.map(account=>html`
                            <li class="mdc-list-item" data-value="${account.accountId}" role="option">${account.accountDisplay}</li>
                            `)}
                        </ul>
                    </div>
                </div>
            </div>
        ` : ''}
        
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="accountHolderName">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" aria-controls="account-helper-text"  name="accountHolderName">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-NameOnAccount')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true" id="account-helper-text">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-NameOnAccount')}
                </div>
            </div>
        </div>
        
        ${el.isBankNameRequired && el.banksList? html`
            <div class="form-element-2" id="bankLists">

                <div class="mdc-select" data-validation-method="required" data-field="bankCode">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>

                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                               ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BankName')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            ${el.banksList.map(bank=>html`
                                <li class="mdc-list-item" data-value="${bank.bankCode}" role="option">${bank.bankName}</li>
                            `)}
                        </ul>
                    </div>
                </div>
            </div>
        ` : ''}
       
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="accountNumber">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="accountNumber">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-AccountNumberOrIBAN')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-AccountNumberOrIBAN')}
                </div>
            </div>
        </div>
        
        ${el.isBankClearingIdRequired ? html`
            <div class="form-element-2">
                <label class="mdc-text-field" data-validation-method="required" data-field="bankClearingID">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankClearingID">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BSDOrSortCode')}
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BSDOrSortCode')}
                    </div>
                </div>
            </div>
        ` : ''}

        ${el.isSwiftCodeRequired ? html`
            <div class="form-element-2">
                <label class="mdc-text-field" data-validation-method="required" data-field="bankSwiftCode">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankSwiftCode">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BICorSWIFT')}
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BICorSWIFT')}
                    </div>
                </div>
            </div>
        ` : ''}

        ${el.address === null || el.address === '' ? html`
            <div class="form-element-2">
                <label class="mdc-text-field"  data-validation-method="required" data-field="address">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="address">
                    <span class="mdc-floating-label" id="my-label-id">
                        Address
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true">
                        Please enter address
                    </div>
                </div>
            </div>
        ` : ''}
        
        ${el.city === null || el.city === '' ? html`
            <div class="form-element-2">
                <label class="mdc-text-field" data-validation-method="required" data-field="city">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="city">
                    <span class="mdc-floating-label" id="my-label-id">
                        City
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true">
                        Please enter city
                    </div>
                </div>
            </div>
        ` : ''}

        ${el.postcode === null || el.postcode === '' ? html`
            <div class="form-element-2">
                <label class="mdc-text-field" data-validation-method="required" data-field="postcode">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="postcode">
                    <span class="mdc-floating-label" id="my-label-id">
                        Postcode
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true">
                        Please enter postcode
                    </div>
                </div>
            </div>
        ` : ''}
        
       
        
        <div class="form-element-2">
            <button type="button" class="primary-button ${el._loading ? 'loading' : ''}" @click=${el._onWithdrawButtonClick} >
                <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-ButtonLabel-Withdraw')}</span>
                <preloader-element></preloader-element>
            </button>
        </div>
    </form>
    
        
    
`;