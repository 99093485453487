import {html} from 'lit-html';

export const Template_CryptoPay_Deposit_AdditionlForm= el=>html`
    
    <form class="payment-method-form ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        ${el.isBtcAddressExist ? ``: html`
        <div class="crypto-withdrawal-address">
            <div class="title-container">
                <span class="title Heading-4">${getCryptoCurrencyLabel(el.currencyISO)} Withdrawal Address</span>
            </div>
            <div class="additional-form-info">
                <p>Please enter your ${getCryptoCurrencyLabel(el.currencyISO)} address below for us to send you future withdrawals. We require this now for your added securiy.</p>
                <p>Note that you will only be able to withdraw funds to this ${getCryptoCurrencyLabel(el.currencyISO)} address.</p>
                <p>If you wish to change this in future, please contact Customer support for assistance</p>
            </div>
            <div class="form-element-2">
                <label class="mdc-text-field" data-validation-method="${getValidationMethod(el.currencyISO)}" data-field="bitcoinWithdrawalAddress">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" value="" name="bitcoinWithdrawalAddress" spellcheck="false" >
                    <span class="mdc-floating-label" id="my-label-id">
                        <!--${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-BankCountry')}-->
                        ${getCryptoCurrencyLabel(el.currencyISO)} address
                    </span>
                    <div class="mdc-line-ripple"></div>
                </label>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true" id="account-helper-text">
                        <!--${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldValidation-NameOnAccount')}-->
                        Please enter a valid ${getCryptoCurrencyLabel(el.currencyISO)} address
                    </div>
                </div>
            </div>
        </div>
        `}
        ${el.networks && el.networks.length>0 ? html`
        <div class="crypto-deposit-network">
            <div class="title-container">
                <span class="title Heading-4">${formatString(APP_STRINGS.getWPMLString('SideViewBank-Deposit-CryptoDepositNetwork-Form-Title'), el.currencyISO)}</span>
            </div>
            <div class="additional-form-info">
                <p>${formatString(APP_STRINGS.getWPMLString('SideViewBank-Deposit-CryptoDepositNetwork-Form-Text'), el.currencyISO)}</p>
            </div>
            <div class="form-element-2" id="depositCryptoNetwork">
                <div class="mdc-select" data-validation-method="network" data-field="depositCryptoNetwork">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>
                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                                ${formatString(APP_STRINGS.getWPMLString('SideViewBank-Deposit-CryptoDepositNetwork-FormField-Label'), el.currencyISO)}
                        </span>
                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            <!--<li class="mdc-list-item mdc-list-item--selected" aria-selected="true" data-value="" role="option"></li>-->
                            ${el.networks.map(network=>html`
                            <li class="mdc-list-item" data-value="${network.network}" role="option">${network.name}</li>
                            `)}
                        </ul>
                    </div>
                </div>
                <!--div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" aria-hidden="true" id="depositCryptoNetwork-helper-text">
                        ${formatString(APP_STRINGS.getWPMLString('SideViewBank-Deposit-CryptoDepositNetwork-Invalid-Msg'), el.currencyISO)}
                    </div>
                </div-->
            </div>
        </div>
        `:``}
    </form>
`;

function getCryptoCurrencyLabel(currencyISO){
    switch(currencyISO){
        case 'BTC':
            return html`Bitcoin`
            break;
        case 'ETH':
            return html`Ethereum`
            break;
        case 'LTC':
            return html`Litecoin`
            break;
        case 'BCH':
            return html`Bitcoin Cash`
            break;
        case 'USDT':
            return html`Tether`
            break;
        case 'USDC':
            return html`USD Coin`
            break;
        case 'DAI':
            return html`DAI`
            break;
    }
}

function getValidationMethod(currencyISO){

    if(currencyISO != ''){
        currencyISO = currencyISO.toLowerCase();
    }
    else{
        currencyISO = 'btc';
    }
    return currencyISO + '-address';
}

function formatString(content, currencyISO){
    if(content){
        content = content.replace(/{crypto}/g, currencyISO);
    }
    return content;
}