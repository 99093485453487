import {render} from "lit-html";
import {FormHelpers} from "../../../../util/FormHelpers";
export class DepositAdditionalFormBase {
    constructor(container, callback) {
        this.container=container;
        this.callback=callback;
        this.form=null;
        this.formInputs=null;
    }

    _init(){
        this.element=document.createElement('div');
        this.container.appendChild(this.element);
        this._render();
        this._initializeFormElements();
    }

    _render(){
        let template=this._getFormTemplate();
        render(template(this),this.element,{eventContext:this});
    }

    update(){
        this._render();
    }

    _getFormTemplate(){
        throw new Error("Implement this method in a subclass");
    }
    _validateInput(input){
        throw new Error("Implement this method in a subclass");
    }

    _initializeFormElements(){
        this.form=this.container.querySelector(".payment-method-form");
        FormHelpers.initializeFormComponents(this.form);
        let elements=this.form.querySelectorAll(".mdc-text-field,.mdc-select");
        this.formInputs=[];
        elements.forEach(element=>{
            let input;
            if(element.classList.contains("mdc-text-field")){
                input=element.MDCTextField;
                input.listen("change",e=>{this._validateInput(input)});
            }else if(element.classList.contains("mdc-select")){
                input=element.MDCSelect;
                input.foundation_.handleChange=()=>{this._validateInput(input)}
            }
            input.useNativeValidation=false;

            this.formInputs.push(input);
        })
    }

    _setInputState(input, valid){
        if(valid){
            input.valid=true;
            if(input.helperText_){
                input.helperText_.foundation_.setPersistent(false);
            }
        }else{
            input.valid=false;
            if(input.helperText_){
                input.helperText_.foundation_.setPersistent(true);
            }
        }
    }

    _validateForm(){
        let valid=true;
        this.formInputs.forEach(input=>{
            valid= this._validateInput(input) && valid;
        })
        return valid;
    }

    processFormData(){
        let valid=this._validateForm();

        if(valid){
            let formData={};
            this.formInputs.forEach(input=>{
                formData[input.root_.dataset.field]=input.value;
            })
            return {
                valid:true,
                formData:formData
            }
        }else{
            return {
                valid:false
            }
        }
    }

    destroy() {
        this.container.removeChild(this.element);
        delete this;
    }


}