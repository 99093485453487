import {html} from "lit-html";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateGameInfoSideView= el=>html`
<div class="view-heading">
    <div class="item-left">
        <span class="title">${el.game.gameTitle}</span>
    </div>
    <div class="item-right">
        <img class="close-img" src="${CDN_ASSETS_URL}/close.svg" @click="${el.close}">
    </div>
</div>
<div class="content game-info-content">
    <div class="favourite">
        <span>${APP_STRINGS.getWPMLString('SideViewGameInfo-FavouriteSection-Title')}</span>
        <img class="favourite-btn" @click=${el.onFavouriteButtonClick} src="${el.game.isFavourite==1 ? `${CDN_ASSETS_URL}/favourite-filled.svg` : `${CDN_ASSETS_URL}/favourite.svg`}">
    </div>
    <div>
        <span>${APP_STRINGS.getWPMLString('SideViewGameInfo-GameCategoriesSection-Title')}</span>
        <ul class="categories-container">
            ${unsafeHTML(el.game.categoryItemsHTML)}
        </ul>
    </div>
    <div class="game-info-items">
        ${unsafeHTML(el.game.infoItemsHTML)}    
    </div>
    ${unsafeHTML(el.game.gameDescriptionHTML)}
    
</div>
`;