import {render} from "lit-html";
import {GameSearch} from "../../core/GameSearch";
import { USER } from "@/js/core/User";
import { TemplateFavouriteGameSection } from "@/templates/template-items/sections/TemplateFavouriteGameSection";


export class FavouriteGame {
    constructor() {        
        this._init();
    }

    _init(){
        this.favouriteGameData = [];
        this.gameSearch = new GameSearch()
        if(USER.isLoggedIn){
            this.getFavouriteGame()
        }
        this.setupEventListener()
        console.log('hello')
    }

    updateView() {
        render(TemplateFavouriteGameSection(this),$('#favourite-game-section')[0],{eventContext:this});
        $('#favourite-game-section').show()
    }

    setupEventListener(){
        USER.on("onLoginStateChange",()=>{
            {
                if(USER.isLoggedIn){
                    this.getFavouriteGame()
                }else{
                    $('#favourite-game-section').hide()
                }
            }
        })
    }

    getFavouriteGame(){
        if((typeof $('#favourite-game-section')) != 'undefined'){
            this.gameSearch.getGameDetailLite($('#favourite-game-section').attr('data-game-category'),'','', '','','', 'MostPopular', '', 0)
            .then(data=>{
                this.favouriteGameData = data
                if(this.favouriteGameData.gameCount > 0){
                    this.updateView()
                }else{
                    $('#favourite-game-section').hide()
                }
            })
        }
        else{
            console.log('#favourite-game-section is undefined');    
        }
    }
}