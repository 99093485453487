export const InitializeScrollToTopController=()=>{
    console.log('ScrollToTopController');

    let contentContainer=document.querySelector("#content-container");
    if(contentContainer==null){return;}

    let img=document.createElement('img');
    img.src=window.CDN_ASSETS_URL+"/up.svg";

    let sctBtn=document.createElement('button');
    sctBtn.classList.add('scroll-to-top');
    sctBtn.appendChild(img);
    document.body.appendChild(sctBtn);


    contentContainer.addEventListener('scroll',e=>{
        if(contentContainer.scrollTop>400){
            sctBtn.classList.add("active");
        }else{
            sctBtn.classList.remove("active");
        }
    })

    sctBtn.addEventListener('click',()=>{
        contentContainer.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    })
}