
import {HorizontalMenu} from "./HorizontalMenu";
import {SideViewRegister} from "./sideViews/SideViewRegister";
import {SideViewRegisterShort} from "./sideViews/SideViewRegisterShort";

export class  HorizontalMenuExtended extends HorizontalMenu{
    constructor() {
        super();
        if(this.container){
            this.showSignUpButton();
        }
    }

    showSignUpButton() {
        let SignUpElement = document.querySelector('li[data-action="signUp"]');

        if(SignUpElement){
            if((typeof window.isByPassVIPInvitation) !== 'undefined'){
                SignUpElement.style.display = isByPassVIPInvitation?'block':'none';
            }
        }
    }

    launchRegistration(){
        if(window.isSimplifiedSignUpForm){
            new SideViewRegisterShort();
        }else{
            new SideViewRegister();
        }

    }

}