import {html} from 'lit-html';
import {Helpers} from "@/js/util/Helpers";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateSimplifiedUserProfile = (el) => html`
<div id="user-profile">
    <div class="content">
        <ul class="center-items">
            <li class="profile-info-container">
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-ProfileSection-Title')}</h4>
                <div class="profile-item profile-info">
                    <div class="profile-info-top">
                        <div class="avatar">
                            ${(el.userData.VIPImage) ? html`<img src="${el.userData.VIPImage}"/>` : ``}
                        </div>
                        <span class="username"><img src="${CDN_ASSETS_URL}/loyalty.svg">${el.userData.VIPLevelName}</span>
                    </div>
                </div>
            </li>
            <li>
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-UserInfoSection-Title')}</h4>
    
                <div class="profile-item player-info-list">
                    <div class="player-info">
                        <span class="Paragraph-Normal" >${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-UserInfoSection-FieldLabel-Name')}</span>
                        <span class="Paragraph-Bold" title="${el.userData.FirstName}">${el.userData.FirstName}</span>
                    </div>
                    <div class="player-info">
                        <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-UserInfoSection-FieldLabel-Email')}</span>
                        <span class="Paragraph-Bold" title="${el.userData.EmailAddress}">${el.userData.EmailAddress}</span>
                    </div>
                    <div class="player-info">
                        <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-UserInfoSection-FieldLabel-Citizenship')}</span>
                        <span class="Paragraph-Bold">${el.userData.CitizenshipName}</span>
                    </div>
                    <div class="player-info">
                        <span class="Paragraph-Normal">${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-UserInfoSection-FieldLabel-Currency')}</span>
                        <span class="Paragraph-Bold">${el.userData.CurrencySymbol} ${el.userData.CurrencyISO}</span>
                    </div>
                </div>
            </li>
            <li>
                <h4>${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-BankSection-Title')}</h4>
                <div class="profile-item">
                    <div class="item-container">
                        <div class="balance-info">
                            <span class="Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-BankSection-FieldLabel-CashBalance')}</span>
                            <span class="Paragraph-Bold">${el.userData.CurrencySymbol}${ getUserBalance(el.userData.CurrentRealBalance) }</span>
                        </div>
                        <div class="button-container">
                            <button class="primary-button" @click=${el.onDepositButtonClick}>${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-BankSection-ButtonLabel-Deposit')}</button>
                        </div>
                    </div>
                    <div class="item-container">
                        <div class="balance-info">
                            <span class="Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewUser-UserProfile-BankSection-FieldLabel-BonusBalance')}</span>
                            <span class="Paragraph-Bold">${el.userData.CurrencySymbol}${ getUserBalance(el.userData.CurrentBonusBalance) }</span>
                        </div>
                    </div>
                </div>
            </li>
            
        </ul>
    </div>
</div>

    

`

function getUserBalance(balance){
    return Helpers.formatNumber(parseFloat(balance) / 100)
}

function getLoyaltyPoint(loyaltyPoints){
    return parseInt(loyaltyPoints)
}