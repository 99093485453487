import {html} from 'lit-html';
import {HorizontalMenu} from "../../js/ui/HorizontalMenu";

export const TemplateHorizontalMenu = el => html`
    <div class="menu-top">
        <div class="header-center">
            <a href="${window.homeLink}">
                <picture>
                    <source srcset="${window.logoImgLink}" media="(max-width: ${MOBILE_BREAKPOINT})">
                    <img class="menu-brand-logo" src="${window.logoImgLink2}" alt="Urban Casino" border="0">
                </picture>
            </a>
            <div class="sub-header-menu ${el.mode}">
                <div>
                    <span @click=${el._onMenuItemClick} data-action="search" title="Search Games"><img alt="Search Games" data-action="search" @click=${el._onMenuItemClick} src="${CDN_ASSETS_URL}/search.svg"></span>
                    <span @click=${el._onMenuItemClick} data-action="help" title="Support"><img alt="Support" data-action="help" @click=${el._onMenuItemClick} src="${CDN_ASSETS_URL}/contact-us.svg"></span>
                    ${el.mode==HorizontalMenu.MODE.LOGGED_OUT ?
                            html``
                            :html` <span @click=${el._onMenuItemClick} data-action="user" title="Account"><img alt="Account" src="${CDN_ASSETS_URL}/user-icon.svg"></span>
                            <span @click=${el._onMenuItemClick} data-action="inbox" title="Inbox"><img alt="Inbox" data-action="inbox" width="25px" @click=${el._onMenuItemClick} src="${CDN_ASSETS_URL}/inbox-icon.svg"></span>
                            <span @click=${el._onMenuItemClick} class="yellow-back" data-action="menu" title="Main Menu"><img alt="Main Menu" src="${CDN_ASSETS_URL}/black-burger-menu.svg"></span>
                            `}
                </div>
                <div>
                    ${window.menuData.map(item=> html`
                        ${item.classes != "mobile-primary-menu" ?
                            html`
                            <a class="menu-item ${item.isActive != 'no'?'active':''}${item.classes}" href="${item.url}">
                                <span>${item.title}</span>
                            </a>`
                        :''}
                    `)}
                    ${el.mode==HorizontalMenu.MODE.LOGGED_OUT ?
                            html` <span @click=${el._onMenuItemClick} class="left-button" data-action="signUp"><img @click=${el._onMenuItemClick} data-action="signUp" src="${CDN_ASSETS_URL}/signup-button.svg"></span>
                            <span  @click=${el._onMenuItemClick} class="right-button" data-action="login"><img @click=${el._onMenuItemClick} data-action="login" src="${CDN_ASSETS_URL}/login-button.svg"></span>`
                            :html`
                                <span @click=${el._onMenuItemClick} class="left-button" data-action="bankWithdraw"><img @click=${el._onMenuItemClick} data-action="bankWithdraw" src="${CDN_ASSETS_URL}/withdraw-button.svg"></span>
                                <span @click=${el._onMenuItemClick} class="right-button" data-action="bank"><img  @click=${el._onMenuItemClick} data-action="bank" src="${CDN_ASSETS_URL}/deposit-button.svg"></span>
                            `}
                </div>
            </div>
            <div class="sub-header-menu-mobile ${el.mode}">
                ${el.mode==HorizontalMenu.MODE.LOGGED_OUT ?
                        html` <span @click=${el._onMenuItemClick} data-action="signUp">SIGN UP</span>
                    <span @click=${el._onMenuItemClick} data-action="login">LOGIN</span>
                    <span @click=${el._onMenuItemClick} class="yellow-back" data-action="menu"><img src="${CDN_ASSETS_URL}/mobile-burger.svg"></span>`
                        :html`
                    <span @click=${el._onMenuItemClick} data-action="bank" class="yellow-back">DEPOSIT</span>
                    <span @click=${el._onMenuItemClick} class="yellow-back" data-action="menu"><img src="${CDN_ASSETS_URL}/mobile-burger.svg"></span>
                `}
            </div>
        </div>
    </div>
`;