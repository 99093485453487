import {html} from "lit-html";
import {SideViewMenu} from "../../../js/ui/SideViewMenu";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateSideViewMenu= el => html`
    <div class="selected-item" @click=${el.onMobileItemClick}>${getMenuItem(el.selectedItem)}</div>
    <ul class="${el.mobileOpened ? 'dynamic-icon open' : 'dynamic-icon'}">
        ${el.menuItems.map(item=>html`<li class="${el.selectedItem==item ? 'active' : ''}" @click=${el.onMenuItemClick} data-item-id="${item}">${getMenuItem(item, (window.isMenuDynamicIcon ? true : false))}</li>`)}
    </ul>
    <img class="close-btn" @click=${el.onCloseButtonClick} src="${CDN_ASSETS_URL}/close.svg">
`;

function getMenuItem(type, isMenuDynamicIcon){
    let item;
    switch (type) {
        case SideViewMenu.ITEM_TYPES.PROFILE:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/profile-pp.svg"><img class="active" src="${CDN_ASSETS_URL}/profile-pp-active.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-UserProfile')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/profile-pp.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-UserProfile')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.PREFERENCES:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/preferences.svg"><img class="active" src="${CDN_ASSETS_URL}/preferences-active.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-UserPreferences')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/preferences.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-UserPreferences')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.INBOX:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/inbox.svg"><img class="active" src="${CDN_ASSETS_URL}/inbox-active.svg"> <span>Inbox</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/inbox.svg"> <span>Inbox</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.VERIFICATION:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/verification.svg"><img class="active" src="${CDN_ASSETS_URL}/verification-active.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-Verification')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/verification.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-Verification')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/responsible-gambling.svg"><img class="active" src="${CDN_ASSETS_URL}/responsible-gambling-active.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-Limits')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/responsible-gambling.svg"> <span>${APP_STRINGS.getWPMLString('ProfileSideView-ProfileMenu-MenuItemLabel-Limits')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.DEPOSIT:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/wallet-2.svg"><img class="active" src="${CDN_ASSETS_URL}/wallet-2-active.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Deposit')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/wallet-2.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Deposit')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.WITHDRAW:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/withdraw.svg"><img class="active" src="${CDN_ASSETS_URL}/withdraw-active.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Withdraw')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/withdraw.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Withdraw')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.HISTORY:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/history.svg"><img class="active" src="${CDN_ASSETS_URL}/history-active.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-History')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/history.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-History')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.BONUSES:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/bonus.svg"><img class="active" src="${CDN_ASSETS_URL}/bonus-active.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Bonuses')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/bonus.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-Bonuses')}</span>`;
            }
            break;
        case SideViewMenu.ITEM_TYPES.LOYALTY_SHOP:
            if(isMenuDynamicIcon === true){
                item=html`<img class="inactive" src="${CDN_ASSETS_URL}/side-icon-loyaty.svg"><img class="active" src="${CDN_ASSETS_URL}/side-icon-loyaty.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-LoyaltyShop')}</span>`;
            }
            else{
                item=html`<img src="${CDN_ASSETS_URL}/side-icon-loyaty.svg"> <span>${APP_STRINGS.getWPMLString('BankSideView-BankMenu-MenuItemLabel-LoyaltyShop')}</span>`;
            }
            break;
        default:
            item='';
    }
    return item;

}


