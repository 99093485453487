import {html} from 'lit-html';

export const Template_PayID_Withdrawal= el=>html`
    
    <form class=" ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        <div class="title-container">
            <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PayID-Form-Title')}</span>
        </div>
        <div class="mdc-line-ripple"></div>
        <div class="additional-form-info">
        <p>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PayID-AddtionalForm-Info1')}</p>
        <p>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PayID-AddtionalForm-Info2')}</p>
        </div>
       
        <div class="payment-method-form">
        
        <div>
        <label class="mdc-text-field" data-validation-method="required" data-field="PayId">
       
        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" aria-controls="account-helper-text"  name="PayID" disabled>
            <span class="mdc-floating-label" id="my-label-id">
                ${el.accountReference}
            </span>
            <div class="mdc-line-ripple"></div>
         </label>
        </div>

        <div class="mdc-line-ripple"></div>
        <div class="bottom-button-section">
            <div class="form-element-2">
            <button type="button" class="primary-button ${el._loading ? 'loading' : ''}" @click=${el._onWithdrawButtonClick} >
            <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-ButtonLabel-Withdraw')}</span>
            <preloader-element></preloader-element>
            </button>
            </div>
       </div>
       </div>
        
    </form>
`;
