import {SideViewBaseClass} from "../SideViewBaseClass";
import {SideViewMenu} from "../SideViewMenu";
import {SideViewSubUserProfile} from "./subViews/SideViewSubUserProfile";
import {SideViewSubUserPreferences} from "./subViews/SideViewSubUserPreferences";
import {SideViewSubVerification} from "./subViews/SideViewSubVerification";
import {SideViewSubResponsibleGambling} from "./subViews/SideViewSubResponsibleGambling";


export class SideViewUser extends SideViewBaseClass{
    constructor(firstView) {
        super("user-side-view",SideViewBaseClass.TYPE.WIDE, firstView,SideViewUser.ALIGNMENT);
        this.menu;
    }

    renderViewContent() {
        console.log("rendering userProfile");
        this.contentElement=document.createElement('div');
        this.contentElement.classList.add("scrolling-content");


        this.menuItems = [
            SideViewMenu.ITEM_TYPES.PROFILE,
            SideViewMenu.ITEM_TYPES.PREFERENCES,
            //SideViewMenu.ITEM_TYPES.INBOX,
            SideViewMenu.ITEM_TYPES.VERIFICATION,
            SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING
        ]
        this.menu = new SideViewMenu(this.menuItems, this.element,this.contentElement);
        this.element.appendChild(this.contentElement);

        this.menu.on("change",e=>{
            console.log("Change event from menu",e);
            this.renderContentView(e.selectedId);
        });
        this.menu.on("close",()=>this.close());

        this.menu.setSelectedItem(this.firstView ? this.firstView : SideViewMenu.ITEM_TYPES.PROFILE);
    }

    renderContentView(itemId){
        if(this.currentContent){this.currentContent.close();}
        switch (itemId) {
            case SideViewMenu.ITEM_TYPES.PROFILE://todo these should have div wrapper each to separate context events
                this.currentContent=new SideViewSubUserProfile(this.contentElement);
                break;
            case SideViewMenu.ITEM_TYPES.PREFERENCES://todo these should have div wrapper each each to separate context events
                this.currentContent=new SideViewSubUserPreferences(this.contentElement);
                break;
            case SideViewMenu.ITEM_TYPES.INBOX:
                break;
            case SideViewMenu.ITEM_TYPES.VERIFICATION:
                this.currentContent=new SideViewSubVerification(this.contentElement);
                break;
            case SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING:
                this.currentContent=new SideViewSubResponsibleGambling(this.contentElement);
                break;
        }
    }



}

SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;