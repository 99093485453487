import {USER} from "./core/User";
import {CAPP_API} from "./core/CAPPApi";
import {Sidebar} from "./ui/Sidebar";
import {Popup} from "./ui/Popup";
import {FormHelpers} from "./util/FormHelpers";
import {APP_STRINGS} from "./core/AppStrings";
import {GameFiltering} from '@/js/ui/gameViews/GameFiltering'
import {FavouriteGame} from '@/js/ui/gameViews/FavouriteGame'
import {InitLivechatController} from "./core/LivechatController";
import {InitActivationController} from "./core/ActivationController";
import {InitializeViewportHeightController} from "./core/ViewportHeightController";
import {InitExternalBannerController} from "./core/ExternalBannerController";
import {InitGlobalActionController} from "./core/GlobalActionController";
import {InitCarouselController} from "./core/CarouselController";
import {InitResetPasswordController} from "./core/ResetPasswordController";
import {SideViewMobileFilterMenu} from "@/js/ui/sideViews/SideViewMobileFilterMenu";
import { Helpers } from "./util/Helpers";
import {HorizontalMenuExtended as HorizontalMenu} from "./ui/HorizontalMenuExtended";
import {ProcessQueryStrings} from "./core/QueryStringProcessor";
import {LazyImageLoader} from "./core/LazyImageLoader";
import {RestrictedCountryPopup} from "./ui/RestrictedCountryPopup";
import {VideoModal} from "./ui/VideoModal";
import {PreloaderElement} from "./ui/PreloaderElement";
import {InitializeScrollToTopController} from "./core/ScrollToTopController";
import {FAST_TRACK} from "./core/FastTrack";

window.APP_STRINGS=APP_STRINGS;
LazyImageLoader.updateLazyImagesList();
InitializeScrollToTopController();


APP_STRINGS.on("WPML_Ready",()=>{
    console.log("WPML loaded!!!");
    startTheApp();
    document.body.classList.remove("loading");
})
APP_STRINGS._init();

function startTheApp(){
    USER.isLoggedIn ? console.log("User is logged in") : console.log("User is not logged in");
    InitializeViewportHeightController();
    InitLivechatController();
    InitActivationController();
    InitExternalBannerController();
    InitGlobalActionController();
    InitResetPasswordController();

    ProcessQueryStrings();


    if(window.isCountryRestricted){
        new RestrictedCountryPopup();
    }

    try{
        InitCarouselController();
    }catch (e) {
        console.log("Carousel error",e);
    }

    new HorizontalMenu();

    if(Helpers.getCookie('_first_time_video') == "") {
        new VideoModal(window.CDN_VIDEOS_URL + '/uc-welcome.mp4');
        Helpers.setCookie('_first_time_video', 1, 365);
    }

    if (USER.isLoggedIn) {
        initFastTrack();
    }

//display favourite game on homepage
    new FavouriteGame()

    CAPP_API.on("ServerError",(error)=>{
        console.log("server error event",error);
        new Popup("ServerError",error,['OK']);
    });

    CAPP_API.on("SessionExpired",(error)=>{
        console.log("SESSION EXPIRED",error);
        USER.logout(true);
        new Popup(error.errorTitle,error.errorMessage,['OK']);
    });

    if(document.querySelector(".game-filters")){
        FormHelpers.initializeFormComponents(document.querySelector(".game-filters"));
        //test game filtering init here
        //set to variable to share use in mobile.
        window.gameFiltering = new GameFiltering()

    }

    let mainContainer=document.getElementById("main-container");
    if(mainContainer){
        window.selectedMobileDisplayFilter = [];
        window.selectedMobileFilter = [];

        if(USER.isLoggedIn){
            mainContainer.classList.add("li-mode");
        }

        USER.on("onLoginStateChange",()=>{
            {
                if(USER.isLoggedIn){
                    mainContainer.classList.add("li-mode");
                    initFastTrack();
                }else{
                    mainContainer.classList.remove("li-mode");
                    //window.location.reload();
					window.location.replace(Helpers.removeUrlParam('sessionKey'));
                }
            }
        })

        $('.mobile-filter').on('click', function(e){
            new SideViewMobileFilterMenu('filter')
        })

        $('.mobile-sorting').on('click', function(e){
            new SideViewMobileFilterMenu('sorting')
        })


    }

//temporary code exposed to window object for dev purposes
    window.USER=USER;
}

function initFastTrack() {
    FAST_TRACK.initialize(window.ftUrl,window.ftPusherChannel,window.ftBrandName);
}

